import React, { useState } from "react";
import { Link } from "react-router-dom";
import arrowIcon from "../../assets/right-arrow.png";
import ResumeResultStats from "../resumeResultStats";
import ResumeResultsSearchSummary from "../resumeResultsSearchSummary";
import ResumeFilterSidebar from "../resumeFilterSidebar";
import ResumeResultsCardList from "../resumeResultsCardList";

import "./resumeDbSearchResults.scss";

function ResumeDbSearchResults() {

  return (
     <div className="resumeDBSearchResults-wrapper">

        <div className="resumeDBSearchResults-Stats">
             <ResumeResultStats />
        </div>
        <div className="resumeDBSearchResults-summary-search">
             <ResumeResultsSearchSummary />
        </div>
        <div className="resumeDBSearchResults-filters">
             <ResumeFilterSidebar/>
        </div>
        <div className="resumeDBSearchResults-card-list">
             <ResumeResultsCardList />
        </div>
    </div>
  );
}

export default ResumeDbSearchResults;
