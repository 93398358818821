import React, { useState } from "react";
import "./index.css";
import SideBar from "../Sidebar";
import { Routes, Route, Outlet } from "react-router-dom"; // Removed unused imports
import Header from "../header";
import Breadcrumbs from "../breadcrumb/breadcrumbs";
import Dashboard from "../dashboard";
import Alljds from "../alljds/alljds";
import Jd from "../particularjds";
import Resumes from "../particularjds/resumes/resumes";
import JdDescription from "../particularjds/jdDescription";
import Createjd from "../createJD";
import CandidateDetails from "../particularjds/resumes/resumecomponents/candidateDetails/candidateDetaild/candidateDetails";
import ResumeSummary from "../particularjds/resumes/resumecomponents/summary/summary";
import Summary from "../particularjds/resumes/resumecomponents/summary/summary";

function Transactions() {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="">
      <Header />
      <div className="row rowdesign">
        <div className="col-3">
          <SideBar />
        </div>
        <div className="col-9 bodyPart">
          <Breadcrumbs />
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;
