import React, { useState } from "react";
import { Link } from "react-router-dom";
import arrowIcon from "../../assets/right-arrow.png";


import "./resumeResultStats.scss";

function ResumeResultStats() {
  const [isCollapsedJD, setIsCollapsedJD] = useState(true);
  const [isCollapsedJDrs, setIsCollapsedJDrs] = useState(true);

  const handleToggleCollapsejd = () => {
    setIsCollapsedJD(!isCollapsedJD);
  };
  const handleToggleCollapsers = () => {
    setIsCollapsedJDrs(!isCollapsedJDrs);
  };

  const setStatus = (data) => {
    console.log(data);
    localStorage.setItem("status", JSON.stringify({ status: data }));
  };
  return (
     <div className="resumeDBSearchResults-wrapper">
             400 Resumes Found
    </div>
  );
}

export default ResumeResultStats;
