import React, { useState,useRef ,useEffect} from "react";
import { Empty, Button,Checkbox,Select } from "antd";
import { useNavigate } from "react-router-dom";

import "./rtableSelectable.scss";
import useResumeStore from "../../store/resume.store";

export default function RTableSelectable({
    isClickable = false,
    titleList,
    data,
    isResumePage = false,
    isEditField = false,
    editFieldHandler,
    setCurrentView,
    resumeProfileHandler,
    qhjid,

}) {
    const navigate = useNavigate();
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [checkBoxData, setCheckBoxData] = useState([]);
  const { updateCandidateStatus } = useResumeStore();
  useEffect(() => {
    const checkBoxArray = data?.map((idx) => {
      return {
        type: "",
        selected: false,
        status: "To Be Called",
      };
    });
    setCheckBoxData(checkBoxArray);
  }, [data]);

    const handleResumeDetailClick = (qhID) => {
        console.log("here is the resume ID", qhID)
        resumeProfileHandler(qhID)
        setCurrentView("profile")
    };

  const ResetCheckBox = () => {
    var checkBoxResetArray = data?.map((idx) => {
      return {
        type: "",
        selected: false,
        status: "To Be Called",
      };
    });
    setCheckBoxData(() => [...checkBoxResetArray]);
  };

    if (!data) {
        return <Empty style={{ margin: "60px" }} />;
    }

    function enableDbUpdate (){
        for(let idx = 0; idx < checkBoxData.length; idx++){
            console.log("enableDbUpdate checkBoxArray", idx,checkBoxData[idx].selected)      
            if (checkBoxData[idx].selected ) {
                return true
            } 
        }
        return false
    }
    const onCheckBoxChange = (e) => {
        if (e.target.checked) {
            checkBoxData[e.target.value].selected = true; 
            console.log("Setting checkBoxData", e.target.value,true,checkBoxData)      
        }else{
            checkBoxData[e.target.value].selected = false; 
            console.log("Setting checkBoxData", e.target.value,false) 
        }
        if (enableDbUpdate() == true){
            setUpdateAvailable(true)

        }else{
            setUpdateAvailable(false)
        } 
        setCheckBoxData(()=>([...checkBoxData]))
      }

    const OnSelectChange = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var status = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].status = status
        checkBoxData[idxInt].type = "CANDIDATE_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}
    }

    const OnSelectChangeResume = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var status = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].status = status
        checkBoxData[idxInt].type = "RESUME_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}
    }

    function getTimeNow(){
        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today. getDate();
        const currentDate = date + "-" + month + "-" + year;
        return currentDate
    }
    const encodeJson = (values) =>{

        let encodedJsonValues ={};
        var updateType=""

        //get company details
        encodedJsonValues._id="Not used"
        encodedJsonValues.qhglobaljid="Not USed"
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        encodedJsonValues.qhoid= userData.ID;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhjid= qhjid;

        //now fill the parameters
        encodedJsonValues.candidatelist =[];
        encodedJsonValues.resumelist =[];

        var num=0
        for(let idx = 0; idx < checkBoxData.length; idx++){
            console.log("checkBoxData", idx,checkBoxData[idx].selected)
            if (checkBoxData[idx].selected && checkBoxData[idx].type =="CANDIDATE_STATUS" ) {
                encodedJsonValues.candidatelist[num]={};
                encodedJsonValues.candidatelist[num].qhrid =data[idx].qhid;
                encodedJsonValues.candidatelist[num].status =checkBoxData[idx].status;

                num++;
                updateType = "CANDIDATE_STATUS" 
            } 
            if (checkBoxData[idx].selected && checkBoxData[idx].type =="RESUME_STATUS" ) {
                encodedJsonValues.resumelist[num]={};
                encodedJsonValues.resumelist[num].qhrid =data[idx].qhid;
                encodedJsonValues.resumelist[num].status =checkBoxData[idx].status;
                num++;
                updateType = "RESUME_STATUS"
            }
        }        
        //get the current date
        let newDate = getTimeNow()
        encodedJsonValues.updatetype = updateType
        encodedJsonValues.updatedby= userData.qhuid;
        encodedJsonValues.updatedbyname= userData.username;
        encodedJsonValues.updatedon    = newDate;
             
        return encodedJsonValues
    }

    const encodeJsonRemoveReq = (values) =>{

        let encodedJsonValues ={};
        var updateType=""

        //get company details
        encodedJsonValues._id="Not used"
        encodedJsonValues.qhglobaljid="Not USed"
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        encodedJsonValues.qhoid= userData.ID;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhjid= qhjid;

        //now fill the parameters
        encodedJsonValues.deletelist =[];

        var num=0
        for(let idx = 0; idx < checkBoxData.length; idx++){
            console.log("checkBoxData", idx,checkBoxData[idx].selected)
            if (checkBoxData[idx].selected ) {
                encodedJsonValues.deletelist[num]={};
                encodedJsonValues.deletelist[num].qhrid =data[idx].qhid;
                num++;
                updateType = "DELETE_RESUME" 
            } 
        }        
        //get the current date
        let newDate = getTimeNow()
        encodedJsonValues.updatetype = updateType
        encodedJsonValues.updatedby= userData.qhuid;
        encodedJsonValues.updatedbyname= userData.username;
        encodedJsonValues.updatedon    = newDate;
             
        return encodedJsonValues
    }
    const getUpdated=(rowIndex)=>{
        return checkBoxData[rowIndex]?.selected 
    }
    const handleUpdates = (value) => {
        var jasonValues= encodeJson()
        console.log("Encoded data", jasonValues)
        updateCandidateStatus({
            jasonValues,qhjid
        });
        ResetCheckBox();
    setUpdateAvailable(false);
    }
    const handleRemoveResume = (value) => {
        var jasonValues= encodeJsonRemoveReq()
        console.log("Encoded data for Removing Resume", jasonValues)
        updateCandidateStatus({
            jasonValues,qhjid
        });
        ResetCheckBox();
    }


    return (
        <div className="rtable-selectable-wrapper">
            <div className="rtable-selectable-btn-wrapper"  >
                <Button
                    className="rtable-selectable-btn"
                    disabled={!updateAvailable}
                    onClick={handleUpdates}
                >
                  Update Status
                </Button>
                <Button
                    className="rtable-selectable-btn"
                    disabled={!updateAvailable}
                    onClick={handleRemoveResume}
                >
                  Remove Resume
                </Button> 
            </div>
            <div
                className="rtable-title-wrapper"
                style={{
                    gridTemplateColumns: `50px 50px 1fr 2fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr`
                }}
            >
                {titleList.map((title) => (
                    <div className="rtable-title">{title}
                    </div>
                ))}
            </div>
            {data?.map((table, rowIndex) => (
                <div
                    key={table.id}
                    className="rtable-info-wrapper"
                    style={{
                        gridTemplateColumns: `50px 50px 1fr 2fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr`,
                        cursor: isClickable && "pointer",
                    }}
                >
                    {Object.keys(table)?.map((tableInfo, idx) => (
                        <div className="rtable-info">
                            {isEditField ? (
                                <div className="edit-field-wrapper">
                                        <>
                                            {tableInfo === "id"
                                                ? rowIndex + 1
                                                : <></>}
                                                
                                            {(tableInfo != "id") &&(tableInfo != "select")&&
                                             (tableInfo != "candidateStatus")&&(tableInfo != "resumeStatus")&&
                                             (tableInfo != "interviewAction")&&(tableInfo != "interviewType")&&
                                             (tableInfo != "interviewStatus")
                                                ? table[tableInfo]
                                                : <></>}

                                             { tableInfo === "select" ?
                                                <Checkbox value = {rowIndex} onChange={onCheckBoxChange}></Checkbox>
                                               : <></>
                                             }  
                                             { tableInfo === "candidateStatus" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?
                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChange}
                                                            placeholder="To Be Called"  
                                                                                                                                                                         
                                                            options={[
                                                                { value: rowIndex + '#' +'Available', label: 'Available' },
                                                                { value: rowIndex + '#' +'Not Picking', label: 'Not Picking' },
                                                                { value: rowIndex + '#' +'Busy', label: 'Busy' },
                                                                { value: rowIndex + '#' +'Call Later', label: 'Call Later' },
                                                                { value: rowIndex + '#' +'Not Interested', label: 'Not Interested'},
                                                                { value: rowIndex + '#' +'Location Issue', label: 'Location Issue' },
                                                                { value: rowIndex + '#' +'JD Needed', label: 'JD Needed' },
                                                                { value: rowIndex + '#' +'Direct Payroll', label: 'Direct Payroll' },
                                                                { value: rowIndex + '#' +'Disconnected', label: 'Disconnected' },
                                                                { value: rowIndex + '#' +'OOS', label: 'OOS' },
                                                                { value: rowIndex + '#' +'Already Got Call', label: 'Already Got Call'}, 
                                                                { value: rowIndex + '#' +'Already Applied', label: 'Already Applied'}, 
                                                                { value: rowIndex + '#' +'Interviewed within 6 Months', label: 'Interviewed within 6 Months ' },
                                                                { value: rowIndex + '#' +'Interviewed before 6 months', label: 'Interviewed before 6 months' },
                                                                { value: rowIndex + '#' +'To Be Called', label: 'To Be Called' }, 
                                                            ]}
                                                        /> :
                                                        table[tableInfo]==""?"--":table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             } 
                                             { tableInfo === "resumeStatus" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?
                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChangeResume}
                                                            placeholder="To Be Shared"                                                        
                                                            options={[
                                                                { value: rowIndex + '#' +'Shared', label: 'Shared' },
                                                                { value: rowIndex + '#' +'Review Pending', label: 'Review Pending' },
                                                                { value: rowIndex + '#' +'Feedback Pending', label: 'Feedback Pending' },
                                                                { value: rowIndex + '#' +'Client Feedback Pending', label: 'Client Feedback Pending' },
                                                                { value: rowIndex + '#' +'To Be Shared', label: 'To Be Shared' },
                                                                { value: rowIndex + '#' +'Screen Rejected', label: 'Screen Rejected'},
                                                                { value: rowIndex + '#' +'Duplicate', label: 'Duplicate' },
                                                                { value: rowIndex + '#' +'Interview Rejected', label: 'Interview Rejected' },
                                                                { value: rowIndex + '#' +'Screen Selected', label: 'Screen Selected' },
                                                            ]}
                                                        /> :
                                                        table[tableInfo]==""?"--":table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             }
                                             { tableInfo === "interviewType" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?
                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChangeResume}
                                                            placeholder="Select"                                                       
                                                            options={[
                                                                { value: rowIndex + '#' +'type_L0', label: 'L0' },
                                                                { value: rowIndex + '#' +'type_L1', label: 'L1' },
                                                                { value: rowIndex + '#' +'type_L2', label: 'L2' },
                                                                { value: rowIndex + '#' +'type_customer', label: 'Customer' },
                                                                { value: rowIndex + '#' +'type_hr', label: 'HR'},
                                                                { value: rowIndex + '#' +'type_mgmt', label: 'MgMT' },
                                                            ]}
                                                        /> :
                                                        table[tableInfo]==""?"--":table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             }  
                                             { tableInfo === "interviewAction" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?
                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChangeResume}
                                                            placeholder="Select"                                                       
                                                            options={[
                                                                { value: rowIndex + '#' +'action_to_schedule', label: 'To Schedule' },
                                                                { value: rowIndex + '#' +'action_scheduled', label: 'Scheduled' },
                                                                { value: rowIndex + '#' +'action_to_reschedule', label: 'To Reschedule' },                                                                
                                                                { value: rowIndex + '#' +'action_rescheduled', label: 'Rescheduled' },
                                                                { value: rowIndex + '#' +'action_cancelled', label: 'Cancelled' },
                                                                { value: rowIndex + '#' +'action_done', label: 'Interview Done' },
                                                            ]}
                                                        /> :
                                                        table[tableInfo]==""?"--":table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             }  
                                             { tableInfo === "interviewStatus" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?
                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChangeResume}
                                                            placeholder="NA"                                                       
                                                            options={[
                                                                { value: rowIndex + '#' +'status_na', label: 'NA' },
                                                                { value: rowIndex + '#' +'status_no_panel', label: 'No Panel' },                                                                                                                               
                                                                { value: rowIndex + '#' +'status_candidate_drop', label: 'Candidate Drop' },
                                                                { value: rowIndex + '#' +'status_candidate_np', label: 'Candidate NP' },
                                                                { value: rowIndex + '#' +'status_panel_not_joined', label: 'Panel Did Not Join' },
                                                                { value: rowIndex + '#' +'status_no_show', label: 'No Show' },
                                                                { value: rowIndex + '#' +'status_selected', label: 'Screen Selected'},
                                                                { value: rowIndex + '#' +'status_hold', label: 'Hold' },
                                                                { value: rowIndex + '#' +'status_rejected', label: 'Rejected' },
                                                                { value: rowIndex + '#' +'status_final_select', label: 'Final Select' },
                                                            ]}
                                                        /> :
                                                        table[tableInfo]==""?"--":table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             }                                                                                                                                                                                      
                                        </>

                                </div>
                            ) : (
                                table[tableInfo]==""?"--":table[tableInfo]
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}
