import { create } from "zustand";

import axios from "../helper/axios";

var userData = JSON.parse(localStorage.getItem("userDetails"));

const useResumeStore = create((set) => ({
    resumes: [],
    resumeStats: [],
    resumeAnalysysData : {},
    allApplications:[],
    selectedApplication:null,
    selectedProfileResume:null,
            
    downloadResumeByID: async (jdID,resumeID) => {
        try {
            userData = JSON.parse(localStorage.getItem("userDetails"));
            const response = await axios.get(
                `/users/${userData.ID}/jds/${jdID}/resumes/${resumeID}`,
                {
                    responseType : "blob"
                }
            );

            const blob = new Blob([response.data]);

            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
    
            // Specify the filename for the downloaded file
            link.setAttribute('download', 'resume.pdf');
    
            // Append the anchor element to the body
            document.body.appendChild(link);
    
            // Trigger the download
            link.click();
    
            // Clean up
            link.parentNode.removeChild(link);
            console.log(response);
        } catch (err) {
            console.error(err);
        }
    },
    getResumesByJD: async (id) => {
        try {
            userData = JSON.parse(localStorage.getItem("userDetails"));
            const response = await axios.get(`/getresumes/${userData.qhoid}/${userData.qhuid}/jds/${id}`);

            console.log(response.data);

            set({ resumes: response?.data });
        } catch (err) {
            console.error(err);
        }
    },
    updateResume: async (jdId, resumeId, body) => {
        try {
            const formData = new FormData();

            for (let key in body) {
                formData.append(key, body[key]);
            }

            await axios.put(
                `/users/${userData.ID}/jds/${jdId}/resumes/${resumeId}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data", // Important for handling form data
                    },
                }
            );
        } catch (err) {
            console.error(err);
        }
    },

    getResumeSkill: async (jdid,resumeId) => {
        try {
            userData = JSON.parse(localStorage.getItem("userDetails"));
            console.log(userData);
            const response = await axios.get(
                `/users/${userData.ID}/jds/${jdid}/resumes/${resumeId}/skillmatch`
            );

            set({ resumeAnalysysData: response?.data });
        } catch (err) {
            console.error(err);
        }
    },
    getSpecificApplicationByJD: async (jdid,id) => {
        try {

           userData = JSON.parse(localStorage.getItem("userDetails"));
           const response = await axios.get(`/getapplication/${userData.qhoid}/${userData.qhuid}/jds/${jdid}/${id}`);

            console.log(response.data);
            set({ selectedApplication: response?.data });
        } catch (err) {
            console.error(err);
        }
    },
    getAllApplicationsByJD: async (jdid) => {
        try {

            userData = JSON.parse(localStorage.getItem("userDetails"));
           const response = await axios.get(`/getapplications/${userData.qhoid}/${userData.qhuid}/jds/${jdid}`);

            console.log(response.data);

            set({ allApplications: response?.data });
        } catch (err) {
            console.error(err);
        }
    }, 
    updateCandidateStatus: async (body) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            console.log(body);
            const response = await axios.post(
                `/candidatestatus/${userData.qhoid}/${userData.qhuid}/jds/${body.qhjid}`,
                body.jasonValues
            );
            console.log(response.data);
        } catch (error) {
            set({ jdCreationError: error });
        }
    },         
    updateProfileInApplication: async (body) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            console.log(body);
            const response = await axios.post(
                `/application/${userData.qhoid}/${userData.qhuid}/jds/${body.jdID}/${body.resumeID}`,
                body.jasonValues
            );
            console.log(response.data);
        } catch (error) {
            set({ jdCreationError: error });
        }
    },
    updateProfileResumeDetails: async (body) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            console.log(body);
            const response = await axios.post(
                `/candidateprofile/${userData.qhoid}/${userData.qhuid}/jds/${body.jdID}/${body.resumeID}`,
                body.resumeJasonValues
            );
            console.log(response.data);
        } catch (error) {
            set({ jdCreationError: error });
        }
    },
    getProfileResumeDetails: async (jdid,id) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const response = await axios.get(`/getcandidateprofile/${userData.qhoid}/${userData.qhuid}/jds/${jdid}/${id}`);
            console.log(response.data);
            set({ selectedProfileResume: response?.data });
        } catch (err) {
            console.error(err);
        }
    },
    resetSelectedProfileResume: async (jdid,id) => {
        set({ selectedProfileResume: null });
    }, 
    resetSelectedApplication: async (jdid,id) => {
        set({ selectedApplication: null });
    },                          
}));



export default useResumeStore;
