import React, { useState } from "react";

import { Field, reduxForm } from "redux-form";
import { actions } from "../actions";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../assets/QuallyHireGreen.png";
import { Container } from "react-bootstrap";
import "./Signin.css";


const Signin = (props) => {
  const [loader, SetLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [error,setError] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const onSubmit = () => {
    console.log("on submit called");
    let dataToSend = { email: email };
    let inputData = { email: email };
    console.log("email", email);
    SetLoader(true);

    dispatch(actions.SignIn(dataToSend, (error, status) => {
      if (!error) {
        navigate("/otp", { state: { inputData, type: "login" } });
        SetLoader(false);
      } else {
        // Handle the error case
        SetLoader(false);
        console.error("API Error:", error);
        let e = error.split("or")
        setError(e[0] + "signup first")
       // setErrorMessage(error); // Set the error message in state
      }
    }));
  };

  const handleOnclick =(e) =>{
    navigate('/landing');
  }
  return (
    
    <div className="">
      <div className="login">
          <div className="header-image-wrapper">
            <img                 
               src={logo} 
               className="header-image" 
               onClick={() => handleOnclick()} />
          </div>
        <div className="header">
          <div>
            <h2 className="form-header">Login to QuallyHire</h2>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-input">
            <div className="form-outline mb-4">
              {/* <Field name="email" className="form-control text-start " placeholder='Your Email ID' component="input" type="text" /> */}
              <div class="input-block">
                <input
                  type="text"
                  name="input-text"
                  id="input-text"
                  required
                  spellcheck="false"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span class="placeholder">Email ID</span>
              </div>
            </div>
            {loader ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <div>
                  <button type="submit" className="Submit_button">
                Login
              </button>
              <p className="text-danger center">{error} </p>
                </div>
            
            
            )}
          </div>
        </form>
      </div>
      <div class="footer">
        <div>
          <p>QuallyHire is an QHRFlow Product from HMW Communications Pvt. Ltd.</p>
        </div>
        <div>
          <a>
            <p>
              Dont have an account ? <Link to={'/signup'}> Sign up Now!</Link>
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

var Sign = reduxForm({
  form: "login",
})(Signin);

export default Sign;
