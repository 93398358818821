import React from "react";
import { useEffect } from "react"

import "./activeSummary.scss";
import StackBarChart from "../StackBarChart";
import useStatsStore from "../../store/stats.store";

const Joblabels = ['Geetha', 'Shivani', 'Anusha', 'Amruta', 'Aniket', 'Arpitha', 'Vinay'];
export const stackBarChartJobs = {
  labels: Joblabels,
  datasets: [
    {
      label: 'Assigned',
      data: Joblabels.map(() => 6),
      backgroundColor: 'rgb(53, 162, 235)',
    },
    {
      label: 'Worked',
      data: Joblabels.map(() => 4),
      backgroundColor: 'rgb(75, 192, 192)',
    },
  ],
};    

const JDClientlabels = ['LG', 'CG', 'Proxelera', 'Team 1', 'HCL', 'Caizin', 'Mobiveil'];
export const stackBarChartJDClients = {
  labels: JDClientlabels,
  datasets: [
    {
      label: 'Total',
      data: Joblabels.map(() => 20),
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: 'TP',
      data: Joblabels.map(() => 30),
      backgroundColor: 'rgb(75, 192, 192)',
    },
    {
      label: 'FTE',
      data: Joblabels.map(() => 140),
      backgroundColor: 'rgb(53, 162, 235)',
    },
  ],
}; 

const resumelabels = ['Geetha', 'Shivani', 'Anusha', 'Amruta', 'Aniket', 'Arpitha', 'Vinay'];
export const stackBarChartResumes = {
  labels : resumelabels,
  datasets: [
    {
      label: 'JDs Created',
      data: resumelabels.map(() => 20),
      backgroundColor: 'rgb(169, 204, 227  )',
    },
    {
      label: 'JDs Worked On',
      data: resumelabels.map(() => 20),
      backgroundColor: 'rgb(210, 180, 222)',
    },
    {
      label: 'Total Uploaded',
      data: resumelabels.map(() => 20),
      backgroundColor: 'rgb(53, 162, 235)',
    },
    {
      label: 'Available',
      data: resumelabels.map(() => 30),
      backgroundColor: 'rgb(255, 195, 0,0.5)',
    },
    {
      label: 'Shared',
      data: resumelabels.map(() => 40),
      backgroundColor: 'rgb(133, 193, 233 )',
    },
    {
      label: 'NP/B/JD/CB',
      data: resumelabels.map(() => 40),
      backgroundColor: 'rgba(128, 0, 128, 0.4)',
      
    },
  ],
};    

export const stackBarChartResumeStatus = {
    labels : resumelabels,
    datasets: [
      {
        label: 'ToBeShared',
        data: resumelabels.map(() => 20),
        backgroundColor: 'rgba(247, 220, 111  )',
      },
      {
        label: 'FBPending',
        data: resumelabels.map(() => 30),
        backgroundColor: 'rgb(133, 193, 233)',
      },
      {
        label: 'Screen Rejected',
        data: resumelabels.map(() => 40),
        backgroundColor: 'rgb(236, 112, 99,0.6)',
      },
      {
        label: 'Screen Selected',
        data: resumelabels.map(() => 40),
        backgroundColor: 'rgba(34, 153, 84 , 0.7)',
      },
      {
        label: 'Duplicate',
        data: resumelabels.map(() => 40),
        backgroundColor: 'rgba(128, 128, 128,0.5)',
      }, 
      {
        label: 'Review Pending',
        data: resumelabels.map(() => 40),
        backgroundColor: 'rgba(84, 153, 199)',
      },      
    ],
  }; 

export const stackBarChartResumeQuality = {
    labels : resumelabels,
    datasets: [
      {
        label: 'Quality',
        data: resumelabels.map(() => 20),
        backgroundColor: 'rgba(75, 192, 192)',
      },
      {
        label: 'Above Average',
        data: resumelabels.map(() => 30),
        backgroundColor: 'rgb(125, 206, 160)',
      },
      {
        label: 'Average',
        data: resumelabels.map(() => 40),
        backgroundColor: 'rgb(133, 193, 233)',
      },
      {
        label: 'Below Average',
        data: resumelabels.map(() => 40),
        backgroundColor: 'rgba(169, 204, 227 )',
      },
      {
        label: 'Not Suitable',
        data: resumelabels.map(() => 40),
        backgroundColor: 'rgba(128, 128, 128,0.5)',
      },     
    ],
  }; 

export const stackBarChartInterviewStatus = {
    labels : resumelabels,
    datasets: [
      {
        label: 'Screening',
        data: resumelabels.map(() => 20),
        backgroundColor: 'rgba(133, 193, 233)',
      },
      {
        label: 'L0',
        data: resumelabels.map(() => 0),
        backgroundColor: 'rgba(52, 152, 219)',
      },      
      {
        label: 'L1',
        data: resumelabels.map(() => 30),
        backgroundColor: 'rgb(125, 206, 160)',
      },
      {
        label: 'L2',
        data: resumelabels.map(() => 40),
        backgroundColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Customer',
        data: resumelabels.map(() => 40),
        backgroundColor: 'rgba( 88, 214, 141   )',
      },
      {
        label: 'HR',
        data: resumelabels.map(() => 40),
        backgroundColor: 'rgba(40, 180, 99)',
      },     
    ],
  }; 

const alllabels = ['JDs', 'Resumes', 'Vendors', 'Users', 'Interviews', 'Offers', 'Joined'];
export const stackBarChartAll = {
    labels : alllabels,
    datasets: [
      {
        label: 'All',
        data: resumelabels.map(() => 40),
        backgroundColor: 'rgba(34, 153, 84 , 0.7)',
      },  
    ],
  }; 

  const allQualitylabels = ['Suitable', 'Above Average', 'Average', 'Below Average', 'Not Suitable']; 
  export const stackBarChartResumeQualityAll = {
    labels : allQualitylabels,
    datasets: [
      {
        label: 'Quality',
        data: resumelabels.map(() => 20),
        backgroundColor: 'rgba(75, 192, 192)',
      },     
    ],
  };   

export default function ActiveSummary({ inputData, buttonName, title }) {

  const { getTeamsDashBoardConsolidatedStats,getTeamsInternalConsolidatedStats,getMyInternalConsolidatedStats,
          getMyDashBoardConsolidatedStats,getJdsConsolidatedStats
   } = useStatsStore();

  useEffect(() => {
    getTeamsDashBoardConsolidatedStats();
    getTeamsInternalConsolidatedStats();
    getMyDashBoardConsolidatedStats();
    getMyInternalConsolidatedStats();

    let encodedJsonValues ={};
    encodedJsonValues.prio = "all";
    encodedJsonValues.jobstatus = "active";
    encodedJsonValues.client = "all";
    encodedJsonValues.ta = "all";
    getJdsConsolidatedStats({...encodedJsonValues});
 }, []);

  return (
        <div className="activity-summary-wrapper">
            {(title === 'All') ? <h1 className="activity-summary-title"> {title} Stats Summary </h1>
            : <h1 className="activity-summary-title"> {title} Activity (Last 24 hours)</h1>
            }
            <div className="activity-wrapper">
                {(title === 'Vendors') ?
                ( 
                <div className = "activity-bar-stackChart-wrapper">
                        <StackBarChart stackBarData={stackBarChartResumes} stack = {false} showTitle={"Candidate Status"} />
                        <StackBarChart stackBarData={stackBarChartResumeStatus} stack = {false} showTitle={"Resume Status"}/>
                        <StackBarChart stackBarData={stackBarChartResumeQuality} stack = {false} showTitle={"Resume Quality"}/>
                        <StackBarChart stackBarData={stackBarChartInterviewStatus} stack = {false} showTitle={"Interview Status"}/>
                </div>
                )
                : (title === 'Users') ? 
                ( 
                    <div className = "activity-bar-stackChart-wrapper">
                        <StackBarChart stackBarData={stackBarChartResumes} stack = {false} showTitle={"Candidate Status"} />
                        <StackBarChart stackBarData={stackBarChartResumeStatus} stack = {false} showTitle={"Resume Status"}/>
                        <StackBarChart stackBarData={stackBarChartResumeQuality} stack = {false} showTitle={"Resume Quality"}/>
                        <StackBarChart stackBarData={stackBarChartInterviewStatus} stack = {false} showTitle={"Interview Status"}/>
                    </div>
                    )
                : 
                ( 
                  <div className = "activity-bar-stackChart-wrapper">
                      <StackBarChart stackBarData={stackBarChartAll} stack = {false} showTitle={"Major Stats - All"} />
                      <StackBarChart stackBarData={stackBarChartResumeQualityAll} stack = {false} showTitle={"All Resume Quality"} />
                  </div>
                  )
                }              
            </div>

        </div>
    );
}
