/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Avatar, Dropdown } from "antd";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../assets/QuallyHireGreen.png";
import Notifications from "../../assets/notifications.svg";
import Settings from "../../assets/settings.svg";
import { DownOutlined, UserOutlined, EditOutlined,LogoutOutlined,FileOutlined,FormOutlined } from '@ant-design/icons';

import "./header.scss";

const Header = () => {
    const navigate = useNavigate();

  var navigateTo = () => {
    navigate("/users");
  };

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const handleClickNewJD = () => {
    
        // Perform API call here with the selected option
        navigate(`/createJd`);
      };

    const handleLogOut = () => {
        localStorage.removeItem("userDetails");
        navigate(`landing`);
    };

    const handleProfile = () => {
    
        // Perform API call here with the selected option
        navigate(`/profile`);
      };
    const items = [
        {
            label: (
                <div className="e-mail">
                    {userDetails.email}
                </div>
            ),
            key: "0",
        },        
        {
            label: (
                <div className="log-out" onClick={handleProfile}>
                    Profile
                </div>
            ),
            key: "1",
            icon: <UserOutlined />,
        },
        {
            label: (
                <div className="log-out" onClick={handleProfile}>
                    FAQ
                </div>
            ),
            key: "2",
            icon: <FileOutlined />,            
        },
        {
            label: (
                <div className="log-out" onClick={handleProfile}>
                    Analytics
                </div>
            ),
            key: "3",
            icon: <FormOutlined />,                  
        },
        {
            label: (
                <div className="log-out" onClick={handleProfile}>
                    Super User
                </div>
            ),
            key: "3",
            icon: <FormOutlined />,                  
        },
        {
            label: (
                <div className="log-out" onClick={handleProfile}>
                    Help
                </div>
            ),
            key: "3",
            icon: <FormOutlined />,                  
        },        
        {
            label: (
                <div className="log-out" onClick={handleLogOut}>
                    Logout
                </div>
            ),
            key: "4",
            icon: <LogoutOutlined />,
        },
    ];

    return (
        <nav className="navbar-wrapper">
            <div className="logo-wrapper">
                <img src={Logo} className="logo" alt="QuallyHire" />
            </div>
            <div className="services-links-wrapper">
            <Link className="links" to="/">
                Home
            </Link>
            <Link className="links" to="/customers">
                Customers
            </Link> 
            <Link className="links" to="/contactBook">
                Contact Book
            </Link>  
            <Link className="links" to="/communication">
                Communication
            </Link>
            <Link className="links" to="/timeSheet">
                Time Sheet
            </Link> 
            <Link className="links" to="/leaves">
                Leaves
            </Link>
            <Link className="links" to="/holidays">
                Holidays
            </Link>                                                                           
        <Link className="links" to="/users">
          Users
        </Link>
            </div>
            <div className="info-wrapper">
                <button className="btn-header">Upload Resume</button>
                <button onClick={handleClickNewJD} className="btn-header">+ New JD</button>
                <button className="btn-header">+ New Interview</button>
                <button className="btn-header">Search</button> 

                <img
                    onClick={navigateTo}
                    className="info-logo"
                    src={Settings}
                    alt="Settings"
                />
                <img
                    className="info-logo"
                    src={Notifications}
                    alt="Notifications"
                />
                <Dropdown
                    menu={{
                        items,
                    }}
                    trigger={["click"]}
                >
                    <Avatar className="user-avatar">
                        {userDetails.username[0]}
                    </Avatar>
                </Dropdown>
            </div>
        </nav>
    );
};

export default Header;
