import React from "react";
import { Modal, Form, Input, Button, Radio, Dropdown ,Space, Select} from "antd";

import useClientStore from "../../store/clientModal.store";

import "./customerAddModal.scss";

export default function CustomerAddModal() {
    const { isModalOpen, handleModalCancel, handleClientAdd } = useClientStore();

    const encodeJson = (values) =>{

        let encodedJsonValues ={};

        //now fill the parameters
        encodedJsonValues.client ={};
        encodedJsonValues.client.clientname=values.clientname;
        encodedJsonValues.client.clienttype=values.clienttype;
        encodedJsonValues.client.clientdescription=values.clientdescription;
        encodedJsonValues.client.clientlocation=values.clientlocation;;
        encodedJsonValues.client.clientstate="active";
        encodedJsonValues.client.clientextra=values.clientextra;
        encodedJsonValues.client.clienttalist= null;
        encodedJsonValues.client.ClientAdditionDate= new Date();
            
        return encodedJsonValues
    }
      

    const handleFormSubmit = (value) => {
        const jasonValues = encodeJson(value)
        handleClientAdd(value);
        handleModalCancel();
    };

    const requirementSelect = [
        {
            label: (
                <div className="role" >
                    Staff Augmentation
                </div>
            ),
            value: "0",
        },        
        {
            label: (
                <div className="role" >
                    FTE
                </div>
            ),            
            value: "1",
        },
        {
            label: (
                <div className="role" >
                    Both
                </div>
            ),            
            value: "2",            
        },   
    ];  


    return (
        <Modal
            title= { "Provide New Customer Details"}
            visible={isModalOpen}
            footer={null}
            onCancel={handleModalCancel}
            className="subUserAddModel-title"
        >
        <Form
            labelCol={{ flex: "150px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            onFinish={handleFormSubmit}
        >
            <Form.Item
                rules={[
                        {
                            required: true,
                             message: "Please Add User's Name",
                            },
                        ]}
                        label="Customer Name"
                        name="clientname"
            >
                <Input   className="subUserAddModel-input"/>
            </Form.Item>
            <Form.Item
                rules={[
                            {
                                required: true,
                                maxLength: 10,
                                message: "Please Add Contact Details",
                            },
                 ]}
                label="Location"
                name="clientlocation"
            >
                <Input   className="subUserAddModel-input"/>
            </Form.Item>
            <Form.Item
                rules={[
                            {
                                required: true,
                                message: "Please Select Client Type",
                            },
                ]}
                label="Company Type"
                name="clienttype"
            >
                     
                <Select
                    className="role"                            
                    defaultValue="select"
                    options={[
                            { value: 'select', label: 'Please select type of customer' },
                            { value: 'product', label: 'PRODUCT COMPANY' },
                            { value: 'service', label: 'SERVICE COMPANY' },
                            { value: 'both', label: 'BOTH' },
                            ]}
                /> 
            </Form.Item> 
            <Form.Item
                        rules={[
                            {
                                required: false,
                                message: "Description",
                            },
                        ]}
                        label="Description"
                        name="clientdescription"
            >                         
                <Input   className="subUserAddModel-input"/>
            </Form.Item>                
            <Form.Item
                        rules={[
                            {
                                required: false,
                                message: "Other Information",
                            },
                        ]}
                        label="Extra Information"
                        name="clientextra"
            >                         
                <Input   className="subUserAddModel-input"/>
            </Form.Item>                                     

                    <Button htmlType="submit" type="primary">
                        Submit
                    </Button>
        </Form>

        </Modal>
    );
}
