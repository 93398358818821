import React, { useState } from "react";
import {
    Button,
    Modal,
    Form,
    Input,
    Select,
    DatePicker,
    Upload,
    message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { useParams } from "react-router-dom";

import useCandidateStore from "../../store/candidateModal.store";
import useResumeStore from "../../store/resume.store";

import "./candidateAddModal.scss";

const defaultInput = {
    name: "",

};

export default function CandidateAddModal() {
    const [formInput, setFormInput] = useState(defaultInput);
    const { value } = useParams();
    const { getResumesByJD } = useResumeStore();
    const { isCandidateModalOpen, handleModalOk, handleModalCancel } =
        useCandidateStore();

    const [form] = Form.useForm();        
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    //baseURL: "https://api.qhrflow.com",
    //const baseURL = "http://localhost:4200";
    const baseURL = "https://api.quallyhireai.com";

    const props = {
        name: "pdf",
        action: `${baseURL}/addresume/${userDetails.qhoid}/${userDetails.qhuid}/jds/${value}`,

        maxCount: 1,
        data: {
            name: formInput.name,
            mobile: "Not Needed",
            email: "Not Needed",
            tags: formInput.tags,
        },
        beforeUpload() {
            const { name } = formInput;
            if (name === "") {
                message.error(`Please Enter the candidate details`);
                return false;
            }
        },
        onChange(info) {
            if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === "done") {
                //now check if 
                // 1. Resume already exists for this JD by the same user ID
                // 2. Resume already exists for this JD byt other user ID
                // 3. Resume already exist for this JD
                //response: '{"status": "success"}', // response from server
                var responseData = info.file.response
                if(responseData.status == "DUPLICATE_UPLOADED_VENDOR"){
                    message.success(`${info.file.name} Duplicate, Uploaded by Another Vendor`);
                }else if(responseData.status == "DUPLICATE_UPLOADED_INTERNAL"){
                    message.success(`${info.file.name} Duplicate, Already Assigned to this JD`);
                }else{
                    message.success(`${info.file.name} file uploaded successfully`);
                    getResumesByJD(value);
                    handleModalOk();    
                }
                form.resetFields(); 
            } else if (info.file.status === "error") {
                message.error(`${info.file.name} file upload failed.`);
                handleModalOk();
                form.resetFields(); 
            }
        },
    };

    const handleFormInput = (event) => {
        setFormInput((inputs) => ({
            ...inputs,
            [event.target.name]: event.target.value,
        }));
    };

    return (
        <Modal
            title="Upload Resume in QuallyHire"
            open={isCandidateModalOpen}
            footer={null}
            onCancel={handleModalCancel}
        >
            <Form
                labelCol={{ flex: "150px" }}
                form={form}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
            >
                <Form.Item
                    label="Candidates Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input candidate name!",
                        },
                    ]}
                >
                    <Input
                        name="name"
                        value={formInput.name}
                        onChange={handleFormInput}                        
                        className="candidateAddModel-input"
                    />
                </Form.Item>

                <Form.Item
                    label="Skills"
                    name="tags"
                >
                    <Input
                        name="tags"
                        value={formInput.tag}
                        onChange={handleFormInput}
                        placeholder={"skill1,..., skill N etc."}                        
                        className="candidateAddModel-input"
                    />
                </Form.Item>                                                

                <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Form>
        </Modal>
    );
}
