import { create } from "zustand";

import axios from "../helper/axios";

const useMembersStore = create((set) => ({
    teamMembers: [],
    vendors: [],
    clients:[],
    assignedTeamMembers: [],
    assignedVendorConnects: [],

    getTeamMembers: async () => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            const response = await axios.get(
                `/users/${userData.ID}/role/team_member`
            );

            set({ teamMembers: response.data });
            console.log(response.data);
        } catch (err) {
            console.error(err);
        }
    },
    getVendors: async () => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            const response = await axios.get(
                `/users/${userData.ID}/role/vendor_connect`
            );

            set({ vendors: response.data });
            console.log(response.data);
        } catch (err) {
            console.error(err);
        }
    },
    getClients: async () => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            const response = await axios.get(
                `/getclients/${userData.ID}`
            );
            set({ clients: response.data });
            console.log(response.data);
        } catch (err) {
            console.error(err);
        }
    },    
    getAssignedMembers: async (jdId) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            console.log(userData);
            const response = await axios.get(
                `assigned/${userData.qhoid}/${userData.qhuid}/${jdId}/team_member`
            );

            set({ assignedTeamMembers: response.data });
            console.log(response.data);
        } catch (err) {
            console.error(err);
        }

        try {
            const response = await axios.get(
                `/jds/${jdId}/assigned/vendor_connect`
            );

            set({ assignedVendorConnects: response.data.vendor_connect });
        } catch (err) {
            console.error(err);
        }
    },
}));

export default useMembersStore;
