import React, { useState,useRef } from "react";
import { Empty, Input, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";

import editIcon from "../../assets/edit.svg";
import PieChart from "../PieChart";
import StackChart from "../StackChart";

import "./tableDashBoard.scss";

export default function TableDashBoard({
    isClickable = false,
    titleList,
    data,
    isJDPage = false,
}) {
    const navigate = useNavigate();
    const inputRef = useRef(null);
    console.log(data)
    if (!data) {
        return (      
              <div style={{ textAlign:"center",margin: "30px" ,fontSize: "12px"}} >
                No Updates in Last 24 Hrs
              </div>
              )
    }

    const GetJobID =(title)=> {
        const jd_array = title?.split(']')
        var jdID = jd_array[0]?.replace("[", "");
        return jdID
    }
    const handleJDClick = (id) => {
        console.log("In Table, clicked on JD",id)
        navigate(`/jds/${id}`);
    };
   const handleResumeStatsClick = (id)=>{
    navigate(id)
   }
   
    return (
        <div className="table-wrapper">
            <div
                className="table-title-wrapper"
                style={{
                    gridTemplateColumns: `50px 1fr 1fr 3fr 1fr 1fr 1fr repeat(${titleList.length-7}, 1fr)`
                }}
            >
                {titleList.map((title) => (
                    <div className="table-title">{title}</div>
                ))}
            </div>
            {data?.map((table, rowIndex) => (
                <div
                    onClick={isJDPage ? () => handleJDClick(GetJobID(table.title)) : () => handleResumeStatsClick(table.team_member_id ) }
                    key={table.id}
                    className="table-info-wrapper"
                    style={{
                        gridTemplateColumns: `50px 1fr 1fr 3fr 1fr 1fr 1fr repeat(${titleList.length-7}, 1fr)`,
                        cursor: isClickable && "pointer",
                    }}
                >
                    {Object.keys(table)?.map((tableInfo, idx) => (
                        <div className="table-info">
                            {   <>
                                {tableInfo === "id"
                                    ? rowIndex + 1
                                    : table[tableInfo]}
                                </>}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
