import { Radio, Divider, Form, Input, message, Button, TimePicker, DatePicker,Empty } from "antd";
import moment from 'moment'
import TextArea from "antd/es/input/TextArea";
import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import RTable from "../RTable";
import CandidateAddModal from "../CandidateAddModal";
import { useNavigate } from "react-router-dom";

import useCandidateStore from "../../store/candidateModal.store";
import useResumeStore from "../../store/resume.store";
import useJDStore from "../../store/jd.store";


import Table from "../Table";
import ResumeDbSearchResults from "../resumeDbSearchResults";
import "./resumeDB.scss";


const getCandidateList =
    JSON.parse(localStorage.getItem("candidateList")) || [];

const candidateDetailsListTable = [
    "Number",
    "Name",
    "Email Id",
    "Mobile",
    "Preferred Location",
    "Current Designation",
    "Notice Period (official days)",
    "Share to Client",
    "Notice Period (Negotiable days)",
    "LWD in Current Company",
    "Offer Joining Date",
    "Offer CTC",
    "Max Degree",
    "Current Company",
    "Current Location",
    "Referral",
    "Remarks",
    "Availability",
    "ECTC",
    "CCTC",
    "Date Added",
    "Uploaded By",
    "Vendor Name",
    "Source Medium",
];


const candidateDetailsData = getCandidateList?.map((candidate, idx) => {
    return {
        id: idx + 1,
        name: candidate?.name,
        email: candidate?.email,
        mobile: candidate?.mobile,
        preferredLocation: candidate?.preferredLocation,
        currentDesignation: candidate?.currentDesignation,
        noticePeriodOfficial: candidate?.noticePeriodOfficial,
        share_to_client: "share to client",
        noticePeriodNegotiable: candidate?.noticePeriodNegotiable,
        lwd: candidate?.lwd,
        offerJoiningDate: candidate?.offerJoiningDate,
        offerCTC: candidate?.offerCTC,
        maxDegree: candidate?.maxDegree,
        currentCompany: candidate?.currentCompany,
        currentLocation: candidate?.currentLocation,
        referral: candidate?.referral,
        remarks: candidate?.remarks,
        availability: candidate?.availability,
        ECTC: candidate?.ECTC,
        CCTC: candidate?.CCTC,
        dateAdded: candidate?.dateAdded,
        uploadedBy: candidate?.uploadedBy,
        vendorName: candidate?.vendorName,
        sourceMedium: candidate?.sourceMedium,
    };
});


export default function ResumeDB() {

    const [currentView, setCurrentView] = useState("search");
    const [isEditMode, setIsEditMode] = useState(false);

    const { value, qhID } = useParams();

    const [formKey, setFormKey] = useState(1);
    const [isRequired, setIsRequired] = useState(true);

    const { setCandidateModalOpen } = useCandidateStore();
    
    const { createJd, updateJd, selectedJD, clearJD } = useJDStore();
    const { resumes, getResumesByJD, updateResume, downloadResumeByID } =
        useResumeStore();

    const navigate = useNavigate();

    const handleFormSubmit = (values) => {

        message.success("Job description created successfully");
        setCurrentView("resultPage");
        navigate('/resultsDB');
    };
    
    const handleUpdateFormSubmit = (values) => {
        console.log("called")
        message.success("Job description updated successfully");
        setCurrentView("search result");
        setIsEditMode(false);
    };
    if (value) {
        var jdValues = {
            hiring_for: selectedJD?.hiring_for,
            title: selectedJD?.title,
            about_company: selectedJD?.about_company,
            client_name: selectedJD?.client_name,
            roles_and_responsibility: selectedJD?.roles_and_responsibility,
            skills: selectedJD?.skills,
            keywords: selectedJD?.keywords,
            soft_skills: selectedJD?.soft_skills,
            education: selectedJD?.education,
            offered_CTC: selectedJD?.offered_CTC,
            creation_date: selectedJD?.creation_date,
            priority: selectedJD?.priority,
            number_of_positions: selectedJD?.number_of_positions,
            valid_until: moment(selectedJD?.valid_until),
            work_location: selectedJD?.work_location,
            work_from_home: selectedJD?.work_from_home,
            notice_period: selectedJD?.notice_period,
            experience: selectedJD?.experience,
            hiring_project_team_name: selectedJD?.hiring_project_team_name,
            hiring_project_team_email_id: selectedJD?.hiring_project_team_email_id,
            hiring_project_group_contact_number:
                selectedJD?.hiring_project_group_contact_number,
        };
    }    
    return (
        <div className="resumeDB-list-wrapper">

            <p className="resumeDB-title">Search Resumes from QHR Flow DataBase</p>
            <Form
                key={formKey}
                className="jd-creation-form"
                name="jd-creation-form"
                justify="start"
                layout="vertical"
                autoComplete="off"
                onFinish={value ? handleUpdateFormSubmit : handleFormSubmit}
                initialValues={jdValues}
            >
                
                <Form.Item
                        required={value ? false : true}
                        name="keywords"
                        className="jd-creation-field-item"
                        label="Mandatory Keywords"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                </Form.Item>  
                <Form.Item
                        required={value ? false : true}
                        name="keywords"
                        className="jd-creation-field-item"
                        label="Optional Keywords"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                </Form.Item>                                            
                <div className="triple-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Experience Range ( Min - Max ) Yrs "
                        name="experience"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            defaultValue={3}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            defaultValue={"5"}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>                    
                    <Form.Item
                        name="offered_CTC"
                        className="jd-creation-field-item"
                        label="CTC Range LPA"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            defaultValue={"10"}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            defaultValue={"15"}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />                        
                    </Form.Item>
                    <Form.Item
                        className="jd-creation-field-item"
                        label="Notice Period (In Days)"
                        name="notice_period"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="number"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                </div>

                <div className="triple-input-wrapper">
                    <Form.Item
                        name="education"
                        className="jd-creation-field-item"
                        label="Education"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            defaultValue={"BE,BTech,MTech,ME,BCA,MCA"}
                            className="jd-creation-input"
                        />
                    </Form.Item>  
                    <Form.Item
                        name="work_location"
                        className="jd-creation-field-item"
                        label="Work Location"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            defaultValue={"Bangalore"}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        name="work_from_home"
                        className="jd-creation-field-item"
                        label="Hybrid Mode"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper"
                        >
                            <Radio className="radio-option" value={true}>
                                Yes
                            </Radio>
                            <Radio className="radio-option" value={false}>
                                No
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>

                <Button
                    className="resumeDb-submit-button"
                    type="primary"
                    htmlType="submit"
                >
                    Search
                </Button>
            </Form>            
            <div className="resumeDB-list-wrapper">
                {
                    currentView === "resultPage" ? (
                        <ResumeDbSearchResults
                        isClickable
                        titleList={candidateDetailsListTable}
                        data={candidateDetailsData}
                        setCurrentView={setCurrentView}
                    />
                    ): <></>
                 }
            </div>
        </div>
    );
}
