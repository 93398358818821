import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Radio, Divider, Form, Input, message, Button, TimePicker, DatePicker } from "antd";
import moment from 'moment'
import editIcon from "../../assets/pencil.png";

import useJDStore from "../../store/jd.store";

import "./profile.scss";
import TextArea from "antd/es/input/TextArea";

export default function Profile() {
    const [isEditMode, setIsEditMode] = useState(false);
    const [formKey, setFormKey] = useState(1);
    const [isRequired, setIsRequired] = useState(true);

    const { value } = useParams();
    const { createJd, updateJd, selectedJD, clearJD } = useJDStore();
    
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    useEffect(() => {
        console.log("value", value);
        if (value) {


        } else {
            setIsEditMode(true)
            clearJD()
            jdValues = {}

        }
    }, [value]);

    const handleFormSubmit = (values) => {
        createJd({
            ...values,
            number_of_positions: parseInt(values.number_of_positions),
            experience: parseInt(values.experience),
            notice_period: parseInt(values.notice_period),
            offered_CTC: parseFloat(values.offered_CTC),
            status: "active"
        });
        message.success("Profile created successfully");
    };

    const handleUpdateFormSubmit = (values) => {
        console.log("called")
        updateJd(
            {
                ...values,
                number_of_positions: parseInt(values.number_of_positions),
                experience: parseInt(values.experience),
                notice_period: parseInt(values.notice_period),
                offered_CTC: parseFloat(values.offered_CTC),
            },
            value
        );
        message.success("Profile updated successfully");
        setIsEditMode(false);
    };
    useEffect(() => {
        console.log(selectedJD);
        setFormKey((prevKey) => prevKey + 1);
    }, [selectedJD]);

    if (true) {
        var jdValues = {
            
            user_name: userDetails?.username,
            organization: userDetails?.organization,
            organization_type: userDetails?.account_type,
            mobile: userDetails?.contact,
            email: userDetails?.email,
            sub_status: userDetails?.sub_status,
            sub_name: userDetails?.sub_name,
            sub_type: userDetails?.sub_type,
            sub_date: userDetails?.offered_CTC,
            sub_start_date: userDetails?.creation_date,
            sub_end_date: userDetails?.priority,
            creation_date: userDetails?.number_of_positions,
        };
    }


    return (
        <div className="profile-form-wrapper">
            <div className="header">
                {isEditMode ?<h5 className="profile-form-title">Super Admin Profile</h5>  :
                              <h5 className="profile-form-title">Admin Profile</h5>  }
                 
                {!isEditMode && (
                    <Button className="profile-edit-button" onClick={() => setIsEditMode(true)} type="primary">
                        <img
                        src={editIcon}
                        alt="Edit"
                        width="14" height="14" 
                        />
                    </Button>
                )}
            </div>
            <Form
                key={formKey}
                className="profile-form"
                name="profile-form"
                justify="start"
                layout="vertical"
                autoComplete="off"
                onFinish={value ? handleUpdateFormSubmit : handleFormSubmit}
                initialValues={jdValues}
            >

                <Form.Item
                    required={value ? false : true}
                    className="profile-field-item"
                    label="User Name"
                    name="user_name"
                >
                    <Input
                        disabled={!isEditMode}
                        required={value ? false : true}
                        className="profile-input"
                    />
                </Form.Item>
                <Form.Item
                        required={value ? false : true}
                        name="organization"
                        className="profile-field-item"
                        label="Organization Name"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="profile-input"
                        />
                </Form.Item>                 
                <Form.Item
                    required={value ? false : true}
                    className="profile-field-item"
                    name="organization_type"
                    label="Select what describes you Best"
                >
                    <Radio.Group
                        disabled={!isEditMode}
                        className="profile-radio-wrapper"
                        onClick
                    >
                        <Radio
                            className="radio-option"
                            value="product-company"
                        >
                            Product Company
                        </Radio>
                        <Radio
                            className="radio-option"
                            value="service-company"
                        >
                            Services Company
                        </Radio>

                        <Radio
                            className="radio-option"
                            value="vendor"
                        >
                            Vendor/Recruitment Partner
                        </Radio>
                    </Radio.Group>
                </Form.Item>

                <div className="triple-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        className="profile-field-item"
                        label="Mobile Number"
                        name="mobile"
                    >
                        <Input
                            disabled
                            required={value ? false : true}
                            className="profile-input"
                        />
                    </Form.Item>
                    <Form.Item
                            required={value ? false : true}
                            name="email"
                            className="profile-field-item"
                            label="Email ID"
                        >
                            <Input
                                disabled
                                required={value ? false : true}
                                className="profile-input"
                            />
                    </Form.Item>
                    <Form.Item
                            required={value ? false : true}
                            name="address"
                            className="profile-field-item"
                            label="Address"
                        >
                            <Input
                                disabled
                                required={value ? false : true}
                                className="profile-input"
                            />
                    </Form.Item>
                </div>

                <div className="triple-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        className="profile-field-item"
                        label="City"
                        name="city"
                    >
                        <Input
                            defaultValue={"Bangalore"}
                            required={value ? false : true}
                            className="profile-input"
                        />
                    </Form.Item>
                    <Form.Item
                            required={value ? false : true}
                            name="country"
                            className="profile-field-item"
                            label="Country"
                        >
                            <Input
                                defaultValue={"India"}                        
                                required={value ? false : true}
                                className="profile-input"
                            />
                    </Form.Item>
                    <Form.Item
                            required={value ? false : true}
                            name="address"
                            className="profile-field-item"
                            label="Pin Code"
                        >
                            <Input
                                defaultValue={"560087"}
                                required={value ? false : true}
                                className="profile-input"
                            />
                    </Form.Item>
                </div>
                
                <div className="triple-input-wrapper">
                <Form.Item
                        required={value ? false : true}
                        name="number_of_positions"
                        className="profile-field-item"
                        label="Subscription Status"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            required={value ? false : true}
                            className="profile-input"
                            defaultValue={"ACTIVE"}
                        />
                    </Form.Item>                    
                    <Form.Item
                        required={value ? false : true}
                        name="priority"
                        className="profile-field-item"
                        label="Subscription Name"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="profile-input"
                            defaultValue={"TRIAL"}
                        />
                    </Form.Item>

                    <Form.Item
                        required={value ? false : true}
                        name="number_of_positions"
                        className="profile-field-item"
                        label="Subscription Type"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            required={value ? false : true}
                            className="profile-input"
                            defaultValue={"PAY MONTHLY"}                            
                        />
                    </Form.Item>

                </div>             

                <div className="triple-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        name="sub_date"
                        className="profile-field-item"
                        label="Subscription Date"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="profile-input"
                        />
                    </Form.Item>  
                    <Form.Item
                        required={value ? false : true}
                        name="sub_start_date"
                        className="profile-field-item"
                        label="Subscription Start Date"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="profile-input"
                        />
                    </Form.Item>
                    <Form.Item
                        required={value ? false : true}
                        name="sub_end_date"
                        className="profile-field-item"
                        label="Subscription End Date"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="profile-input"
                        />
                    </Form.Item>
                </div>
                <Form.Item
                        required={value ? false : true}
                        name="creation_date"
                        className="profile-field-item"
                        label="Profile Creation Date"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="profile-input"
                        />
                </Form.Item>   
                <Button
                    style={{ width: "100%", height: "40px", fontWeight: "600" }}
                    type="primary"
                    disabled={!isEditMode}
                    htmlType="submit"
                >
                    Update Profile
                </Button>
            </Form>
        </div>
    );
}
