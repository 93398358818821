import React, { useState } from "react";
import { Link } from "react-router-dom";
import arrowIcon from "../../assets/right-arrow.png";
import {  Button } from "antd";


import "./resumeResultsSearchSummary.scss";

function ResumeResultsSearchSummary() {
  const [isCollapsedJD, setIsCollapsedJD] = useState(true);
  const [isCollapsedJDrs, setIsCollapsedJDrs] = useState(true);

  const handleToggleCollapsejd = () => {
    setIsCollapsedJD(!isCollapsedJD);
  };
  const handleToggleCollapsers = () => {
    setIsCollapsedJDrs(!isCollapsedJDrs);
  };
  const handleModify = () => {
  };

  const setStatus = (data) => {
    console.log(data);
    localStorage.setItem("status", JSON.stringify({ status: data }));
  };
  return (
     <div className="resumeResultSearchSummary-wrapper">

           <div className="resumeResultsSearchSummary-title">
                Showing Results for Mandatory Keywords
           </div>
           <div className="resumeResultsSearchSummary-details">
               Np 3o days, CTC 0-13LPA, location BLR
           </div>
           <div className="resumeResultsSearchSummary-details">
                <Button
                    className={""}
                    onClick={() => handleModify()}
                >
                    Modify Criteria
                </Button>            
           </div>
    </div>
  );
}

export default ResumeResultsSearchSummary;
