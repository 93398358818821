import React, { useEffect } from "react";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";

import useJDStore from "../../store/jd.store";
import useStatsStore from "../../store/stats.store";
import { useLocation } from "react-router-dom";

import TableJds from "../TableJds";

import "./jds.scss";

export default function JDs(props) {
  console.log(props);

  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const { getAllJds, jdData } = useJDStore();
  const { jdsConsolidatedStats } = useStatsStore();

  useEffect(() => {
    getAllJds();
  }, [status]);

  const titleList = [
    "Sr. No.",
    "Date Added",    
    "Client/TA",
    "Job Title",
    "Available Resumes",
    "Matching Resumes",
    "Screen Rejected",
    "Screen Selected",
    "Interviews Reject",
    "Final Selects",
    "Priority",
    "Assigned To",
    "SPOC",
    "Hiring Manager",
    "Date Modified",
  ];

  const GetTeamAssignment =(jds)=>{
    var assignedTeam=''
    jds?.JDAssignment?.Assignments?.map((assign,idx)=>{
      if (assignedTeam === ''){
        assignedTeam = assign.AssignedToName;
      }else{
        assignedTeam = assignedTeam +','+assign.AssignedToName;
      }
      
     return })

     return assignedTeam
  }

  const jdsDetailData = jdsConsolidatedStats?.JDListInternalActivity?.map((jds, idx) => {
    return {
      id: idx + 1,
      date_added: jds?.dateadded,      
      client_name: jds?.client,
      title: '['+jds?.qhcompanyjid+'] '+jds?.title,
      available_resumes: jds.candidatestatus?.NoOfResumesAvailable,
      matching_resume: jds?.candidateStatus?.NoOfResumesAvailable,
      screen_selected: jds?.resumestatus?.NoOfScreenSelected,
      screen_rejected: jds?.resumestatus?.NoOfScreenRejected,
      interviews: jds?.interviewstatus?.NoOfInterviewStatusRejected,
      final_select: jds?.interviewstatus?.NoOfInterviewStatusFinalSelect,
      priority: jds?.priority,
      assigned_to: "to do",         
      SPOC: jds?.spoc,
      hiring_project_team_name: "To Do",
      date_modified: "to do",
    };
  });

  return (
    <div className="jds-wrapper">
      {jdData?.length > 0 ? (
        <TableJds
          isClickable={true}
          isJDPage={true}
          titleList={titleList}
          data={jdsDetailData}
        />
      ) : (
        <Empty style={{ margin: "60px" }} />
      )}
    </div>
  );
}
