import { create } from "zustand";

import axios from "../helper/axios";

var userData = JSON.parse(localStorage.getItem("userDetails"));

const useStatsStore = create((set) => ({
    teamsDashConsolidatedStats: null,
    teamsInternalConsolidatedStats:null,
    jdsConsolidatedStats:null,
    myDashConsolidatedStats: null,
    myInternalConsolidatedStats:null,
    interviewSchedules:null,
    
    getTeamsDashBoardConsolidatedStats: async (jdid,id) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const response = await axios.get(`/getTeamDashBoardConsolidated/${userData.account_type}/${userData.qhoid}/${userData.qhuid}`);

            console.log(response.data);
            set({ teamsDashConsolidatedStats: response?.data });
        } catch (err) {
            console.error(err);
        }
    },

    getTeamsInternalConsolidatedStats: async (jdid,id) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const duration = "24"
           const response = await axios.get(`/getTeamInternalConsolidated/${userData.account_type}/${userData.qhoid}/${userData.qhuid}/${duration}`);

            console.log(response.data);
            set({ teamsInternalConsolidatedStats: response?.data });
        } catch (err) {
            console.error(err);
        }
    }, 
    getJdsConsolidatedStats: async (data) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           console.log(data);
           const duration = "all"
           const response = await axios.post(`/getJdsConsolidated/${userData.account_type}/${userData.qhoid}/${userData.qhuid}/${duration}`,data);
           set({ jdsConsolidatedStats: response?.data });
           console.log(response.data);
        } catch (err) {
            console.error(err);
        }
    },
    getMyDashBoardConsolidatedStats: async (jdid,id) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const response = await axios.get(`/getMyDashBoardConsolidated/${userData.account_type}/${userData.qhoid}/${userData.qhuid}`);

            console.log(response.data);
            set({ myDashConsolidatedStats: response?.data });
        } catch (err) {
            console.error(err);
        }
    },
    getMyInternalConsolidatedStats: async (jdid,id) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const duration = "24"
           const response = await axios.get(`/getMyInternalConsolidated/${userData.account_type}/${userData.qhoid}/${userData.qhuid}/${duration}`);

            console.log(response.data);
            set({ myInternalConsolidatedStats: response?.data });
        } catch (err) {
            console.error(err);
        }
    },
    
    getInterviewSchedule: async () => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const response = await axios.get(`/getInterviewSchedule/${userData.account_type}/${userData.qhoid}/${userData.qhuid}`);

            console.log(response.data);
            set({ interviewSchedules: response?.data });
        } catch (err) {
            console.error(err);
        }
    },

    

}));

export default useStatsStore;
