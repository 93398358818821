import { Doughnut, Pie , Bar} from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import "./pieChart.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartdata = {

  labels :[ "T", "A","CL","JD","NP","B/D","AHCT","AHNP"],
  datasets : [
    {
      label : '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};


function PieChart({ pieData }) {
  return (
      <div className= "pieChart-wrapper" >
        <Pie
          data={PieChartdata}
          options={{
            plugins: {
              title: {
                display: false,
                text: "Users Gained between 2016-2020"
              }
            }
          }}
        />
      </div>

  );
}
export default PieChart;