import React, { useEffect,useState } from "react";
import { useParams } from "react-router-dom";
import useResumeStore from "../../store/resume.store";
import useJDStore from "../../store/jd.store";
import {Input,Card, Button,Form} from "antd"
import editIcon from "../../assets/pencil.png";
import ResumeSkillsCardTable from "../ResumeSkillsCardTable";

import "./resumeAnalysisNew.css";

function ResumeAnalysisNew( {jdID}) {

  const { value } = useParams();
  const { Search } = Input;
  const [candidateFound, setIsCandidateFound] = useState(false);
  const [checkAgain, setCheckAgain] = useState(false);
  const [resultString, setResultString] = useState("");
  const [canDetails,setCandidateDetails]= useState({name:"",email:"",mobile:"",role:"",exp:"",finalScore:""});
  const [currentResumeAnalysis,setCurrentResumeAnalysis]= useState({name:"",email:"",mobile:"",role:"",exp:"",skillData:[]});

  const {allApplications } =  useResumeStore();  
  
  const { selectedJD } = useJDStore();

  const titleList = [
    "Keyword",    
    "Suitability",
    "Required Occurance",
    "Found Occurance"
  ];
  const SkillsData = []

  useEffect(() => {
    console.log("");
  }, [value]);


   const GetResumeDetails = (qhrid)=>{
    for(let idx = 0; idx < allApplications?.length; idx++){  
        if (allApplications[idx]?.qhrid === qhrid) {
            console.log("Application Details", idx,allApplications[idx])   
            return {
                found: true,
                name: allApplications[idx]?.applicationid.name,
                mobile: allApplications[idx]?.applicationid.mobile,
                email: allApplications[idx]?.applicationid.email,
                finalScore:allApplications[idx]?.resumescore,
        } 
       }
     }
     return {
      found: false
     }  
   }

  const handleKeys = (fianlScore) =>{

    for(const [key, value] of Object.entries(fianlScore?.scorelist)) {
      const keyWordsArray = new Map([])
      for(const [key2, value2] of Object.entries(fianlScore?.scorelist[key].keywords)) {
        keyWordsArray.set(key2,value2)
      }
      //converting from map to array ??? needs to optimize this
      const arr = [...keyWordsArray]?.map(([skill, score, required,found]) => ({ skill, score, required,found }));
      const one = {
        kArray:arr,
        desc:fianlScore?.scorelist[key]?.description,
        score:fianlScore?.scorelist[key]?.score,
        type:key 
      }
      SkillsData?.push(one)
      }
    console.log("SkillsData",SkillsData);
  }
  const onSearch = (value, _e, info) => {
    setResultString("")
    const dt= GetResumeDetails(value)
    if(dt.found){
        setResultString("")
        setIsCandidateFound(true) 
        handleKeys(dt.finalScore)
        setCandidateDetails({name:dt.name,email:dt.email,mobile:dt.mobile,role:dt.finalScore?.role,exp:dt.finalScore?.exp,
                             finalScore:dt.finalScore?.score,skillData:[...SkillsData]})
    }else{
        setResultString("Candidate Not Found")
        setCandidateDetails({name:"",email:"",mobile:"",role:"",exp:"",finalScore:0,skillData:[]})
    } 
    console.log("Current Values", canDetails.role,dt,canDetails.skillData);    
  }
  const handleAnalyzeAgain = () => {
    console.log("called")
  };

  const handleChangeSkills = (value) => {
       setCheckAgain(value)
  };

  return (
   <div className="resumeAnalysisNew-wrapper">
      <div className="resumeAnalysisNew-Id">
          <Search
              placeholder="Enter Resume ID"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
        />
      </div>
      {
        candidateFound?( 
        <div className="resumeAnalysisNew-details">
          <Input
           type="text"
           className="resumeAnalysisNew-details-items"
           id="name"
           placeholder="Name"
           value={canDetails.name}
           readOnly
         />
          <Input
           type="text"
           className="resumeAnalysisNew-details-items"
           id="email"
           placeholder="Email"
           value={canDetails.email}
           readOnly
         />
          <Input
           type="text"
           className="resumeAnalysisNew-details-items"
           id="Mobile"
           placeholder="Mobile"
           value={canDetails.mobile}
           readOnly
         />                    
        </div>
       ):resultString
      }

      <div className="resumeAnalysisNew-keywords">
        { (
            !checkAgain&&<Button className="resumeAnalysisNew-keywords-button" onClick={() => handleChangeSkills(true)}>
                        <img
                        src={editIcon}
                        alt="Edit"
                        width="14" height="14" 
                        />
            </Button>            
          )         
        } 
        { 
          (
            checkAgain&&<Button className="resumeAnalysisNew-keywords-button" onClick={() => handleChangeSkills(false)}>
                        <img
                        src={editIcon}
                        alt="Edit"
                        width="14" height="14" 
                        />
            </Button>            
          )          
        }                
        <Form
            className="resumeAnalysisNew-keywords"
            justify="start"
            layout="vertical"
            autoComplete="off"
            onFinish={handleAnalyzeAgain()}
        >
                <Form.Item
                    name="mKeywords"
                    className="resumeAnalysisNew-keywords-items"
                    label="Mandatory Skills"
                >
                    <Input
                        type="text"
                        placeholder={selectedJD?.JDRequired.Keywords}
                        disabled= {!checkAgain}
                        style={{
                          fontSize: 14,
                          fontWeight:700,
                          color:"black",
                          textAlign:"left"
                        }}
                    />
                </Form.Item>                  
                <Form.Item
                      name="keywords"
                      className="resumeAnalysisNew-keywords-items"
                      label="Optional Skills"
                      disabled= {!checkAgain}
                >
                    <Input
                        type="text"
                        placeholder={""}
                        disabled= {!checkAgain}
                        style={{
                          fontSize: 14,
                          fontWeight:700,
                          color:"black",
                          textAlign:"left"
                        }}
                    />
                </Form.Item>                              
               {
                checkAgain&& <Button
                    style={{ width: "20%", height: "40px", fontWeight: "600" }}
                    type="primary"
                    htmlType="submit"
                >
                    Do Resume Analysis again
                </Button>
               }
        </Form>
      </div> 

      <div className="resumeAnalysisNew-verdicts">
      <div className = "resumeAnalysisNew-verdicts-skills">
          <div className="resumeAnalysisNew-verdicts-others-items">
              <Card title="Final Analysis" bordered={true}>
               { canDetails?.finalScore?(
                <div>
                  Resume is {canDetails?.finalScore} % Suitable.
                </div>)
               :(
                <div>
                  Resume is Not Suitable.
                </div>)}
              </Card>
          </div>
        </div>        
        <div className = "resumeAnalysisNew-verdicts-skills">
          <div className="resumeAnalysisNew-verdicts-others-items">
              <Card title="Skills Analysis" bordered={true}>
                 {
                   canDetails.skillData !=0 ?
                       (<div>
                         Skills are {canDetails?.skillData?.[2].score} % Matching.
                          <div>
                              <div style={{marginTop:"15px",fontWeight: "400",fontSize:"12px" ,textAlign:"center"}}>
                                  Mandatory Skills Results
                              </div>
                              <ResumeSkillsCardTable
                              titleList={titleList}
                              data={canDetails?.skillData?.[2].kArray}
                              shortTable
                              />
                          </div>
                          <div>
                              <div style={{marginTop:"15px",fontWeight: "400",fontSize:"12px" ,textAlign:"center"}}>
                                  Optional Skills Results
                              </div>
                              <ResumeSkillsCardTable
                              titleList={titleList}
                              data={canDetails?.skillData?.[2].kArray}
                              shortTable
                              />
                          </div>                          
                        </div>):""
                 }
              </Card>
          </div>
        </div>
        <div className = "resumeAnalysisNew-verdicts-skills">
          <div className="resumeAnalysisNew-verdicts-others-items">
              <Card title="Role Analysis" bordered={true}>
                 {
                   canDetails.skillData !=0 ?
                       (<div>
                         Candidate is {canDetails?.skillData?.[1].score} % suitable for the {canDetails?.role} role.
                          <div>
                              <div style={{marginTop:"15px",fontWeight: "400",fontSize:"12px" ,textAlign:"center"}}>
                                  Mandatory Skills Results
                              </div>
                              <ResumeSkillsCardTable
                              titleList={titleList}
                              data={canDetails?.skillData?.[1].kArray}
                              shortTable
                              />
                          </div>
                          <div>
                              <div style={{marginTop:"15px",fontWeight: "400",fontSize:"12px" ,textAlign:"center"}}>
                                  Optional Skills Results
                              </div>
                              <ResumeSkillsCardTable
                              titleList={titleList}
                              data={canDetails?.skillData?.[1].kArray}
                              shortTable
                              />
                          </div>                          
                        </div>):""
                 }
              </Card>
          </div>
        </div>  
        <div className = "resumeAnalysisNew-verdicts-skills">
          <div className="resumeAnalysisNew-verdicts-others-items">
              <Card title="Education Analysis" bordered={true}>
                 {
                   canDetails.skillData !=0 ?
                       (<div>
                         Candidate is having following degree(s).
                          <div>
                              <div style={{marginTop:"15px",fontWeight: "400",fontSize:"12px" ,textAlign:"center"}}>
                                  Education Keyword Results
                              </div>
                              <ResumeSkillsCardTable
                              titleList={titleList}
                              data={canDetails?.skillData?.[1].kArray}
                              shortTable
                              />
                          </div>                        
                        </div>):""
                 }
              </Card>
          </div>
        </div>                
        <div className = "resumeAnalysisNew-verdicts-others"> 
          <div className="resumeAnalysisNew-verdicts-others-items">
            <Card title="Exp Analysis" bordered={true}>
              Card content
            </Card>
         </div>
         <div className="resumeAnalysisNew-verdicts-others-items">
            <Card title="Gap Analysis" bordered={true}>
              Card content
            </Card>
         </div>         
         <div className="resumeAnalysisNew-verdicts-others-items">
            <Card title="Grammer Analysis" bordered={true}>
              Card content
            </Card>
         </div>          
         <div className="resumeAnalysisNew-verdicts-others-items">
            <Card title="Frequent Hopper Analysis" bordered={true}>
              Card content
            </Card>
         </div> 
         <div className="resumeAnalysisNew-verdicts-others-items">
            <Card title="Barred Company Analysis" bordered={true}>
              Card content
            </Card>
         </div> 
         <div className="resumeAnalysisNew-verdicts-others-items">
            <Card title="Barred College Analysis" bordered={true}>
              Card content
            </Card>
         </div>                                                     
     
        </div>
      </div>             
   </div>
  );
}

export default ResumeAnalysisNew;
