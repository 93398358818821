import { Doughnut, Pie , Bar} from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend ,
  CategoryScale,
  LinearScale,
  BarElement,
  Title} from 'chart.js';
import "./stackChart.scss";

ChartJS.register(  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,ArcElement);

const StackChartdata = {

  labels : [""],
  datasets : [
    {
      label : 'Skills %',
      data: ["40"],
      backgroundColor: 'rgb(255, 99, 132)',
      borderWidth: 1,
    },
    {
      label : 'A',
      data: [3],
      backgroundColor: 'rgb(75, 192, 192)',
      borderWidth: 1,
    },
    {
      label : 'O',
      data: [12],
      backgroundColor: 'rgb(53, 162, 235)',
      borderWidth: 1,
    },  
    {
      label : 'AH',
      data: [1],
      backgroundColor: 'rgb(53, 162, 260)',
      borderWidth: 1,
    },  
    {
      label : 'J',
      data: [2],
      backgroundColor: 'rgb(53, 162, 212)',
      borderWidth: 1,
    },                
  ],
};


function StackChart({ stackData, stack, showLegend }) {
  return (
    <div className="chart-container">
        <Bar
          data={StackChartdata}
          options={{
            plugins: {
              title: {
                display: false,
                text: "Users Gained between 2016-2020"
              },
              legend: {
                display: false,
              },
              axis: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
              },  
            },
            scales:{
              x: {
                stacked: stack,
                display:false,
                grid: {
                  drawOnChartArea:false,
                  display: false,
                },   
                gridLines: {
                  display:false,
                  offset: false
                }, 
                ticks: {
                  display: false,
                },                               
              },
              y: {
                display:false,
                stacked: stack,
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },  
                gridLines: {
                  drawBorder: false,
                  display:false,
                  offset: false
                },              
              }
            }
          }}
        />
      </div>
  );
}
export default StackChart;