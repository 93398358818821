import "./App.css";
import Header from "./components/header";
import { Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";
// Import your other components here
import { useSelector } from "react-redux";
import Mainpage from "./components/mainpage";
import Dashboard from "./components/dashboard";
import Alljds from "../src/components/alljds/alljds";
import TeamAssignments from "../src/components/teamAssignment";
import MyDashboard from "../src/components/myDashboard";
import TeamTaskUpdates from "../src/components/teamTaskUpdates";
import MyInternalTaskUpdates from "../src/components/myInternalTaskUpdates";
import MySelection from "../src/components/mySelection";
import TeamSelection from "../src/components/TeamSelection";
import MyJds from "./components/myJDs";
import AssignedJds from "./components/assignedJDs";
import Jd from "../src/components/particularjds";
import JdsDashboard from "../src/components/jdsDashBoard";
import JdsPriority from "../src/components/jdsPriority";
import InterviewCalendar from "../src/components/interviewCalendar"
import Resumes from "../src/components/particularjds/resumes/resumes";
import Jd_description from "../src/components/particularjds/jdDescription";
import Createjd from "./components/createJD";
import CandidateDetails from "../src/components/particularjds/resumes/resumecomponents/candidateDetails/candidateDetaild/candidateDetails";
import ResumeSummary from "../src/components/particularjds/resumes/resumecomponents/summary/summary";
import Summary from "../src/components/particularjds/resumes/resumecomponents/summary/summary";
import Signin from "./authentication/Signin";
import Signup from "./authentication/Signup";
import Otpscreen from "./components/otpscreen/otpscreen";
import Settings from "./components/settings";
import Customers from "./components/customers";
import SpecificCustomer from "./components/specificCustomer";
import TimeSheet from "./components/timeSheet";
import ContactBook from "./components/contactBook";
import Leaves from "./components/leaves";
import Holidays from "./components/holidays";
import Assignemnts from "./components/particularjds/assignment/assignments";
import Allresumes from "./components/allResumes";
import ResumeSource from "./components/resumeSource";
import Profile from "./components/profile/profile";
import JDs from "./components/JDs";
import CandidateProfile from "./components/candidateProfile";
import ResumeDB from "./components/resumeDB";
import ResumeDbSearchResults from "./components/resumeDbSearchResults"
import Fromteammember from "./components/fromTeamMember/fromTeamMember";
import Particularmembers from "./components/particularMemberDetails";
import MainpageStats from "./components/fromTeamMember/mainpage";
import Landing from "./landing"
import JobListings from "./landing/jobSeekers/jobListing";
import CareerWebsites from "./landing/careerWebsites";
import ResumeAnalysisFree from "./landing/resumeAnalysisFree";
import JobListingSearchReasults from "./landing/jobSeekers/jobListingSearchResults";
import JDsConsolidated from "./components/JdsConsolidated";

var PrivateRoute = (props) => {
  const authFlag = useSelector((state) => state.authReducer.authFlag);
  var authDetail = localStorage.getItem("userDetails");

  const auth = localStorage.getItem("token");
  return authDetail ? <Outlet /> : <Navigate to="/landing" />;
  // return <Outlet />;
};
function App() {
  return (
    <div id="main">
      <div id="body">
        <Routes>
          <Route path="/landing" element={<Landing />} />
          <Route path="/login" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/otp" element={<Otpscreen />} />
          <Route path="/jobSeekers" element={<JobListings />} />  
          <Route path="/jobListingSeachResults" element={<JobListingSearchReasults />} />
          <Route path="/resumeAnalysisFree" element={<ResumeAnalysisFree />} />
          
          <Route path="/careerWebsites" element={<CareerWebsites />} />  
                          
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Mainpage />}>
              <Route index element={<Navigate to="dashboard" />} />
              <Route path="profile" element={<Profile />} />
              <Route exact path="dashboard" element={<Dashboard />} />
              <Route path="TeamAssignments" element={<TeamAssignments />} />
              <Route path="myDashboard" element={<MyDashboard />} />
              <Route path="mySelection" element={<MySelection />} />
              <Route path="teamSelection" element={<TeamSelection />} />
              <Route path="MyJds" element={<MyJds />} />
              <Route path="assignedJds" element={<AssignedJds />} />
              <Route path="teamTaskUpdates" element={<TeamTaskUpdates />} />
              <Route path="myInternalTaskUpdates" element={<MyInternalTaskUpdates />} />   
              <Route path="jdsConsolidated" element={<JDsConsolidated />} />           
              <Route path="jdsDashboard" element={<JdsDashboard />} />
              <Route path="jdsPriority" element={<JdsPriority />} />
              <Route path="InterviewCalendar" element={<InterviewCalendar />} />
              
              
              <Route path="resume_source" element={<ResumeSource />} />
              <Route path="From_My_Team" element={<MainpageStats />}>
                {/* Add more routes inside Fromteammember */}
                {/* <Route index element={<Navigate to="" />} /> */}
                <Route path="" element={<Fromteammember />} />
                <Route path=":value" element={<Particularmembers />} />
                {/* Add as many routes as needed */}
              </Route>

              <Route path="From_Vendors" element={<Fromteammember />} />
              <Route path="particularMember" element={<Particularmembers />} />

              <Route path="settings" element={<Settings />} />
              <Route path="customers" element={<Customers />} />
              <Route path="specificCustomer">
                <Route index element={<Customers />} />
                <Route path=":clientName/:clientID" element={<SpecificCustomer />} >              
                </Route>
              </Route>              
              <Route path="timeSheet" element={<TimeSheet />} />
              <Route path="contactBook" element={<ContactBook />} />
              <Route path="leaves" element={<Leaves />} />
              <Route path="holidays" element={<Holidays />} />
              <Route path="jds">
                <Route index element={<JDs />} />
                  <Route path=":value" element={<Createjd />} >
                    <Route path="candidateProfile/:jdID/:id/:resumeID" element={<CandidateProfile />} ></Route>              
                  </Route>
              </Route> 
              <Route path="createJd" element={<Createjd />} />
              <Route path="resumes" element={<ResumeDB />} />
              <Route path="resultsDB" element={<ResumeDbSearchResults />} />

              <Route index element={<Outlet />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
