import React, { useState,useRef } from "react";
import { Empty, Input, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import editIcon from "../../assets/edit.svg";
import PieChart from "../PieChart";
import StackChart from "../StackChart";

import "./resumeResultsCard.scss";


export default function ResumeResultsCard({
    isClickable = false,
    isResumePage = false,
    isEditField = false,
}) {
    const navigate = useNavigate();
    const [isEditActive, setIsEditActive] = useState(false);
    const [activeFieldID, setActiveFieldID] = useState();
    const [activeRowID, setActiveRowID] = useState();
    const [editInput, setEditInput] = useState(""); // State to hold input value
    const [editInputKey, setEditInputKey] = useState("");
    const inputRef = useRef(null);

    const focusInputField = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const handleResumeDetailClick = (id,qhID) => {
        
    };
   const handleResumeStatsClick = (id)=>{
    
    navigate(id)
   }

   const handleModify = () => {
   };

   const showPieChart = (tableInfo) =>{
        if( tableInfo === ""){
            return false
        }
        else{
            return false
        }
    }

    return (
        <div className="resumeResultsCard-wrapper">
            <div className="resumeResultsCard-name">
                <div className="resumeResultsCard-name-row">
                    Vinay Warde
                </div>
                <div className="resumeResultsCard-name-row">
                    ECTC: 54 LPA
                </div>
                <div className="resumeResultsCard-name-row">
                    NP: 15 Days
                </div>
                <div className="resumeResultsCard-name-row">
                    ID
                </div>                
            </div>
            <div className="resumeResultsCard-heading">
                Job Details and Location:
            </div>
            <div className="resumeResultsCard-current">
                <div className="resumeResultsCard-current-column">
                    Designation:
                </div>
                <div className="resumeResultsCard-current-column">
                    Company:
                </div>
                <div className="resumeResultsCard-current-column">
                    CTC:
                </div>
                <div className="resumeResultsCard-current-column">
                    Location:
                </div>
                <div className="resumeResultsCard-current-column">
                    Preferred Location:
                </div>                
            </div>            
            <div className="resumeResultsCard-heading">
                Skills:
            </div>
            <div className="resumeResultsCard-skills">
                Python, automation, testing
            </div>
            <div className="resumeResultsCard-heading">
                JDs Applied:
            </div>
            <div className="resumeResultsCard-Jds-Table">
                <div className="resumeResultsCard-Jds-Table-column">
                    JDID 001
                </div>
                <div className="resumeResultsCard-Jds-Table-column">
                    HCTC
                </div>
                <div className="resumeResultsCard-Jds-Table-column">
                    Suitability: 50%
                </div>
                <div className="resumeResultsCard-Jds-Table-column">
                    By: Sucheta
                </div>
                <div className="resumeResultsCard-Jds-Table-column">
                     On: 24-2-2024
                </div>                
            </div>
            <div className="resumeResultsCard-othes">
               <Button
                    className={""}
                    onClick={() => handleModify()}
                >
                    Email
               </Button>
               <Button
                    className={""}
                    onClick={() => handleModify()}
                >
                    Modify Skills
                </Button>  
                <Button
                    className={""}
                    onClick={() => handleModify()}
                >
                    Apply on Another JD
                </Button>  
                <Button
                    className={""}
                    onClick={() => handleModify()}
                >
                    Whatsapp
               </Button>                
            </div>                                                            
        </div>
    );
}
