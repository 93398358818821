import React from "react";
import { Modal, Form, Input, Button, Radio, Dropdown ,Space, Select} from "antd";
import useClientStore from "../../store/clientModal.store";

import "./taAddModal.scss";

export default function TAAddModal(
    clientName
) {
    const { isModalOpen, handleModalCancel, handleClientTAAdd,setCurrentClientName } = useClientStore();

    const handleFormSubmit = (value) => {
        setCurrentClientName(clientName);
        handleClientTAAdd(value);
        handleModalCancel();
    };

    return (
        <Modal
            title= { "Provide New TA Details"}
            visible={isModalOpen}
            footer={null}
            onCancel={handleModalCancel}
            className="subUserAddModel-title"
        >
        <Form
                    labelCol={{ flex: "150px" }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                    onFinish={handleFormSubmit}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add User's Name",
                            },
                        ]}
                        label="TA Name"
                        name="taname"
                    >
                        <Input   className="subUserAddModel-input"/>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: false,
                                maxLength: 10,
                                message: "Please add contact details",
                            },
                        ]}
                        label="Contact Number"
                        name="tanumber"
                    >
                        <Input   className="subUserAddModel-input"/>
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                maxLength: 10,
                                message: "Please Add Contact Details",
                            },
                        ]}
                        label="Email ID"
                        name="tamail"
                    >
                        <Input   className="subUserAddModel-input"/>
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Organization",
                            },
                        ]}
                        label="Role"
                        name="tarole"
                    >
                    <Select                           
                        defaultValue="select"
                        className="role"
                        options={[
                                 { value: 'select', label: 'Kindly Select Role' },
                                 { value: 'ta', label: 'TA' },
                                 { value: 'lead_ta', label: 'LEAD TA' },
                                 { value: 'manage', label: 'MANAGER' },
                                 ]}
                   />                     

                    </Form.Item>    
                    <Form.Item
                        rules={[
                            {
                                maxLength: 10,
                                message: "Please Add Group Details",
                            },
                        ]}
                        label="Group"
                        name="tagroup"
                    >
                        <Input   className="subUserAddModel-input"/>
                    </Form.Item>                                    

                    <Form.Item
                        rules={[
                            {
                                maxLength: 10,
                                message: "Please Add Lead Details",
                            },
                        ]}
                        label="Reporting Lead"
                        name="talead"
                    >
                        <Input   className="subUserAddModel-input"/>
                    </Form.Item>
                    
                    <Form.Item
                        rules={[
                            {
                                maxLength: 10,
                                message: "Please Add Manager Name",
                            },
                        ]}
                        label="Reporting Manager"
                        name="tamanager"
                    >
                        <Input   className="subUserAddModel-input"/>
                    </Form.Item>

                    <Button htmlType="submit" type="primary">
                        Submit
                    </Button>
        </Form>

        </Modal>
    );
}
