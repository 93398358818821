import React, { useState, useEffect } from "react";
import { Button, Empty } from "antd";

import Table from "../Table";
import SubUserAddModal from "../SubUserAddModal";

import useMembersStore from "../../store/members.store";
import useSubUserStore from "../../store/subUserModal.store";

import "./leaves.scss";

const currentTeamTitles = [
    "S. No.",
    "Name",
    "Mobile No.",
    "Email ID",
    "Role",
    "Admin Rights",
    "Action"
];

const vendorTitles = [
    "S. No.",
    "Name",
    "Mobile No.",
    "Email ID",
    "Address",
    "City",
    "Country",
    "Vendor Alias",
    "Vendor Rate",
    "Referred By",
    "Vendor Status",
    "Onboarding Date",
    "Credentials",
    "Action",
];

function Leaves() {
    const [currentView, setCurrentView] = useState("team");

    const { getTeamMembers, getVendors, vendors, teamMembers } =
        useMembersStore();

    const { setModalOpen, setCurrentMemberType, removeMember } =
        useSubUserStore();

    useEffect(() => {
        getTeamMembers();
        getVendors();
    }, []);

    const teamMembersList =
        teamMembers && teamMembers.length > 0
            ? teamMembers.map((member, idx) => ({
                  id: idx + 1,
                  username: member?.username,
                  contact: member?.contact,
                  email: member?.email,
                  remove: (
                      <div
                          style={{
                              cursor: "pointer",
                              color: "blue",
                              fontWeight: "600",
                          }}
                          onClick={() => removeMember(member.ID)}
                      >
                          Remove
                      </div>
                  ),
                  admin: `${member?.admin}`,
              }))
            : [];

    const vendorsList =
        vendors && vendors.length > 0
            ? vendors.map((member, idx) => ({
                  id: idx + 1,
                  username: member?.username,
                  contact: member?.contact,
                  email: member?.email,
                  address: member?.address,
                  vendor_alias: member?.vendor_alias,
                  vendor_rate: member?.vendor_rate,
                  referred_by: member?.referred_by,
                  vendor_status: member?.vendor_status,
                  date_added: member?.date_added,
              }))
            : [];

    const handleAddTeamClick = () => {
        setModalOpen();
        setCurrentMemberType("team");
    };
    const handleAddVendorClick = () => {
        setModalOpen();
        setCurrentMemberType("vendor");
    };

    return (
        <div className="customers-container">
            <div className="customers-btns-wrapper">
                <Button
                    onClick={() => setCurrentView("team")}
                    className={`customer-btn ${
                        currentView === "team" && "customer-btn-active"
                    }`}
                >
                    Team Members
                </Button>
                <Button
                    onClick={() => setCurrentView("vendor")}
                    className={`customer-btn ${
                        currentView === "vendor" && "customer-btn-active"
                    }`}
                >
                    Vendor List
                </Button>
            </div>
            {currentView === "team" ? (
                <div className="customer-members-wrapper">
                    <div style={{display:"flex"}}>
                    <h2 className="customer-members-list-title">Current Users</h2>
                    <div className="customer-members-add-wrapper">
                        <Button
                            onClick={handleAddTeamClick}
                            type="primary"
                            className="customer-members-add-btn"
                        >
                            + Add User
                        </Button>
                    </div>
                    </div>
                    {teamMembers?.length > 0 ? (
                        <Table
                            titleList={currentTeamTitles}
                            data={teamMembersList}
                        />
                    ) : (
                        <Empty style={{ margin: "60px" }} />
                    )}

                  
                </div>
            ) : (
                <div className="members-wrapper">
                    <div style={{display:"flex"}}>
                    <h2 className="members-list-title">Current Vendors</h2>
                    <div className="members-add-wrapper">
                        <Button
                            onClick={handleAddVendorClick}
                            type="primary"
                            className="members-add-btn"
                        >
                            + Add Vendor
                        </Button>
                    </div>
                    </div>
                    {vendors?.length > 0 ? (
                        <Table titleList={vendorTitles} data={vendorsList} />
                    ) : (
                        <Empty style={{ margin: "60px" }} />
                    )}

                  
                </div>
            )}
            <SubUserAddModal />
        </div>
    );
}

export default Leaves;
