import React, { useState } from "react";

import { Field, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../actions";
import { Link, useNavigate } from "react-router-dom";
import logo from ".././assets/QuallyHireGreen.png";
import "./signup.css";

const Signup = (props) => {
  const [loader, SetLoader] = useState(false);
  const [inputData, setFormData] = useState({
    username: "",
    organization: " ",
    contact: 1,
    email: "",
    account_type: "",
  });
  const[error,setError] = useState("")

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const onSubmit = () => {
    console.log("formData", inputData);
    SetLoader(true);
  
    dispatch(actions.Signup(inputData, (error, status) => {
      if (!error) {
        navigate("/otp", { state: { inputData, type: "signup" } });
        SetLoader(false);
      } else {
        // Handle the error case
        SetLoader(false);
        console.error("API Error:", error);
        setError(error + " please sign in" ); // Set the error message in state
      }
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...inputData, [name]: value });
  };
  const handleOnclick =(e) =>{
    navigate('/landing');
  }
  return (
    <div className="container1">
      <div className="signup">
         <div className="header-image-wrapper">
            <img 
               src={logo} 
               className="header-image" 
               onClick={() => handleOnclick()}
               />
          </div>
        <div className="header">
          <div>
            <h2 className="form-header">Signup to QuallyHire</h2>
          </div>
        </div>
        <label className="form-label">
        Enter your details
              </label>
        
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-input">
            <div className="form-outline mb-4">
              {/* <Field name="email" className="form-control text-start " placeholder='Your Email ID' component="input" type="text" /> */}
              <div class="input-block">
                <input
                  type="text"
                  name="username"
                  id="input-text"
                  required
                  spellcheck="false"
                  onChange={handleChange}
                />
                <span class="placeholder">Full Name</span>
              </div>
            </div>
            <div className="form-outline mb-4">
              <div class="input-block">
                <input
                  type="text"
                  name="organization"
                  id="input-text"
                  required
                  spellcheck="false"
                  onChange={handleChange}
                />
                <span class="placeholder">Organization</span>
              </div>
            </div>
            <div className="form-outline mb-4">
              <div class="input-block">
                <input
                  type="text"
                  name="email"
                  id="input-text"
                  required
                  spellcheck="false"
                  onChange={handleChange}
                />
                <span class="placeholder">Email</span>
              </div>
            </div>

            <div className="form-outline mb-4">
              <div class="input-block">
                <input
                  type="text"
                  name="contact"
                  id="input-text"
                  required
                  spellcheck="false"
                  onChange={handleChange}
                />
                <span class="placeholder">Number</span>
              </div>
            </div>

            <div className="form-outline  mb-4">
              <label className="form-label">
                Select what best describes you
              </label>
              <div className="row userType">
                <div className="col">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        name="account_type"
                        value="product_company"
                        className="form-check-input"
                        onChange={handleChange}
                      />{" "}
                      Product Company
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        name="account_type"
                        value="service_company"
                        className="form-check-input"
                        onChange={handleChange}
                      />{" "}
                      Service Company
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        name="account_type"
                        value="vendor"
                        className="form-check-input"
                        onChange={handleChange}
                      />{" "}
                      Vendor/Recruitment Partner
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {loader ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <div>
              <button type="submit" className="Submit_button">
                Verify Your Email ID
              </button>
              <p className="text-danger center" style={{textAlign:"center"}}>{error}</p>
              </div>
            )}
          </div>
        </form>
      </div>
      <div class="footer">
        <div>
          <a>
            <p>
              Already have an account? <Link to={'/login'}>Sign In</Link>
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

var Sign = reduxForm({
  form: "Signup",
})(Signup);

export default Sign;
