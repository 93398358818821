import React, { useEffect } from "react";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";

import useStatsStore from "../../store/stats.store";

import "./myInternalTask.scss";
import TeamAssignmentCard from "../TeamAssignmentCard";

export default function MyInternalTaskUpdates(props) {
  console.log(props);


  const status = localStorage.getItem("status");

  const { myInternalConsolidatedStats } = useStatsStore();

  useEffect(() => {

  }, []);

  return (
    <div className="teamTask-wrapper">
      <div className="teamTask-title">My Work Update for last 24 hrs ({myInternalConsolidatedStats?.JDListInternalActivity?.length} JDs)</div>
      {myInternalConsolidatedStats?.JDListInternalActivity?.length > 0 ? (
      <>
              {myInternalConsolidatedStats?.JDListInternalActivity?.map((jd) => (
                    <TeamAssignmentCard data={jd} all={false}/>
              ))}
      </>
      ) : (
        <Empty style={{ margin: "60px" }} />
      )}
    </div>
  );
}
