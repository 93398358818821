import React, { useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { actions } from "../../actions";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Empty, Card,Carousel } from "antd";
import workflow from "../../assets/landingFeatures/workflow.png";
import hiring from "../../assets/landingFeatures/hiring.png";

import "./features.css";


function Features (props)  {
  const [loader, SetLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [error,setError] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const { Meta } = Card;
  const contentStyleQhrFlow = {
   margin:20,
   padding:"5px",
   height: "300px",
   width:"auto",
   background: "#d0ece7 "
  };   
  
  const contentStyle = {
   margin:20,
   padding:"5px",
   height: "300px",
   width:"auto",
   background: "#f5eef8"
  }; 
  
  const contentStyleJobListing = {
   margin:20,
   padding:"5px",
   height: "300px",
   width:"auto",
   background: "#aed6f1 "
  };  

  const contentStyleERL = {
   margin:20,
   padding:"5px",
   height: "300px",
   width:"auto",
   background: "#f2d7d5"
  }; 
  const onSubmit = () => {
    console.log("on submit called");
    let dataToSend = { email: email };
    let inputData = { email: email };
    console.log("email", email);
    SetLoader(true);

    dispatch(actions.SignIn(dataToSend, (error, status) => {
      if (!error) {
        navigate("/otp", { state: { inputData, type: "login" } });
        SetLoader(false);
      } else {
        // Handle the error case
        SetLoader(false);
        console.error("API Error:", error);
        let e = error.split("or")
        setError(e[0] + "signup first")
       // setErrorMessage(error); // Set the error message in state
      }
    }));
  };

  const handleSignin = () => {
    navigate('/login')
  };

  const handleSignup = () => {
    navigate('/signup')
  };

  const handleClick = () => {

 };

const settings = {
   dots: false,
   speed: 10000,
   slidesToShow: 5,
   slidesToScroll: 1,
   infinite: true,
   autoplay: true,
   autoplaySpeed: 10,
 };

 const settingsJobListing = {
   dots: false,
   speed: 10000,
   slidesToShow: 2,
   slidesToScroll: 1,
   infinite: true,
   autoplay: true,
   autoplaySpeed: 10,
 };
  return (
     <div className="features-wrapper">
        <div className="feature-heading">
           Products and Features
        </div>
        <div className="feature-subheading">
           QHRFlow 
        </div>
        
        <div className="feature-carousil-wrapper">
           <div className="container">
                 <Slider {...settings}>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Work Flow" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Software solution designed to automate and optimize recruitment processes.
                         </div>                      
                         
                     </Card>
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Task Management" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Manage Teams productivity and tasks
                         </div>                                               
                     </Card>                     
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Improved ATS" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              AI Enabled Application tracking System
                         </div>                                               
                     </Card>                      
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Bulk Communications" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Communicate to Multiple Candidates at once.
                         </div>                                               
                     </Card>                      
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Bulk Forms" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Send Any Forms to Multiple Candidates at once.
                         </div>                                               
                     </Card>                      
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Bulk Evaluations" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Evaluate Multiple Candidates at once.
                         </div>                                               
                     </Card>                      
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Interview Management" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Interview Scheduling and Reminders
                         </div>                                               
                     </Card>                      
                     </div>                                            
                 </Slider>
           </div>
        </div>

        <div className="feature-subheading" style = {{color:"#af0df4"}}>
           QHRFlow Vendor Connect
        </div>
        
        <div className="feature-carousil-wrapper">
           <div className="container">
                 <Slider {...settings}>
                     <div>
                     <Card style ={contentStyle} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Vendor Management" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Software solution designed to automate and optimize recruitment processes.
                         </div>                      
                         
                     </Card>
                     </div>
                     <div>
                     <Card style ={contentStyle} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Resume Quality Analysis" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Manage Teams productivity and tasks
                         </div>                                               
                     </Card>                     
                     </div>
                     <div>
                     <Card style ={contentStyle} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Improved ATS" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              AI Enabled Application tracking System
                         </div>                                               
                     </Card>                      
                     </div>
                     <div>
                     <Card style ={contentStyle} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Bulk Communications" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Communicate to Multiple Candidates at once.
                         </div>                                               
                     </Card>                      
                     </div>                    
                 </Slider>
           </div>
        </div>

        <div className="feature-subheading" style = {{color:"#3498db"}}>
           QuallyHire JoB Portal
        </div>
        
        <div className="feature-carousil-wrapper">
           <div className="container">
                 <Slider {...settingsJobListing}>
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Employer Career Pages" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                             Maintain and Update your website carrer pages easily
                         </div>                      
                         
                     </Card>
                     </div>
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="QuallyHire Job Search Platform" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Post and Search Jobs
                         </div>                                               
                     </Card>                     
                     </div>                  
                 </Slider>
           </div>
        </div>

        <div className="feature-subheading" style = {{color:"#808b96"}}>
           QuallyHire Employee Reigster and Leave management  (ERL)
        </div>
        
        <div className="feature-carousil-wrapper">
           <div className="container">
                 <Slider {...settingsJobListing}>
                     <div>
                     <Card style ={contentStyleERL} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Employee Time Sheet" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                             Maintain and Update your website carrer pages easily
                         </div>                      
                         
                     </Card>
                     </div>
                     <div>
                     <Card style ={contentStyleERL} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Leaves Management" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Post and Search Jobs
                         </div>                                               
                     </Card>                     
                     </div>                  
                 </Slider>
           </div>
        </div>

        <div className="feature-img-wrapper"> 
            <div>
                <img
                        src={hiring}
                        alt="hiring"
                        width="400" height="500" 
                /> 
            </div>
            <div className="feature-card">
                 Quality Solutions for Hiring Best Employees
            </div>

                   
        </div>

        <div className="feature-endHeading-wrapper">
            <div className="feature-endHeading">
                Experience the <span style={{background:"#f4d03f", padding:"10px",fontWeight:"300"}}> difference</span> 
                <br> 
                </br>
                    Try <span style={{background:"#f4d03f", padding:"10px",fontWeight:"500"}}> Quallyhire AI Product Suits</span>
            </div>

            <div className="feature-Button-wrapper">
                <Button type="primary" shape="round" size="large">
                    Talk to Sales 
                </Button>
            </div> 

        </div>
                                                                              
     </div>
  );
};

export default Features;
