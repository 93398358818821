import React, { useState,useRef } from "react";
import { Empty, Input, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";


import "./teamAssignmentCardStatsView.scss";

export default function TeamAssignmentCardStatsView({
    data,
    type,
    analyticsAction
}) {
    const COLOR_WHITE = '#ffffff'
    const COLOR_GREY = '#a3a0a0'
    const navigate = useNavigate();
    const [backGColor, setBackGroudColor] = useState('#a3a0a0');


    const inputRef = useRef(null);
    console.log("Team Insternal Stats View",[...data])

    const focusInputField = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const handleJDClick = (id) => {
        console.log("resume byy asdsa",id)
        navigate(`/jds/${id}`);
    };
   const handleResumeStatsClick = (id)=>{
    
    navigate(id)
   }   

    return (
    <div className= "teamAssignmentCardStatsView-wrapper">  
      <div className="teamAssignmentCardStatsView-title-wrapper">
          <div className="teamAssignmentCardStatsView-title-heading">
             {type}
          </div>
          <div className="teamAssignmentCardStatsView-title-action">
             {analyticsAction}
          </div>  
      </div>  
      {
        [...data]?.map((entry)=> {
            return(
                <div className="teamAssignmentCardStatsView-Values-wrapper">
                    <div className="teamAssignmentCard-Stats-subheading-wrapper">
                    {entry[0]}
                    </div>
                    <div className="teamAssignmentCard-Stats-subheading-value">
                    {entry[1] != 0  ? 
                             entry[1]:"-"
                     }
                    </div>                   
                </div>
            )
        }) 
      }                        
    </div>
    );
}
