import React, { useState, useEffect } from "react";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import CustomerTable from "../customerTable";
import CustomerAddModal from "../customerAddModal";

import useMembersStore from "../../store/members.store";
import useClientStore from "../../store/clientModal.store";
import NewRTableSelectable from "../NewRTableSelectable";

import "./customers.scss";

const allcustomerTitles = [
  "Select",
  "S. No.",
  "Customer Name",
  "Company Type",
  "HQ Location",
  "Description",
  "TAs",
  "Status",
  "Date Added",
];


function Customers() {
    const [currentView, setCurrentView] = useState("active");

    const { getClients,clients} =  useMembersStore();

    const navigate = useNavigate();

    const { setModalOpen, setCurrentClientName,setCurrentClientTAName, removeClient } =
        useClientStore();

    useEffect(() => {
        getClients();
    }, []);

  const viewTAs = (name, id) => {
    // navigate(`/specificCustomer/${name}/${id}`);
    navigate(`/customers/${name}`, { state: { clientID: id } });
  };

    const clientList =
         clients && clients.length > 0
            ? clients.map((client, idx) => ({
          select: "",
                  id: idx + 1,
                  name: client?.ClientName,
                  type: client?.ClientType,
                  location: client?.ClientLocation,
                  description: client?.ClientDescription,                  
                  talist:(
                    <div
                        style={{
                            cursor: "pointer",
                            color: "blue",
                            fontWeight: "600",
                        }}
                        onClick={() => viewTAs(client?.ClientName,idx)}
                    >
                        View TA
                    </div>
                  ),
                  state: client?.ClientState,
                  date : client?.ClientAdditionDate,                  
          // remove: (
          //   <div
          //     style={{
          //       cursor: "pointer",
          //       color: "blue",
          //       fontWeight: "600",
          //     }}
          //     onClick={() => removeClient(client?.ClientName)}
          //   >
          //     Remove
          //   </div>
          // ),
        }))
      : [];

    const handleAddTeamClick = () => {
        setModalOpen();
    };

    return (
        <div className="customers-container">
            <div className="customers-btns-wrapper">
                <Button
                    onClick={() => setCurrentView("active")}
                    className={`customer-btn ${
                        currentView === "active" && "customer-btn-active"
                    }`}
                >
                    Active List
                </Button>
                <Button
                    onClick={() => setCurrentView("all")}
                    className={`customer-btn ${
                        currentView === "all" && "customer-btn-active"
                    }`}
                >
                    All List
                </Button>
            </div>
            {currentView === "active" ? (
                <div className="customer-members-wrapper">
                    <div style={{display:"flex"}}>
                    <h2 className="customer-members-list-title">List of Active Cutomers</h2>
                    <div className="customer-members-add-wrapper">
                        <Button
                            onClick={handleAddTeamClick}
                            type="primary"
                            className="customer-members-add-btn"
                        >
                            + Add new Customer
                        </Button>
                    </div>
                    </div>
                    {clients?.length > 0 ? (
            <NewRTableSelectable
                            titleList={allcustomerTitles}
                            data={clientList}
                        />
                    ) : (
                        <Empty style={{ margin: "60px" }} />
                    )}

                  
                </div>
            ) : (
                <div className="customer-members-wrapper">
                    <div style={{display:"flex"}}>
                    <h2 className="customer-members-list-title">List of All Cutomers</h2>
                    <div className="customer-members-add-wrapper">
                        <Button
                            onClick={handleAddTeamClick}
                            type="primary"
                            className="customer-members-add-btn"
                        >
                            + Add New Customer
                        </Button>
                    </div>
                    </div>
                    {clients?.length > 0 ? (
                        <CustomerTable 
                           titleList={allcustomerTitles} 
                           data={clientList} 
                        />
                    ) : (
                        <Empty style={{ margin: "60px" }} />
                    )}

                  
                </div>
            )}
            <CustomerAddModal />
        </div>
    );
}

export default Customers;
