import React, { useEffect } from "react";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";

import useStatsStore from "../../store/stats.store";

import "./teamTask.scss";
import TeamAssignmentCard from "../TeamAssignmentCard";

export default function TeamTaskUpdate(props) {
  console.log(props);


  const status = localStorage.getItem("status");

  const { teamsInternalConsolidatedStats } = useStatsStore();

  useEffect(() => {

  }, []);

  return (
    <div className="teamTask-wrapper">
      <div className="teamTask-title">Teams Work Update for last 24 hrs</div>
      {teamsInternalConsolidatedStats?.JDListInternalActivity?.length > 0 ? (
      <>
              {teamsInternalConsolidatedStats?.JDListInternalActivity?.map((jd) => (
                    <TeamAssignmentCard data={jd}/>
              ))}
      </>
      ) : (
        <Empty style={{ margin: "60px" }} />
      )}
    </div>
  );
}
