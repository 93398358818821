import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import home from "../../assets/home.png";
import "./breadcrumbs.css";

function Breadcrumbs() {
  const location = useLocation();
  let currentLink = "";
  // Split and filter the path once to get the crumbs array
  const crumbsArray = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "");

  const crumbs = crumbsArray.map((crumb, index) => {
    currentLink += `/${crumb}`;

    return crumb !== "dashboard" ? (
      <div className="crumb" key={crumb}>
        <span>/</span>
        {crumbsArray.length - 1 !== index ? ( // Use crumbsArray.length here
          <NavLink
            className="breadCrumbData"
            to={currentLink}
            activeClassName="activeLink" // Add a class for the active link
          >
            <span>{crumb}</span>
          </NavLink>
        ) : (
          <span>{crumb}</span>
        )}
      </div>
    ) : null;
  });

  return (
    <div className="breadcrumbs">
      <NavLink to={"/"} activeClassName="activeLink">
        <img  className="homeimg" src={home} alt="Home" />
      </NavLink>
      {crumbs}
    </div>
  );
}

export default Breadcrumbs;
