import axios from "../helper/axios";
// import Cookies from "js-cookie";

export function Signup(data, callback) {
  console.log("Signup", data);
  return async (dispatch) => {
    try {
      const response = await axios.post("/users", data);
      console.log("/users response:", response.data);
      // Dispatch({ type: "SIGNUP", payload: response.data });
      callback(null, "success"); // Pass null or undefined in the success case
    } catch (error) {
      console.error("Signup error:", error);
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Server error:", error.response.data.error);
        localStorage.setItem("errormessage", error.response.data.error);
        callback(error.response.data.error, "error");
      } else {
        console.error("Unknown error occurred");
        localStorage.setItem("errormessage", "Unknown error occurred");
        callback("Unknown error occurred", "error");
      }
    }
  };
}

export function Verify_acoount_otp(data,callback) {
  console.log("/users/verifyaccount",data)
  return async (Dispatch) => {
    axios
      .post("/users/verifyaccount", data)
      .then((res) => {
        console.log(res);
        Dispatch({ type: "SIGNUP", payload: res });
        localStorage.setItem("userDetails",JSON.stringify(res.data.user))
        Dispatch({ type: "SIGNIN", payload: res.data.user });
       callback()
      })
      .catch((e) => console.log(e));
  };
}
export function Subusersignuop(data,owner_id,role, callback) {
  console.log("Subusersignuop",data)
  console.log("ownerid",owner_id);
  console.log("role",role);
  return async (Dispatch) => {
    axios
      .post(`/users/${owner_id}/${role}`, data)
      .then((res) => {
        console.log(res);
       // Dispatch({ type: "SIGNUP", payload: res });
        callback();
      })
      .catch((e) => console.log(e));
  };
}

// export function SignIn(data, callback) {
//   return async (Dispatch) => {
//     console.log(data);
//     axios
//       .post("users/getotp", data)
//       .then((res) => {
//         console.log("/users/getotp", res);
//         callback();
//       })
//       .catch((e) => console.log(e));
//   };
// }

export function SignIn(data, callback) {
  return async (dispatch) => {
    try {
      console.log(data);
      const response = await axios.post("users/getotp", data);
      console.log("/users/getotp", response.data);
      callback(null, "success"); // Pass null or undefined in the success case
    } catch (error) {
      console.error(error);
      console.error(error.response.data.error);
      localStorage.setItem("errormessage", error.response.data.error);
      callback(error.response.data.error, "error");
    }
  };
}

export function Verify_signin_otp(data,callback) {
  console.log("/users/verifyotp",data)
  return async (Dispatch) => {
    axios
      .post("/users/verifyotp", data)
      .then((res) => {
        console.log(res);
        localStorage.setItem("userDetails",JSON.stringify(res.data.user))
        Dispatch({ type: "SIGNIN", payload: res.data.user });
        callback()
       
      })
      .catch((e) => console.log(e));
  };
}

export function Authorization(callback) {
  console.log("Authourzation called");
  return async (Dispatch) => {
    axios
      .post("/refresh")
      .then((res) => {
        console.log("Authorization", res);
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("token")
        Dispatch({ type: "SIGNIN", payload: res.data });
         callback()
      })
      .catch((e) => console.log(e));
  };
}

export function Getsubsuser(owner_id,role ) {
  return async (Dispatch) => {
    console.log("getsubsuser");
    axios
      .get(`/users/${owner_id}/role/${role}`)
      .then((res) => {
        console.log(res)
        if(role === "vendor_connect"){
          Dispatch({ type: "VENDOR_CONNECT", payload: res.data });

        }else{
          Dispatch({ type: "EMPLOYEE", payload: res.data });

        }
      })
      .catch((e) => console.log(e));
  };
}
export function Forgetpassword(data) {
  return async (Dispatch) => {
    console.log(data);
    axios
      .post("/forgotpassword", data)
      .then((res) => {
        Dispatch({ type: "RESET_PASSWORD", payload: res.data });
      })
      .catch((e) => console.log(e));
  };
}
export function Logout(callback) {
  console.log("log out called")
  return async (Dispatch) => {
    axios
      .get("/logout")  
      .then((res) => {
        console.log("log out called after")

       localStorage.removeItem("token")
        Dispatch({ type: "RESET_PASSWORD", payload: res.data });
        callback()
      })
      .catch((e) => console.log(e));
  };
}
