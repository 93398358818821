import React, { useEffect,useState } from "react";
import { Card ,Empty} from "antd";
import { useNavigate } from "react-router-dom";

import useStatsStore from "../../store/stats.store";
import useMembersStore from "../../store/members.store";
import InterviewCardTable from "../InterviewCardTable";

import "./interviewDayScheduleCard.scss";

export default function InterviewDayScheduleCard( 
      {role,jd, sDate}
     ) 
{
  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const { interviewSchedules,getInterviewSchedule } = useStatsStore();
  const { getClients,clients,teamMembers} =  useMembersStore();

  const personalListTable = [
    "Time",
    "Name",
    "Email Id",
    "Mobile",
    "QHRID",
];

  const panelListTable = [
    "Time",
    "Candidate Name",
    "Panel Group",
  ];

  const panelGroupTable = [
    "Panel Group",
    "Interviewer Name",
    "Interviewer Email Id",
    "Interviewer Mobile",
  ];

  const contactListTable = [
    "Company",
    "HR Name",
    "Email Id",
    "Mobile",
  ];

  function getInterviewListForSelectedDate () {
    const filteredData = jd?.InterviewItem?.filter((item, idx) => {
           return (sDate == item.interviewdate) ;
    });
    const personalDetailsData = filteredData.map((item, idx) => {
      return {
        time: item?.interviewtime,
        name: item?.name,
        email: item?.email,
        mobile: item?.mobile,
        qhrid: item?.qhrid
      };
    });
    return personalDetailsData
  }

  function getPanelData () {
    const filteredData = jd?.InterviewItem?.filter((item, idx) => {
      return (sDate == item.interviewdate) ;
    });
    const personalDetailsData = filteredData.map((item, idx) => {
      return {
        time: item?.interviewtime,
        name: item?.name,
        group: "Panel 1",
      };
    });
    return personalDetailsData
  }

  function getPanelGroupData () {
    const filteredData = jd?.InterviewItem?.filter((item, idx) => {
      return (sDate == item.interviewdate) ;
    });
    const personalDetailsData = filteredData.map((item, idx) => {
      return {
        group: "Panel 1",
        name: item?.name,
        email: item?.email,
        mobile: item?.mobile,
      };
    });
    return personalDetailsData
  }

  function getAssignedTo(){
    for (var i=0; i < teamMembers?.length; i++) {
      if(teamMembers[i]?.username == jd?.assignedto){
        const data = [
          {
            company:teamMembers[i]?.organization,
            name: teamMembers[i]?.username,
            email: teamMembers[i]?.email,
            mobile: teamMembers[i]?.contact,
          }
        ]
        return data;
      }
    }
  }
  
  function getTADetails(){
    for (var i=0; i < clients?.length; i++) {
       if(clients[i]?.ClientName == jd?.client){
         for (var j=0; j < clients[i]?.ClientTAList?.length; j++) {
            if (clients[i]?.ClientTAList[j]?.TAName == jd?.ta){
                var data =[
                    {
                     company:jd?.client,
                     name: jd?.ta,
                     email: clients[i]?.ClientTAList[j]?.TAMail,
                     number: clients[i]?.ClientTAList[j]?.TANumber,
                    }
                ]
                return data;
            }
         }
       } 
    }
  }  

  useEffect(() => {    
    //getInterviewSchedule();
  }, [status]);
  
  return (
    <div className="interviewDaySchedule-wrapper">
      { role === "candidates" ? (
                <InterviewCardTable
                titleList={personalListTable}
                data={getInterviewListForSelectedDate()}
            />
        ): role === "panel" ?( 
                <>
                    <InterviewCardTable
                    titleList={panelListTable}
                    data={getPanelData()}
                    />
                    <InterviewCardTable
                    titleList={panelGroupTable}
                    data={getPanelGroupData()}
                    />
                </>

        ): role === "reminders" ?( 
                <InterviewCardTable
                titleList={personalListTable}
                data={getInterviewListForSelectedDate()}
        />
        ): role === "contact" ?(
            <>

                <InterviewCardTable
                titleList={contactListTable}
                data={getAssignedTo()}
                shortTable={true}
                /> 

                <InterviewCardTable
                titleList={contactListTable}
                data={getTADetails()}
                shortTable={true}
                />                  
            </> 
       
      ):<></>
      }
    </div>
  );
}
