import React, { useEffect } from "react";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";

import useJDStore from "../../store/jd.store";
import { useLocation } from "react-router-dom";

import SelectionTable from "../SelectionTable";

import "./teamSelection.scss";

export default function TeamSelection(props) {
  console.log(props);

  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const { getAllJds, jdData } = useJDStore();

  useEffect(() => {
    getAllJds();
  }, [status]);

  const titleList = [
    "Sr. No.",
    "Assigned On",
    "Job ID", 
    "Priority",   
    "Client",
    "TA",
    "Job Title",
    "SPOC",
    "Assigned To",
    "Num of Selection",
    "Num of Joinings",
    "Num of Not Joined",
  ];

  
  const GetTeamAssignment =(jds)=>{
    var assignedTeam=''
    jds?.JDAssignment?.Assignments?.map((assign,idx)=>{
      if (assignedTeam === ''){
        assignedTeam = assign.AssignedToName;
      }else{
        assignedTeam = assignedTeam +','+assign.AssignedToName;
      }
      
     return })

     return assignedTeam
  }

  const jdsDetailData = jdData?.map((jds, idx) => {
    return {
      id: idx + 1,
      date_added: jds?.JDOther.PostingDate,  
      jobId: jds?.QhCompanyJID,
      priority: jds?.JDOther.Priority,
      client_name: jds?.JDClient.ClientName,
      ta_name: jds?.JDClient.TaName,
      title: jds?.JDDetails.Title,
      SPOC: jds?.JDAssignment?.Spoc,
      assigned_to: GetTeamAssignment(jds),     
      final_select: jds?.final_select,
      matching_resume: jds?.matching_resume,
      hiring_project_team_name: jds?.hiring_project_team_name,
    };
  });

  return (
    <div className="jds-wrapper">
      {jdData?.length > 0 ? (
        <SelectionTable
          isClickable={true}
          isJDPage={true}
          titleList={titleList}
          data={jdsDetailData}
        />
      ) : (
        <Empty style={{ margin: "60px" }} />
      )}
    </div>
  );
}
