import React, { useState } from "react";
import {
    Button,
    Modal,
    Form,
    Input,
    Select,
    DatePicker,
    Upload,
    message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import "./resumeAddModal.scss";

const defaultInput = {
    name: "",

};

export default function ResumeAddModal(
    {currentState,
     handler,
     minExp ,
     maxExp ,
     cRole,
     jCategory,
     jKeywords,
     jEducation,
     eJson,
     rAHandler,
     rAFileName
    }) {
    const [formInput, setFormInput] = useState(defaultInput);

    const [form] = Form.useForm();        
    
      const handleOk = () => {
        handler(false);
      };
    
      const handleCancel = () => {
        handler(false);
      };

    const baseURL = "http://localhost:4200";
    //const baseURL = "https://api.quallyhireai.com";

    const props = {
        name: "pdf",
        action: `${baseURL}/resumeEvalFree`,
        maxCount: 1,
        data: {
            exp: formInput.exp,
            minexp: minExp,
            maxexp: maxExp,
            role: cRole,
            category: jCategory,
            keywords: jKeywords,
            education: "ccc",
            ejsondata:  JSON.stringify(eJson)
        },
        beforeUpload() {
            const { exp } = formInput;
            if (exp === "") {
                message.error(`Please Enter the candidate exp`);
                return false;
            }
            console.log(eJson);
        },
        onChange(info) {
            if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === "done") {
                //now check if 
                // 1. Resume already exists for this JD by the same user ID
                // 2. Resume already exists for this JD byt other user ID
                // 3. Resume already exist for this JD
                //response: '{"status": "success"}', // response from server
                var responseData = info.file.response
                if(responseData.status == "DUPLICATE_UPLOADED_VENDOR"){
                    message.success(`${info.file.name} Duplicate, Uploaded by Another Vendor`);
                }else if(responseData.status == "DUPLICATE_UPLOADED_INTERNAL"){
                    message.success(`${info.file.name} Duplicate, Already Assigned to this JD`);
                }else{
                    message.success(`${info.file.name} Resume analysis Available`);
                    handleOk();    
                    localStorage.setItem("resumeAnalysisFreeResponse",JSON.stringify(responseData))
                    rAHandler(true)
                    rAFileName( info.file.name)
                }
                form.resetFields(); 
            } else if (info.file.status === "error") {
                message.error(`${info.file.name} file upload failed.`);
                handleOk();
                rAHandler(false)
                form.resetFields(); 
            }
        },
    };

    const handleFormInput = (event) => {
        setFormInput((inputs) => ({
            ...inputs,
            [event.target.name]: event.target.value,
        }));
    };

    return (
        <Modal
            title="Now upload the resume"
            open={currentState}
            footer={null}
            onCancel={handleCancel}
        >
            <Form
                labelCol={{ flex: "150px" }}
                form={form}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
            >
                <Form.Item
                    label="Candidate Exp"
                    name="exp"
                    rules={[
                        {
                            required: true,
                            message: "Please enter exp!",
                        },
                    ]}                    
                >
                    <Input
                        name="exp"
                        value={formInput.exp}
                        onChange={handleFormInput}                        
                        className="resumeAddModel-input"
                    />
                </Form.Item>
                                             
                <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Select Resume</Button>
                </Upload>
            </Form>
        </Modal>
    );
}
