import React, { useState } from "react";

import { actions } from "../../actions";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Cascader,Form, Select,Input } from "antd";
import logo from "../../assets/QuallyHireGreen.png";
import ResumeAddModal from "./ResumeAddModal";
import Footer from "../footer";
import ResumeAnalysisFreeResults from "./ResumeAnalysisFreeResults";

import "./resumeAnalysisFree.css";


function ResumeAnalysisFree (props)  {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAnalysisAvailable, setAnalysisAvailable] = useState(false);
  const [resumeFileName, setResumeFileName] = useState("");
  const [val, setRequestVal] = useState({});
  const [loader, SetLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [error,setError] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  

  const setModelOpen = () => {
    setIsModalOpen(true);
  };

  const itRoles = [
    { value: 'developer', label: 'Developer' },
    { value: 'tester', label: 'Tester' },
    { value: 'integration', label: 'Integrator' },
    { value: 'sdet', label: 'SDET'},
    { value: 'architect', label: 'Architect' },
    { value: 'project manager', label: 'Project Manager' }, 
    { value: 'qa', label: 'QA' },
    { value: 'scrum master', label: 'Scrum Master' },
    { value: 'validation', label: 'Validation' },
    { value: 'admin', label: 'Admin '},
    { value: 'support', label: 'Support' }        
  ];

  const embeddedRoles = [
    { value: 'developer', label: 'Developer' },
    { value: 'tester', label: 'Tester' },
    { value: 'integration', label: 'Integrator' },
    { value: 'sdet', label: 'SDET'},
    { value: 'architect', label: 'Architect' },
    { value: 'project manager', label: 'Project Manager' }, 
    { value: 'qa', label: 'QA' },
    { value: 'scrum master', label: 'Scrum Master' },
    { value: 'validation', label: 'Validation' }       
  ];

  const jobCategory = [
   { value: 'it', label: 'IT and Software',children: itRoles},
   { value: 'vlsi', label: 'Hardware and VLSI' },
   { value: 'wireless', label: 'Wireless' },
   { value: 'embedded', label: 'Embedded',children: embeddedRoles},
   { value: 'teaching', label: 'Teaching' },
   { value: 'BFSI', label: 'BFSI' },
   { value: 'mechanical', label: 'Mechanical' },
   { value: 'research', label: 'Research'}, 
   { value: 'purchasing', label: 'Purchasing ' },
   { value: 'marketing', label: 'Sales and Marketing' },
   { value: 'production', label: 'Production' },
   { value: 'medical', label: 'Medical '},           
 ];

 const bStream = [
  { value: 'any',label:'Any'},
  { value: 'it', label: 'IT'},
  { value: 'computers', label: 'Computers' },
  { value: 'electronics', label: 'Electronics' },
  { value: 'electrical', label: 'Electrical'},
  { value: 'civil', label: 'Civil' },
  { value: 'mechanical', label: 'Mechanical' }, 
  { value: 'metallurgy', label: 'Metallurgy' }      
];

 const bDegrees = [
  { value: 'btech', label: 'BE/BTech',children: bStream},
  { value: 'bca', label: 'BCA' },
  { value: 'bcom', label: 'BCom'},
  { value: 'bpharma', label: 'Bpharma' },
  { value: 'bsc', label: 'BSc' }, 
  { value: 'bs', label: 'BS' },
  { value: 'any', label: 'Any' },      
];

const mDegrees = [
  { value: 'mtech', label: 'BE/BTech',children: bStream},
  { value: 'mca', label: 'MCA' },
  { value: 'mcom', label: 'MCom'},
  { value: 'mpharma', label: 'Mpharma' },
  { value: 'msc', label: 'MSc' }, 
  { value: 'ms', label: 'MS' },
  { value: 'any', label: 'Any' }       
];

const medicalDegrees = [
  { value: 'mbbs', label: 'MBBS',children: bStream},
  { value: 'md', label: 'MS/MD' },
  { value: 'any', label: 'Any'}     
];

const businessDegrees = [
  { value: 'mba', label: 'MBA',children: bStream},
  { value: 'bba', label: 'BBA' },
  { value: 'any', label: 'Any'}     
];
 const educationList = [
  { value: 'Bachelors', label: 'Bachelors',children: bDegrees},
  { value: 'Masters', label: 'Masters', children: mDegrees },
  { value: 'Medical', label: 'Medical', children: medicalDegrees },
  { value: 'Business', label: 'Business', children: businessDegrees},
  { value: 'Diploma', label: 'Diploma' },
  { value: 'Phd', label: 'Phd' },           
];

const onChange = (value) => {
  console.log(value);
};

 const handleSignin = () => {
    navigate('/login')
  };


 const handleJobSeekers = () => {
   navigate('/jobSeekers')
 };


 const handleUpdateFormSubmit = (values) => {
  setRequestVal(values)
  console.log( "form submit",values)
  setModelOpen()
 };

 const handleChange =()=>{

 }

 const encodeJson = () =>{
  let encodedJsonValues ={};
  encodedJsonValues.minexp = val.minexp
  encodedJsonValues.maxexp = val.maxexp
  encodedJsonValues.crole = val.category?.[1]
  encodedJsonValues.jcategory =val.category?.[0]
  encodedJsonValues.jkeywords = val.keywords
  encodedJsonValues.jeducation = val.education
  return encodedJsonValues
 }

 return (
   <div className="resumeAnalysis-wrapper">
    {
      console.log( "form submit")
    }
   <div className="resumeAnalysis-header"> 
      <div className="header-logo">
          <img className="header-logo-img"src={logo} />
          <div className="header-logo-name">
              Quallyhire AI
          </div>
      </div>
      <div className="header-menu">
        <ul className="header-menu">
          <li >
            <a href="/">Home</a>
          </li>
          <li>
            Resume Analysis
          </li>
          <li>
            Support 
          </li>                                          
        </ul>
      </div>
      <div className="header-sign">
        <Button className="sign-button" onClick={() => handleSignin()}>
          Post 5 Free Jobs
        </Button>
        <Button className="job-button" onClick={() => handleJobSeekers()}>
          Employers
        </Button>                                     
      </div>          
   </div>

   <div className="jobListing-title">
     <div className="jobListing-punchLine">
        Resume Analysis
     </div>
     <div className="jobListing-puncLineSmall">
        Let us do the Resume Analysis with your JD Keywords.
     </div>
     <div className="resumeAnalysis-search-wrapper">
         <div className="resumeAnalysis-heading">
            Enter JD Details Below
         </div>
        <Form
            className="resumeAnalysis-search-wrapper"
            name="resumeAnalysis-search"
            autoComplete="off"
            onFinish={handleUpdateFormSubmit}
        >
            <div className="resumeAnalysis-search">
                <Form.Item
                    name="category"
                    rules={[
                      {
                          required: true,
                          message: "Please enter category!",
                      },
                  ]} 
                >  
                <Cascader 
                     options={jobCategory} 
                     onChange={onChange} 
                     placeholder="Category"
                     required={true}
                     className="resumeAnalysis-search-item"
                />                     
                </Form.Item>     
                <Form.Item
                    name="education"
                    rules={[
                      {
                          required: true,
                          message: "Please enter education!",
                      },
                  ]} 
                >
                <Cascader 
                     options={educationList} 
                     onChange={onChange} 
                     placeholder="Education"
                     multiple
                     required={true}
                     className="resumeAnalysis-search-item"
                 />
                </Form.Item>                                            
                <Form.Item
                    name="minexp"
                    rules={[
                      {
                          required: true,
                          message: "Please enter minexp!",
                      },
                  ]} 
                >
                    <Input
                        className="resumeAnalysis-search-item"
                        type="text"
                        required={ true}
                        placeholder={"Min Exp"}
                    />
                </Form.Item>
                <Form.Item
                    name="maxexp"
                    rules={[
                      {
                          required: true,
                          message: "Please enter maxexp!",
                      },
                  ]} 
                >
                    <Input
                        className="resumeAnalysis-search-item"
                        type="text"
                        required={ true}
                        placeholder={"Max Exp"}
                    />
                </Form.Item>  
             </div>
             <div className="resumeAnalysis-search-item-keywords">  
                <Form.Item
                    name="keywords"
                    rules={[
                      {
                          required: true,
                          message: "Please enter keywords!",
                      },
                  ]} 
                >
                    <Input
                        className="resumeAnalysis-search-item-keywords"
                        type="text"
                        required= {true}
                        placeholder={"Keywords"}
                    />
                </Form.Item>                                            
            </div>
            <div className="resumeAnalysis-search-button">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="resumeAnalysis-search-button"
                  >
                      Submit
                  </Button> 
          </div>                              
        </Form>
     </div> 
     {
      isModalOpen? <ResumeAddModal
                      currentState={isModalOpen} 
                      handler={setIsModalOpen} 
                      minExp ={val.minexp}
                      maxExp ={val.maxexp}
                      cRole ={val.category?.[1]}
                      jCategory ={val.category?.[0]}
                      jKeywords = {val.keywords}
                      jEducation = {val.education}
                      eJson = {encodeJson()}
                      rAHandler = {setAnalysisAvailable}
                      rAFileName = {setResumeFileName}
                  />:<></>
     } 
     {
      isAnalysisAvailable? <ResumeAnalysisFreeResults currentState={isAnalysisAvailable}fileName={resumeFileName} />:<></>
     }      
    
   </div>

   <div className="landing-footer">
      <Footer/>
   </div>         
</div>
  );
};

export default ResumeAnalysisFree;
