import React, { useState,useRef ,useEffect} from "react";
import { Empty, Button,Checkbox,Select,TimePicker ,DatePicker} from "antd";
import { useNavigate } from "react-router-dom";

import "./interviewTableSelectable.scss";
import useResumeStore from "../../store/resume.store";
import dayjs from 'dayjs';
const format = 'HH:mm';

export default function InterviewTableSelectable({
    isClickable = false,
    titleList,
    data,
    isResumePage = false,
    isEditField = false,
    editFieldHandler,
    setCurrentView,
    resumeProfileHandler,
    qhjid,

}) {
    const navigate = useNavigate();

    const checkBoxArray=data?.map((ele)=>{
        return {
            type:"",
            selected: false,
            interviewAction:ele?.iAction,
            interviewType:ele?.iType,
            interviewStatus:ele?.iStatus,
            interviewDate:ele?.iDate,
            interviewTime:ele?.iTime,           
            status: ""
         }
    })
    const [updateAvailable, setUpdateAvailable] = useState(false);
    const [checkBoxData, setCheckBoxData] = useState(checkBoxArray);
    const { updateCandidateStatus } =
    useResumeStore();

    const handleResumeDetailClick = (qhID) => {
        console.log("here is the resume ID", qhID)
        resumeProfileHandler(qhID)
        setCurrentView("profile")
    };

    const ResetCheckBox = () => {
        var checkBoxResetArray=data?.map((ele)=>{
            return {
                type:"",
                selected: false,
                interviewAction:ele?.iAction,
                interviewType:ele?.iType,
                interviewStatus:ele?.iStatus,
                interviewDate:ele?.iDate,
                interviewTime:ele?.iTime,            
                status: ""  }
        })
        setCheckBoxData(()=>([...checkBoxResetArray]))
    };
    useEffect(() => {
        console.log("Selected JD and Data", qhjid,data)   
        setCheckBoxData(checkBoxArray)
    }, []);

    if (!data) {
        return <Empty style={{ margin: "60px" }} />;
    }

    function enableDbUpdate (){
        for(let idx = 0; idx < checkBoxData.length; idx++){
            console.log("enableDbUpdate checkBoxArray", idx,checkBoxData[idx].selected)      
            if (checkBoxData[idx].selected ) {
                return true
            } 
        }
        return false
    }
    const onCheckBoxChange = (e) => {
        if (e.target.checked) {
            checkBoxData[e.target.value].selected = true; 
            console.log("Setting checkBoxData", e.target.value,true,checkBoxData)      
        }else{
            checkBoxData[e.target.value].selected = false; 
            console.log("Setting checkBoxData", e.target.value,false) 
        }
        if (enableDbUpdate() == true){
            setUpdateAvailable(true)

        }else{
            setUpdateAvailable(false)
        } 
        setCheckBoxData(()=>([...checkBoxData]))
      }

    const OnSelectChangeInterviewType = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var interviewType = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].interviewType = interviewType
        checkBoxData[idxInt].type = "INTERVIEW_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}
    }

    const OnSelectChangeInterviewAction = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var action = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].interviewAction = action
        checkBoxData[idxInt].type = "INTERVIEW_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}
    }
    const OnSelectChangeInterviewStatus = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var status = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].status = status
        checkBoxData[idxInt].type = "INTERVIEW_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}
    }
    const handleInterviewTime = (rowIndex,time, timeString) =>{
        console.log("InterviewTime", rowIndex,time,timeString)
        checkBoxData[rowIndex].interviewTime = timeString
        checkBoxData[rowIndex].type = "INTERVIEW_STATUS"
    }

    const handleInterviewDate = (rowIndex,Value) =>{
        //console.log("InterviewDate B", rowIndex,Value)
        var day = Value?.$D
        var month = Value?.$M +1
        if(Value?.$D < 10) day = '0'+ Value?.$D
        if(month < 10) month = '0'+ month
        checkBoxData[rowIndex].interviewDate = ''+day +"-"+ month+"-" + Value?.$y
        checkBoxData[rowIndex].type = "INTERVIEW_STATUS"
        //console.log("InterviewDate A", checkBoxData[rowIndex].interviewDate)
    }
    function getTimeNow(){
        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today. getDate();
        const currentDate = date + "-" + month + "-" + year;
        return currentDate
    }
    const encodeJson = (values) =>{

        let encodedJsonValues ={};
        var updateType=""

        //get company details
        encodedJsonValues._id="Not used"
        encodedJsonValues.qhglobaljid="Not USed"
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        encodedJsonValues.qhoid= userData.ID;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhjid= qhjid;

        //now fill the parameters
        encodedJsonValues.interviewlist =[];

        var num=0
        for(let idx = 0; idx < checkBoxData.length; idx++){
            console.log("checkBoxData", idx,checkBoxData[idx].selected)
            if (checkBoxData[idx].selected && checkBoxData[idx].type =="INTERVIEW_STATUS" ) {
                encodedJsonValues.interviewlist[num]={};
                encodedJsonValues.interviewlist[num].qhrid =data[idx].qhid;
                encodedJsonValues.interviewlist[num].status =checkBoxData[idx].interviewStatus;
                encodedJsonValues.interviewlist[num].type =checkBoxData[idx].interviewType;
                encodedJsonValues.interviewlist[num].action =checkBoxData[idx].interviewAction;
                encodedJsonValues.interviewlist[num].interviewdate =checkBoxData[idx].interviewDate;
                encodedJsonValues.interviewlist[num].interviewtime =checkBoxData[idx].interviewTime;
                num++;
                updateType = "INTERVIEW_STATUS" 
            } 
        }        
        //get the current date
        let newDate = getTimeNow()
        encodedJsonValues.updatetype = updateType
        encodedJsonValues.updatedby= userData.qhuid;
        encodedJsonValues.updatedbyname= userData.username;
        encodedJsonValues.updatedon    = newDate;
             
        return encodedJsonValues
    }
    const getUpdated=(rowIndex)=>{
        return checkBoxData[rowIndex]?.selected 
    }
    const handleUpdates = (value) => {
        var jasonValues= encodeJson()
        console.log("Encoded data", jasonValues)
        updateCandidateStatus({
            jasonValues,qhjid
        });
        ResetCheckBox();
    }

    return (
        <div className="rtable-selectable-wrapper">
            <div className="rtable-selectable-btn-wrapper"  >
                <Button
                    className="rtable-selectable-btn"
                    disabled={!updateAvailable}
                    onClick={handleUpdates}
                >
                  Update Status
                </Button>
                <Button
                    className="rtable-selectable-btn"
                    disabled={!updateAvailable}
                    onClick={handleUpdates}
                >
                  Remove Resume
                </Button> 
            </div>
            <div
                className="rtable-title-wrapper"
                style={{
                    gridTemplateColumns: `50px 50px 1fr 1.5fr 1fr 1.5fr 2fr 2fr 2fr 2fr 1fr 1fr`
                }}
            >
                {titleList.map((title) => (
                    <div className="rtable-title">{title}
                    </div>
                ))}
            </div>
            {data?.map((table, rowIndex) => (
                <div
                    key={table.id}
                    className="rtable-info-wrapper"
                    style={{
                        gridTemplateColumns: `50px 50px 1fr 1.5fr 1fr 1.5fr 2fr 2fr 2fr 2fr 1fr 1fr`,
                        cursor: isClickable && "pointer",
                    }}
                >
                    {Object.keys(table)?.map((tableInfo, idx) => (
                        <div className="rtable-info">
                            {isEditField ? (
                                <div className="edit-field-wrapper">
                                        <>
                                            {tableInfo === "id"
                                                ? rowIndex + 1
                                                : <></>}
                                                
                                            {(tableInfo != "id") &&(tableInfo != "select")&&
                                             (tableInfo != "iAction")&&(tableInfo != "iType")&&
                                             (tableInfo != "iStatus")&&(tableInfo != "iTime")&&(tableInfo != "iDate")
                                                ? table[tableInfo]
                                                : <></>}

                                             { tableInfo === "select" ?
                                                <Checkbox value = {rowIndex} onChange={onCheckBoxChange}></Checkbox>
                                               : <></>
                                             }  
                                             { tableInfo === "iType" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?
                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChangeInterviewType}
                                                            placeholder="Select"  
                                                            options={[
                                                                { value: rowIndex + '#' +'L0', label: 'L0' },
                                                                { value: rowIndex + '#' +'L1', label: 'L1' },
                                                                { value: rowIndex + '#' +'L2', label: 'L2' },
                                                                { value: rowIndex + '#' +'Customer', label: 'Customer' },
                                                                { value: rowIndex + '#' +'HR', label: 'HR'},
                                                                { value: rowIndex + '#' +'MgMT', label: 'MgMT' },
                                                            ]}
                                                        /> :
                                                        table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             }  
                                             { tableInfo === "iAction" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?
                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChangeInterviewAction}
                                                            placeholder="Select"                                                       
                                                            options={[
                                                                { value: rowIndex + '#' +'To Schedule', label: 'To Be Scheduled' },
                                                                { value: rowIndex + '#' +'Scheduled', label: 'Scheduled' },
                                                                { value: rowIndex + '#' +'To Reschedule', label: 'To Be Rescheduled' },                                                                
                                                                { value: rowIndex + '#' +'Rescheduled', label: 'Rescheduled' },
                                                                { value: rowIndex + '#' +'Cancelled', label: 'Cancelled' },
                                                                { value: rowIndex + '#' +'Done', label: 'Interview Done' },
                                                            ]}
                                                        /> :
                                                        table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             }  
                                             { tableInfo === "iTime" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?
                                                        <TimePicker  placeholder="Select Time"
                                                                     use12Hours={true}
                                                                     format={"h:mm a"}
                                                                     style={{ width: 130,height: 24 }}
                                                                     onChange={(time,timeString)=>handleInterviewTime(rowIndex,time,timeString)}
                                                        />:
                                                        table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             }
                                             { tableInfo === "iDate" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?
                                                        <DatePicker 
                                                                style={{ width: 130,height: 24 }}
                                                                onChange={(value)=>handleInterviewDate(rowIndex,value)}
                                                        />:
                                                        table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             } 
                                             { tableInfo === "iStatus" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?
                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChangeInterviewStatus}
                                                            placeholder="NA"                                                         
                                                            options={[
                                                                { value: rowIndex + '#' +'NA', label: 'NA' },
                                                                { value: rowIndex + '#' +'No Panel', label: 'No Panel' },                                                                                                                               
                                                                { value: rowIndex + '#' +'Candidate Drop', label: 'Candidate Drop' },
                                                                { value: rowIndex + '#' +'Candidate NP', label: 'Candidate NP' },
                                                                { value: rowIndex + '#' +'Panel Not Joined', label: 'Panel Did Not Join' },
                                                                { value: rowIndex + '#' +'No Show', label: 'No Show' },
                                                                { value: rowIndex + '#' +'Hold', label: 'Hold' },
                                                                { value: rowIndex + '#' +'Interview Rejected', label: 'Rejected' },
                                                                { value: rowIndex + '#' +'Final Select', label: 'Final Select' },
                                                            ]}
                                                        /> :
                                                        table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             }                                                                                           
                                        </>

                                </div>
                            ) : (
                                table[tableInfo]
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}
