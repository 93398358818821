import React, { useState } from "react";
import { Link } from "react-router-dom";
import arrowIcon from "../../assets/right-arrow.png";

import "./resumeFilterSidebar.scss";

function ResumeFilterSidebar() {
  const [isCollapsedJD, setIsCollapsedJD] = useState(true);
  const [isCollapsedJDrs, setIsCollapsedJDrs] = useState(true);

  const handleToggleCollapsejd = () => {
    setIsCollapsedJD(!isCollapsedJD);
  };
  const handleToggleCollapsers = () => {
    setIsCollapsedJDrs(!isCollapsedJDrs);
  };

  const setStatus = (data) => {
    console.log(data);
    localStorage.setItem("status", JSON.stringify({ status: data }));
  };
  return (
    <div className="sidebar-wrapper">

      {/* <Link className="sidebar-link" to="/interviews">
                Job Descriptions
            </Link> */}
      <Link className="sideFont" to="/TeamAssignments " style={{ marginLeft: "12px" }}>
        More Filters
        <hr></hr>
      </Link>

      {/* <Link className="sidebar-link" to="/jds">
                Dashboard
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedJD ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapsejd}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedJD ? "false" : "true"}
        >
          <img
            src={arrowIcon}
            alt="Arrow"
            width="8" height="8" 
            className={`arrow-icon me-2 ${isCollapsedJD ? "" : "rotate"}`}
          />
          KeyWords
        </button>

        {!isCollapsedJD && (
          <ul id="home-collapse" className="listShow">
            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/jds", state: { status: "active" } }}
              >
                Keywords Included
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/jds", state: { status: "hold" } }}
                onClick={() => setStatus("hold")}
              >
                Keyword Excluded
              </Link>
            </li>
            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>
      
      {/* <Link className="sidebar-link" to="/My Assignments">
                Dashboard
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedJD ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapsejd}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedJD ? "false" : "true"}
        >
          <img
            src={arrowIcon}
            alt="Arrow"
            width="8" height="8" 
            className={`arrow-icon me-2 ${isCollapsedJD ? "" : "rotate"}`}
          />
          Inteview and Selections
        </button>

        {!isCollapsedJD && (
          <ul id="home-collapse" className="listShow">
            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/myJDs", state: { status: "active" } }}
              >
                Screen Selects
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/jds", state: { status: "hold" } }}
                onClick={() => setStatus("hold")}
              >
                L1 Reject/Selected
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/jds", state: { status: "closed" } }}
                onClick={() => setStatus("closed")}
              >
                 L2 Reject/Selected
              </Link>
            </li>
            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>



      {/* <Link className="sidebar-link" to="/resumes">
                Dashboard
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedJD ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapsejd}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedJD ? "false" : "true"}
        >
          <img
            src={arrowIcon}
            alt="Arrow"
            width="8" height="8" 
            className={`arrow-icon me-2 ${isCollapsedJD ? "" : "rotate"}`}
          />
          Notice Periods
        </button>

        {!isCollapsedJD && (
          <ul id="home-collapse" className="listShow">
            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/resumes", state: { status: "active" } }}
              >
                Resigned/Layoff
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/jds", state: { status: "hold" } }}
                onClick={() => setStatus("hold")}
              >
                LWD This Month
              </Link>
            </li>
            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>

      {/* <Link className="sidebar-link" to="/interviews">
                Dashboard
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedJD ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapsejd}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedJD ? "false" : "true"}
        >
          <img
            src={arrowIcon}
            alt="Arrow"
            width="8" height="8" 
            className={`arrow-icon me-2 ${isCollapsedJD ? "" : "rotate"}`}
          />
          Candidate Status
        </button>

        {!isCollapsedJD && (
          <ul id="home-collapse" className="listShow">
            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/jds", state: { status: "active" } }}
              >
                Available/Callback/NP Etc
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/jds", state: { status: "closed" } }}
                onClick={() => setStatus("closed")}
              >
                Busy/Etc
              </Link>
            </li>
            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>



      {/* <Link className="sidebar-link" to="/resume_source">
                Resume Sourcing
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedJDrs ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapsers}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedJDrs ? "false" : "true"}
        >
          <img
            src={arrowIcon}
            alt="Arrow"
            width="10" height="10" 
            className={`arrow-icon me-2 ${isCollapsedJDrs ? "" : "rotate"}`}
          />
          Last Uploaded
        </button>

        {!isCollapsedJDrs && (
          <ul id="home-collapse" className="">
            <li className="list-group-item">
            <Link className="sidebar-link" to="/resume_source">
               Last 7days
              </Link>
              <Link className="sidebar-link" to="/From_My_Team">
               Last 15days
              </Link>
            </li>
            <li className="list-group-item">
              <Link className="sidebar-link" to="/From_Vendors">
               Last 30 days
              </Link>
            </li>
            <li className="list-group-item">
              <Link className="sidebar-link" to="/From_Vendors">
               Last 45 days
              </Link>
            </li>
            <li className="list-group-item">
              <Link className="sidebar-link" to="/From_Vendors">
               Last 60 days
              </Link>
            </li>                        

            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>
    </div>
  );
}

export default ResumeFilterSidebar;
