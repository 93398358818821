import React, { useEffect,useState } from "react";
import { useParams } from "react-router-dom";
import {Input,Card, Button,Form} from "antd"
import editIcon from "../../../assets/pencil.png";
import ResumeSkillsCardTable from "../../../components/ResumeSkillsCardTable";

import "./resumeAnalysisFreeResults.scss";

function ResumeAnalysisFreeResults( {currentState,fileName}) {

  const { value } = useParams();
  const { Search } = Input;
  const [candidateFound, setIsCandidateFound] = useState(false);
  const [resultString, setResultString] = useState("");
  const [canDetails,setCandidateDetails]= useState({name:"",role:"",exp:"",finalScore:0,skillData:[]});
 
  const titleList = [
    "Keyword",    
    "Suitability",
    "Required Occurance",
    "Found Occurance"
  ];
  const SkillsData = []

  const getKeywordsData = (kAarray) =>{
    const keywordsyData = kAarray?.map((arr, idx) => {
        return {
            skill:  arr?.skill,
            avg: arr?.score.avg,
            total: arr?.score.total,
            found: arr?.score.found,
        };
    });

    return keywordsyData
  }

  useEffect(() => {
    onSearch()
  }, [localStorage.getItem("resumeAnalysisFreeResponse")]);


    const GetResumeDetails = ()=>{ 

    const responseData = JSON.parse(localStorage.getItem("resumeAnalysisFreeResponse"));
    console.log("Response Data", responseData);
            return {
                name: "Unknowns",
                finalScore:responseData?.final_score,
                found: true
        }  
   }

  const handleKeys = (fianlScore) =>{
    for(const [key, value] of Object.entries(fianlScore?.scorelist)) {
      const keyWordsArray = new Map([])
      for(const [key2, value2] of Object.entries(fianlScore?.scorelist[key].keywords)) {
        keyWordsArray.set(key2,value2)
      }
      //converting from map to array ??? needs to optimize this
      const arr = [...keyWordsArray]?.map(([skill, score, required,found]) => ({ skill, score, required,found }));
      const one = {
        kArray:arr,
        desc:fianlScore?.scorelist[key]?.description,
        score:fianlScore?.scorelist[key]?.score,
        type:key 
      }
      SkillsData?.push(one)
      }
    console.log("SkillsData",SkillsData);
  }
  const onSearch = () => {
    setResultString("")
    const dt= GetResumeDetails()
    if(dt.found){
        setResultString("")
        setIsCandidateFound(true) 
        handleKeys(dt.finalScore)
        setCandidateDetails({name:dt.name,role:dt.finalScore?.role,exp:dt.finalScore?.exp,
                             finalScore:dt.finalScore,skillData:[...SkillsData]})
    }else{
        setResultString("Candidate Not Found")
        setCandidateDetails({name:"",role:"",exp:"",finalScore:"",skillData:[]})
    } 
    console.log("Current Values", canDetails.role,dt,canDetails.skillData);    
  }

  return (
   <div className="resumeAnalysisNew-wrapper">
      <div className="resumeAnalysisNew-heading-filename">
                  Resume: {fileName} - {canDetails?.exp} Yrs
      </div>
      <div className="resumeAnalysisNew-heading">
        <div className="resumeAnalysisNew-heading-items">
                  Email Id: {canDetails?.finalScore.email}
        </div>
        <div className="resumeAnalysisNew-heading-items">
                  Mobile: {canDetails?.finalScore.contact}
        </div>
        <div className="resumeAnalysisNew-heading-items">
                  Extracted Exp: {canDetails?.finalScore.expparsed} Yrs
        </div>        
      </div>
      <div className="resumeAnalysisNew-verdicts">
      <div className = "resumeAnalysisNew-verdicts-skills">
          <div className="resumeAnalysisNew-verdicts-others-items">
              <Card title="Final Analysis" bordered={true}>
               { (canDetails?.finalScore.score &&(canDetails?.finalScore.eduverdict?.verdict=="Suitable"))?(
                <div>
                  Resume is {canDetails?.finalScore.score} % Suitable.<br></br>
                  {
                    canDetails?.finalScore.scorelist.skill_scores.additional ==""?<></>:
                    canDetails?.finalScore.scorelist.skill_scores.additional
                  }
                                    {
                    canDetails?.finalScore.scorelist.role_scores.additional ==""?<></>:
                    canDetails?.finalScore.scorelist.role_scores.additional
                  }
                </div>)
               :(
                <div>
                  Resume is Not Suitable.
                  {
                    canDetails?.finalScore.eduverdict?.verdict == "Not Suitable"? <div>Education is Not suitable</div> :
                    ""
                  }
                </div>)}
              </Card>
          </div>
        </div>        
        <div className = "resumeAnalysisNew-verdicts-skills">
          <div className="resumeAnalysisNew-verdicts-others-items">
              <Card title="Skills Analysis" bordered={true}>
                 {
                   canDetails.skillData !=0 ?
                       (<div>
                         Skills are {canDetails?.skillData?.[2].score} % Matching.
                          <div>
                              <div style={{marginTop:"15px",fontWeight: "400",fontSize:"12px" ,textAlign:"center"}}>
                                  Mandatory Skills Results
                              </div>
                              <ResumeSkillsCardTable
                              titleList={titleList}
                              data={getKeywordsData(canDetails?.skillData?.[2].kArray)}
                              shortTable
                              />
                          </div>                        
                        </div>):""
                 }
              </Card>
          </div>
        </div>
        <div className = "resumeAnalysisNew-verdicts-skills">
          <div className="resumeAnalysisNew-verdicts-others-items">
              <Card title="Role Analysis" bordered={true}>
                 {
                   canDetails.skillData !=0 ?
                       (<div>
                         Candidate is {canDetails?.skillData?.[1].score} % suitable for the {canDetails?.role} role.
                          <div>
                              <ResumeSkillsCardTable
                              titleList={titleList}
                              data={getKeywordsData(canDetails?.skillData?.[1].kArray)}
                              shortTable
                              />
                          </div>                       
                        </div>):""
                 }
              </Card>
          </div>
        </div>  
        <div className = "resumeAnalysisNew-verdicts-skills">
          <div className="resumeAnalysisNew-verdicts-others-items">
              <Card title="Education Analysis" bordered={true}>
                 { 
                   canDetails.skillData !=0 ?
                       (<div>
                          {canDetails?.finalScore.eduverdict?.verdict } - {canDetails?.finalScore.eduverdict?.description}<br></br>
                          <br></br>
                          {   
                              canDetails?.finalScore.edudetailstype =="Normal"?
                              canDetails?.finalScore.edudetails?.map((row, idx) => {
                                return (
                                <div >
                                  {row}
                                </div>)  
                               }):
                               canDetails?.finalScore.edudetailstable?.map((row, idx) => {
                                return (
                                <div >
                                  {row.map((item,i)=>{
                                      return (
                                        <div>
                                           {item}
                                        </div>
                                      )
                                  })}
                                </div>)  
                               })
                        }              
                        </div>):""
                 }
              </Card>
          </div>
        </div>    
        <div className = "resumeAnalysisNew-verdicts-skills">
          <div className="resumeAnalysisNew-verdicts-others-items">
            <Card title="Exp Analysis" bordered={true}>
                  To Do
              </Card>
          </div>
        </div>                     
        <div className = "resumeAnalysisNew-verdicts-others">        
         <div className="resumeAnalysisNew-verdicts-others-items">
            <Card title="Grammer Analysis" bordered={true}>
              Card content
            </Card>
         </div>          
         <div className="resumeAnalysisNew-verdicts-others-items">
            <Card title="Barred Company Analysis" bordered={true}>
              Card content
            </Card>
         </div> 
         <div className="resumeAnalysisNew-verdicts-others-items">
            <Card title="Barred College Analysis" bordered={true}>
              Card content
            </Card>
         </div>                                                     
     
        </div>
      </div>             
   </div>
  );
}

export default ResumeAnalysisFreeResults;
