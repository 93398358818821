import React, { useEffect,useState } from "react";
import { Button, Empty,Select } from "antd";

import useStatsStore from "../../store/stats.store";
import useMembersStore from "../../store/members.store";

import Table from "../Table";
import "./jdsConsolidated.scss";
import TeamAssignmentCard from "../TeamAssignmentCard";

export default function JDsConsolidated(props) {
  console.log(props);


  const status = localStorage.getItem("status");

  const { jdsConsolidatedStats,getJdsConsolidatedStats } = useStatsStore();
  const { getClients,clients} =  useMembersStore();

  const [filterParamPrio, setFilterPrio] = useState("all");
  const [filterParamJobStatus, setFilterJobStatus] = useState("active");
  const [filterParamClient, setFilterClient] = useState("all");
  const [filterParamTA, setFilterTA] = useState("all");
  const [clientList, setSetClientList] = useState();

  const ClientAll = { value: 'all', label: 'All' }

  var clientInDB = clients?.map((client, idx) => {
    return {
      value:client?.ClientName,
      label:client?.ClientName,
    };
  })


  useEffect(() => {
    getClients()
    clientInDB?.push(ClientAll)
    console.log("clientList", clientList)
    console.log("clientInDB", clientInDB)
    console.log("ClientAll", ClientAll)
    setSetClientList([...clientInDB])
    handleUpdateFilter()
  }, []);

  const handleFilterJDPriority =(value)=>{
    setFilterPrio(value)
  }
  const handleFilterJDstatus =(value)=>{
    setFilterJobStatus(value)
  }
  const handleFilterCustomers =(value)=>{
    setFilterClient(value)
  }
  const handleFilterTA =(value)=>{
    setFilterTA(value)
  }

  const handleUpdateFilter =() =>{
    let encodedJsonValues ={};
    encodedJsonValues.prio = filterParamPrio;
    encodedJsonValues.jobstatus = filterParamJobStatus;
    encodedJsonValues.client = filterParamClient;
    encodedJsonValues.ta = filterParamTA;

    getJdsConsolidatedStats(encodedJsonValues);
  }

  const titleList = [
    "Sr. No.",
    "Assigned On",
    "Priority",    
    "Job Title",
    "Client",
    "TA",
    "SPOC",
    "Assigned To",
    "Hiring Manager",
  ];

  const GetTeamAssignment =(jds)=>{
    var assignedTeam=''
    jds?.JDAssignment?.Assignments?.map((assign,idx)=>{
      if (assignedTeam === ''){
        assignedTeam = assign.AssignedToName;
      }else{
        assignedTeam = assignedTeam +','+assign.AssignedToName;
      }
      
     return })

     return assignedTeam
  }

  const jdsDetailData = jdsConsolidatedStats?.map((jds, idx) => {
    return {
      id: idx + 1,
      date_added: jds?.JDOther.PostingDate,  
      priority: jds?.JDOther.Priority,
      title: '['+jds?.QhCompanyJID+'] '+jds?.JDDetails.Title,
      client_name: jds?.JDClient.ClientName,
      ta_name: jds?.JDClient.TaName,
      SPOC: jds?.JDAssignment?.Spoc,
      assignedTo: GetTeamAssignment(jds),
      hiring_project_team_name: jds?.hiring_project_team_name,
    };
  });

  return (
    <div className="teamTask-wrapper">
      <div className="teamTask-title">All JDs ({jdsConsolidatedStats?.length}) </div>
      <div>
        <div className="teamTask-filter">
        <div className="teamTask-filter-item-wrapper">
            <div className="teamTask-filter-item-label"> 
          
            </div>
            <div className="teamTask-filter-item"
                 style={{margin: "10px"}}>
              <Button
                onClick={handleUpdateFilter}
              >
                Update Now
              </Button>
            </div>
          </div>
          <div className="teamTask-filter-item-wrapper">
            <div className="teamTask-filter-item-label">
              Customers
            </div>
            <div className="teamTask-filter-item">
              <Select
                style={{ width: 130,height: 24 ,textAlign:"left"}}
                onChange={handleFilterCustomers}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}  
                placeholder= "All"                                                     
                options={clientList}
              />
            </div>
          </div>
          <div className="teamTask-filter-item-wrapper">
            <div className="teamTask-filter-item-label">
              JD Status
            </div>
            <div className="teamTask-filter-item">
              <Select
                style={{ width: 130,height: 24 }}
                onChange={handleFilterJDstatus}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}                  
                placeholder= "Active"                                               
                options={[
                          { value: "active", label: 'Active' },
                          { value: "closed", label: 'Closed' },
                          { value: "hold", label: 'Hold' }, 
                          { value: "wait_fb", label: 'Temp Hold' },                                                               
                        ]}
              /> 
            </div>
          </div>
          <div className="teamTask-filter-item-wrapper">
            <div className="teamTask-filter-item-label">
              Priority
            </div>
            <div className="teamTask-filter-item">
              <Select
                style={{ width: 130,height: 24 }}
                onChange={handleFilterJDPriority}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}                  
                placeholder={"All"}                                                  
                options={[
                          { value: "all", label: 'All' },
                          { value: "high", label: 'High' },
                          { value: "urgent", label: 'Urgent' },
                          { value: "todays", label: 'Todays' },  
                          { value: "low", label: 'Low' },
                          { value: "normal", label: 'Normal' },                                                                
                        ]}
              /> 
            </div>
          </div>              
        </div>

            
      </div>
       
      {jdsConsolidatedStats?.length > 0 ? (
        <Table
          isClickable={true}
          isJDPage={true}
          titleList={titleList}
          data={jdsDetailData}
        />
      ) : (
        <Empty style={{ margin: "60px" }} />
      )}
    </div>
  );
}
