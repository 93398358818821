import axios from "../helper/axios";

export function Createjd(data, account_type, owner_id, callback) {
  console.log("create jd called");

  return async (Dispatch) => {
    axios
      .post(`/${account_type}/${owner_id}/jds`, data)
      .then((res) => {
        console.log(res);
        callback();
      })
      .catch((e) => console.log(e));
  };
}

export function Getjds(account_type, owner_id) {
  console.log("Getjds called");
  return async (Dispatch) => {
    axios
      .get(`/jds`, {
        params: {
          account_type: account_type,
          owner_id: owner_id,
        },
      })
      .then((res) => {
        console.log(res);
        Dispatch({ type: "JD_LIST", payload: res.data });
      })
      .catch((e) => console.log(e));
  };
}

export function GetJd(account_type, owner_id, jd_id) {
  console.log("Getjd called");

  return async (Dispatch) => {
    axios
      .get(`/${account_type}/${owner_id}/jds/${jd_id}`)
      .then((res) => {
        console.log(res);
        Dispatch({ type: "JD_INFO", payload: res.data });
      })
      .catch((e) => console.log(e));
  };
}

export function updateJD(value, account_type, owner_id, jd_id) {
  return async (Dispatch) => {
    console.log("indside dispatgch");
    axios
      .put(`/${account_type}/${owner_id}/jds/${jd_id}`, value)
      .then((res) => {
        console.log(res);
        Dispatch({ type: "JD_INFO", payload: res.data });
      })
      .catch((e) => console.log(e));
  };
}

export function uploadResume(owner_id, jd_id, value) {
  return async (Dispatch) => {
    console.log("indside dispatgch");
    axios
      .post(`/users/${owner_id}/jds/${jd_id}/resumes`, value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        Dispatch({ type: "JD_INFO", payload: res.data });
      })
      .catch((e) => console.log(e));
  };
}

export function GetjdResumes(jdId) {
  console.log("GetjdResumes called");

  return async (Dispatch) => {
    axios
      .get(`/resumes/jds/${jdId}`)
      .then((res) => {
        console.log(res);
        Dispatch({ type: "RESUMES", payload: res.data });
      })
      .catch((e) => console.log(e));
  };
}

export function Downloadresumes(owner_id, jdId, resumeid) {
  console.log("GetjdResumes called");

  return async (Dispatch) => {
    axios
      .get(`/users/${owner_id}/jds/${jdId}/resumes/${resumeid}`, {
        responseType: "blob", // Tell axios to treat the response as a binary blob
      })
      .then((res) => {
        console.log(res);
        const blobURL = URL.createObjectURL(res.data);

        // Create a link and simulate a click to trigger download
        const link = document.createElement("a");
        link.href = blobURL;
        link.download = "resume.pdf";
        link.click();
      })
      .catch((e) => console.log(e));
  };
}

export function UpdateStatus(account_type, owner_id, jd_id, status) {
  console.log("UpdateStatus called");
  return async (Dispatch) => {
    axios
      .put(`/${account_type}/${owner_id}/jds/${jd_id}/status/${status}`)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => console.log(e));
  };
}
