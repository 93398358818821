import React, { useEffect,useState } from "react";
import { Badge, Calendar,theme } from "antd";
import { useNavigate } from "react-router-dom";

import useStatsStore from "../../store/stats.store";
import InterviewDaySchedule from "../../components/interviewDaySchedule"

import Table from "../Table";

import "./interviewCalendar.scss";

export default function InterviewCalendar(props) {
  console.log(props);


  const status = localStorage.getItem("status");

  const {interviewSchedules,getInterviewSchedule } = useStatsStore()

  const getListData = (value) => {
    let listData = []; // Specify the type of listData
    let dateValue = value.format("DD-MM-YYYY");
 
    switch (dateValue) {
      case '08-09-2024':
        listData = [
          {
            type: 'success',
            content: '10:00 AM',
          },
          {
            type: 'success',
            content: '10:00 AM',
          }
        ];
        break;
      case '10-09-2024':
        listData = [
          {
            type: 'success',
            content: '10:00 AM',
          },
          {
            type: 'success',
            content: '12:20 PM',
          },
          {
            type: 'success',
            content: '14:24 PM',
          },
        ];
        break;
      case '15-09-2024':
        listData = [
          {
            type: 'success',
            content: '14:24 PM',
          },
          {
            type: 'success',
            content: '14:24 PM',
          },
          {
            type: 'success',
            content: '14:24 PM',
          },
          {
            type: 'success',
            content: '14:24 PM',
          },
          {
            type: 'success',
            content: '14:24 PM',
          },
          {
            type: 'success',
            content: '14:24 PM',
          },
        ];
        break;
      default:
    }
    return listData || [];
  };

  const checkforInterviewsOnThisDate= (jd,date) =>{
    for (let i = 0; i < jd.InterviewItem?.length; i++){
      console.log("check for the date",  jd?.InterviewItem[i]?.interviewdate,date)
      if(jd?.InterviewItem[i]?.interviewdate == date){
        return true
      } 
    }
  }
  const getListData2 = (value) => {
    let listData = []; // Specify the type of listData
    let dateValue = value.format("DD-MM-YYYY");
    for (let i = 0; i < interviewSchedules?.JDListInterview?.length; i++){
        for (let idx = 0; idx < interviewSchedules?.JDListInterview[i]?.InterviewItem.length; idx++){
           if(interviewSchedules?.JDListInterview[i]?.InterviewItem[idx]?.interviewdate == dateValue){
               listData.push({type:'success',
              content:interviewSchedules?.JDListInterview[i]?.InterviewItem[idx].interviewtime})
           }
        }
    }
    return listData || [];
  };

  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
    getInterviewSchedule()
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };
  const dateCellRender = (value) => {
    const listData = getListData2(value);
    return (
      <ul className="events" style={{fontSize: "8px",padding : 0,backgroundColor:"#cef3d6"}}>
        {listData.map((item) => (
          <li style={{fontSize : "8px"}} key={item.content}>
            <Badge  style={{fontSize : '10px'}} status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };
  const onPanelChange = (value, mode) => {
    console.log(value.format('DD-MM-YYYY'), mode);
  };

  const calculatCurrentDate = () => {
    const date = new Date();
    var day = date.getDate();
    var month = date.getMonth()+1;
    const year = date.getFullYear();

    if (day < 10) {
      day = "0"+ day
    }
    if (month < 10) {
      month = "0"+ month
    }
    const cDate = day + "-" + month + "-" + year;
    console.log("Current Date",cDate);
    
    return cDate
  };

  const [selectedDate, setSelectedDate] = useState(calculatCurrentDate());
  const [currentDate, setCurrentDate] = useState(calculatCurrentDate());

  useEffect(() => {
    getInterviewSchedule();
    setCurrentDate();
  }, [selectedDate]);

  const handleOnChange = (value) => {
    console.log("handlOnchage",value.format('DD-MM-YYYY'));
    setSelectedDate(value.format('DD-MM-YYYY'))
  };

  const handlOnSelect = (value) => {
    console.log("handleOnselect",value.format('DD-MM-YYYY'));
    setSelectedDate(value.format('DD-MM-YYYY'))
  };

  const checkforInterviewsToday= () =>{
     return false
  }

  const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };
  const { token } = theme.useToken();
  const wrapperStyle = {
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
    fontSize: 10,
    padding: 0,
    margin: 0
  };

  return (
    <div className="interviewCalendar-wrapper">
      <div className="interviewCalendar-Left-wrapper">
        <div className="interviewCalendar-Left-item1">              
              <Calendar className="interviewCalendar-Left-item1" style={wrapperStyle} cellRender={cellRender} 
                        onSelect={handlOnSelect}
                        onChange={handleOnChange} />
        </div>
      </div>
      <div className="interviewCalendar-Right-wrapper">
        { 
            currentDate != selectedDate ?(
            interviewSchedules?.JDListInterview?.map((jd) => (
              checkforInterviewsOnThisDate(jd,selectedDate)?(              
              <InterviewDaySchedule jd={jd} sDate={selectedDate} />)
              :<h5 style={{margin:20}}>No Interviews on {selectedDate}</h5> 
            )))
            :(
              interviewSchedules?.JDListInterview?.map((jd) => (
                checkforInterviewsOnThisDate(jd,currentDate)?(              
                <InterviewDaySchedule jd={jd} sDate={currentDate} />)
                :<h5 style={{margin:20}}>No Interviews on {currentDate}</h5> 
              )))
        }
      </div>
    </div>
  );
}
