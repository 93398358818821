import React, { useState,useRef } from "react";
import { Empty, Input, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import editIcon from "../../assets/edit.svg";
import PieChart from "../PieChart";
import StackChart from "../StackChart";
import ResumeResultsCard from "../resumeResultsCard";

import "./resumeResultsCardList.scss";

const titleList = [
    "Sr. No.",
    "QH ID",
    "Name",
    "Email Id",
    "Mobile",
    "Sex M/F",
    "Age",
    "Location",
    "Designation",
    "Company",
    "Max Degree",
    "Remarks"
];

export default function ResumeResultsCardList({
    isClickable = false,
    isResumePage = false,
    isEditField = false,
}) {
    const navigate = useNavigate();
    const [isEditActive, setIsEditActive] = useState(false);
    const [activeFieldID, setActiveFieldID] = useState();
    const [activeRowID, setActiveRowID] = useState();
    const [editInput, setEditInput] = useState(""); // State to hold input value
    const [editInputKey, setEditInputKey] = useState("");
    const inputRef = useRef(null);

    const focusInputField = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const handleResumeDetailClick = (id,qhID) => {

    };
   const handleResumeStatsClick = (id)=>{
    
    navigate(id)
   }
    const handleEditBtnClick = (id, rowId) => {
        setIsEditActive(true);
        setActiveFieldID(id);
        setActiveRowID(rowId);
        focusInputField();
    };


    const showPieChart = (tableInfo) =>{
        if( tableInfo === ""){
            return false
        }
        else{
            return false
        }
    }


    return (
        <div className="resumeResultsCardList-wrapper">
            <div >
                {titleList.map((title) => (
                    <ResumeResultsCard/>
                ))}
            </div>
        </div>
    );
}
