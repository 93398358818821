import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useJDStore from "../../../store/jd.store";
import { useLocation } from "react-router-dom";

import Table from "../../../components/Table";
import logo from "../../../assets/QuallyHireGreen.png";
import { Radio, Divider, Form, Input, message, Button, TimePicker, DatePicker,Card } from "antd";
import Footer from "../../footer";

import "./jobListingSearchResults.scss";
import JobListingSearchResultFilter from "../jobListingSearchResultFilter"
import { Dropdown } from "react-bootstrap";


export default function JobListingSearchResults(props) {
  console.log(props);

  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const { getAllJds, jdData } = useJDStore();

  const handleSignin = () => {
    navigate('/login')
  };

  const handleSignup = () => {
    navigate('/signup')
  };

  const handleJobSeekers = () => {
    navigate('/jobSeekers')
  };

  const handleCareerListing = () => {
    navigate('/jobSeekers')
  };

  const handleUpdateFormSubmit = () => {
    navigate('/jobSeekers')
  };

  useEffect(() => {
    getAllJds();
  }, [status]);

  const titleList = [
    "Sr. No.",
    "Date Added",    
    "Client",
    "Job ID",
    "Job Title",
    "Available Resumes",
    "Screen Rejected",
    "Interviews",
    "Final Select",
    "Priority",
    "Job Status",
    "Matching Resume",
    "Assigned To",
    "SPOC",
    "Hiring Manager",
    "Date Modified",
  ];

  const jdsDetailData = jdData?.map((jds, idx) => {
    return {
      id: idx + 1,
      date_added: jds?.date_added,      
      client_name: jds?.client_name,
      jobId: jds?.ID,
      title: jds?.title,
      available_resumes: jds?.available_resumes,
      screen_rejected: jds?.screen_rejected,
      interviews: jds?.interviews,
      final_select: jds?.final_select,
      priority: jds?.priority,
      status: jds?.status,
      matching_resume: jds?.matching_resume,
      assigned_to: jds?.assigned_to,
      SPOC: jds?.SPOC,
      hiring_project_team_name: jds?.hiring_project_team_name,
      date_modified: jds?.date_modified,
    };
  });

  return (
    <div className="jobListing-wrapper">
       <div className="jobListing-header"> 
          <div className="header-logo">
              <img className="header-logo-img"src={logo} />
              <div className="header-logo-name">
                  Quallyhire AI
              </div>
          </div>
          <div className="header-menu">
            <ul className="header-menu">
              <li >
                <a href="/">Home</a>
              </li>
              <li>
                
              <a href="/jobSeekers">JobSeekers</a>
              </li>
              <li>
                Support 
              </li>                                          
            </ul>
          </div>
          <div className="header-sign">
            <Button className="sign-button" onClick={() => handleSignin()}>
              Post 5 Free Jobs
            </Button>
            <Button className="job-button" onClick={() => handleJobSeekers()}>
              Employers
            </Button>                                     
          </div>          
       </div>

       <div className="jobListing-title">
         <div className="jobListing-punchLine">
            Get Your Dream Job from Quallyhire AI
         </div>
         <div className="jobListing-puncLineSmall">
            1,80,570 jobs listed here! Your dream job is waiting.
         </div>
         <div className="jobListing-search-wrapper">
            <Form
                className="jobListing-search-wrapper"
                name="jobListing-search"
                autoComplete="off"
                onFinish={handleUpdateFormSubmit}
            >
                <div className="jobListing-search">
                     <Form.Item
                        name="priority"
                    >
                        <Input
                            className="jobListing-search-item"
                            type="text"
                            required= {true}
                            defaultValue={"Keywords"}
                        />
                    </Form.Item>
                    <Form.Item
                        name="number_of_positions"
                    >
                        <Input
                            className="jobListing-search-item"
                            type="text"
                            required={ true}
                            defaultValue={"location"}
                        />
                    </Form.Item>
                    <Form.Item
                        name="number_of_positions"
                    >
                      <Button
                        className="jobListing-search-button"
                        type="primary"
                        htmlType="submit"
                      >
                          Search Jobs
                      </Button>   
                    </Form.Item>   
                </div>                 
            </Form>
         </div>  

         <div className="features-wrapper">
            <div className="feature-heading">
              IT and Software Jobs in Our Platform
            </div>                                                                    
         </div>
       </div>

       <div className="jobListing-Results">
          <div className="jobListing-filters">
            <JobListingSearchResultFilter />
          </div>
          <div className="jobListing-cards">
              <div className="job-listing-result-nums">
                 Resulting <span style={{color : "#008fff"}}>60 </span> jobs matching this criteria
              </div>
              <div className="job-card">
                  <div className="job-card-title">
                     <div className = "title-heading">
                        [QHR-10] Software Engineer
                     </div>
                     <div className = "title-postedDate">
                        1 day ago
                     </div>                                             
                  </div>
                  <div className="job-card-details">
                     <div className="job-card-details-size-sub-heading">
                       Details:
                     </div>
                     <div className="job-card-details-size">
                       6 to 15 Yrs
                     </div>
                     <div className="job-card-details-size">
                       Full Time
                     </div>
                     <div className="job-card-details-size">
                       Permanent
                     </div>                     
                     <div className="job-card-details-size">
                       Bangalore, Chennai, Hyderabad
                     </div>
                     <div className="job-card-details-size">
                       Max 30 days.
                     </div>  
                     <div className="job-card-details-size">
                       30 to 40 LPA
                     </div>                     
                     <div className="job-card-details-size-no-border">
                       WFO
                     </div>                                                                                                                            
                  </div>
                  <div className="job-card-details">
                     <div className="job-card-details-size-sub-heading">
                       Main Skills:
                     </div>
                     <div className="job-card-details-keywords">
                       Python, C++, Git
                     </div>                                                                                                                           
                  </div> 
                  <div className="job-card-details">
                     <div className="job-card-details-size-sub-heading">
                       Posted By:
                     </div>
                     <div className="job-card-details-size">
                       Company HR
                     </div> 
                     <div className="job-card-details-size-no-border">
                       abc@xyw.com
                     </div>                                                                                                                                               
                  </div>
                  <div className="job-card-details">
                     <div className="job-card-details-size-sub-heading">
                       Company Name:
                     </div>
                     <div className="job-card-details-size">
                       Product Company
                     </div>                     
                     <div className="job-card-details-keywords">
                       LG Soft
                     </div>                                                                                                                           
                  </div>                   
                  <div className="job-card-details">
                     <div className="job-card-details-size-sub-heading">
                       Additional:
                     </div>
                     <div className="job-card-details-keywords">
                       Diversity Candidate Needed
                     </div>                                                                                                                           
                  </div>                                                                         
              </div> 
              <div className="job-card">
                  <div className="job-card-title">
                     <div className = "title-heading">
                        [QHR-10] Software Engineer
                     </div>
                     <div className = "title-postedDate">
                        1 day ago
                     </div>                                             
                  </div>
                  <div className="job-card-details">
                     <div className="job-card-details-size-sub-heading">
                       Details:
                     </div>
                     <div className="job-card-details-size">
                       Product Company
                     </div>
                     <div className="job-card-details-size">
                       Full Time
                     </div>
                     <div className="job-card-details-size">
                       Permanent
                     </div>                     
                     <div className="job-card-details-size">
                       Bangalore
                     </div>
                     <div className="job-card-details-size">
                       Max 30 days.
                     </div>  
                     <div className="job-card-details-size">
                       30 to 40 LPA
                     </div>                     
                     <div className="job-card-details-size-no-border">
                       WFO
                     </div>                                                                                                                            
                  </div>
                  <div className="job-card-details">
                     <div className="job-card-details-size-sub-heading">
                       Main Skills:
                     </div>
                     <div className="job-card-details-keywords">
                       Python, C++, Git
                     </div>                                                                                                                           
                  </div> 
                  <div className="job-card-details">
                     <div className="job-card-details-size-sub-heading">
                       Posted By:
                     </div>
                     <div className="job-card-details-size">
                       Company HR
                     </div> 
                     <div className="job-card-details-size-no-border">
                       abc@xyw.com
                     </div>                                                                                                                                               
                  </div>
                  <div className="job-card-details">
                     <div className="job-card-details-size-sub-heading">
                       Company Name:
                     </div>
                     <div className="job-card-details-keywords">
                       LG Soft
                     </div>                                                                                                                           
                  </div>                   
                  <div className="job-card-details">
                     <div className="job-card-details-size-sub-heading">
                       Additional:
                     </div>
                     <div className="job-card-details-keywords">
                       Diversity Candidate Needed
                     </div>                                                                                                                           
                  </div>                                                                         
              </div>                 
          </div>
       </div>

       <div className="landing-footer">
          <Footer/>
       </div>         
    </div>
  );
}
