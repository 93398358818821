import React, { useEffect } from "react";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";

import useJDStore from "../../store/jd.store";
import { useLocation } from "react-router-dom";
import useStatsStore from "../../store/stats.store";

import TableDashBoard from "../TableDashBoard";

import "./myDashboard.scss";
import TeamAssignmentCard from "../TeamAssignmentCard";

export default function MyDashBoard(props) {
  console.log(props);

  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const { getAllJds, jdData } = useJDStore();
  const {myDashConsolidatedStats} = useStatsStore();

  useEffect(() => {
    getAllJds();
  }, [status]);

  const resumeSharingList = [
    "Sr. No.",
    "Client",
    "TA",
    "Job Title",
    "SPOC",
    "Assigned To",
    "Resumes Shared",
    "Resumes FB Pending",
    "Duplicates",
    "End Client FB Pending"
  ];

  const resumeSelectionList = [
    "Sr. No.",   
    "Client",
    "TA",
    "Job Title",
    "SPOC",
    "Assigned To",
    "Screen Selected",
    "Selected for L0",
    "Selected for L1",
    "Selected for L2",
    "Selected for Customer Interview",
    "Selected for HR",
  ];
  const resumeRejectionList = [
    "Sr. No.",
    "Client",
    "TA",
    "Job Title",
    "SPOC",
    "Assigned To",
    "Screen Rejected",
    "Interviews Rejected",
    "Interviews No Shows",
    "Interviews Rescheduled",
  ]; 
  const finalSelectionList = [
    "Sr. No.",    
    "Client",
    "TA Name",
    "Job Title",
    "SPOC",
    "Assigned To",
    "Final Selected",
    "Offered",
    "Declined",
    "Joined",
    "Joined Elsewhere",
    "Offer on Hold",
    "Documentation Ongoing",
    "Documents Issues",
  ];  

  const GetTeamAssignment =(jds)=>{
    var assignedTeam=''
    jds?.JDAssignment?.Assignments?.map((assign,idx)=>{
      if (assignedTeam === ''){
        assignedTeam = assign.AssignedToName;
      }else{
        assignedTeam = assignedTeam +','+assign.AssignedToName;
      }
      
     return })

     return assignedTeam
  }

  const resumeDetailData = myDashConsolidatedStats?.resumesharing?.map((resume, idx) => {
    return {
      id: idx + 1,  
      client_name: resume?.client,
      ta_name: resume?.ta,
      title: '['+resume?.qhcompanyjid+'] '+resume?.title,
      SPOC: resume?.spoc,
      assigned_to: resume?.assignedto,
      shared: resume?.noofresumesshared===0?"-":resume?.noofresumesshared,
      fb_pending: resume?.nooffeedbackpending===0?"-":resume?.nooffeedbackpending,
      duplicates: resume?.noofduplicate===0?"-":resume?.noofduplicate,
      efb_pending: resume?.noofendclientfeedbackpending===0?"-":resume?.noofendclientfeedbackpending,
    };
  });

  const resumeRejectionData = myDashConsolidatedStats?.rejections?.map((rejection, idx) => {
    return {
      id: idx + 1,    
      client_name: rejection?.client,
      ta_name: rejection?.ta,
      title: '['+rejection?.qhcompanyjid+'] '+rejection?.title,
      SPOC: rejection?.spoc,
      assigned_to: rejection?.assignedto,
      numScreenRejected: rejection?.numscreenrejected===0?"-":rejection?.numscreenrejected,
      numInterviewRejected: rejection?.numinterviewrejected===0?"-":rejection?.numinterviewrejected,
      numInterviewNoShow: rejection?.numinterviewnoshow===0?"-":rejection?.numinterviewnoshow,
      numInterviewRescheduled: rejection?.numinterviewrescheduled===0?"-":rejection?.numinterviewrescheduled,
    };
  }); 

  const resumeSelectionData = myDashConsolidatedStats?.feedbacks?.map((feedback, idx) => {
    return {
      id: idx + 1,    
      client_name: feedback?.client,
      ta_name: feedback?.ta,
      title: '['+feedback?.qhcompanyjid+'] '+feedback?.title,
      SPOC: feedback?.spoc,
      assigned_to: feedback?.assignedto,
      screen_selected: feedback?.numscreenselected===0?"-":feedback?.numscreenselected,
      selected_for_L0: feedback?.numl0===0?"-":feedback?.numl0,
      selected_for_L1: feedback?.numl1===0?"-":feedback?.numl1,
      selected_for_L2: feedback?.numl2===0?"-":feedback?.numl2,
      selected_for_customers: feedback?.numhr===0?"-":feedback?.numcustomer,
      selected_for_hr: feedback?.numhr===0?"-":feedback?.numhr,
    };
  });   
 
  const finalSelectionData = myDashConsolidatedStats?.offers?.map((offer, idx) => {
    return {
      id: idx + 1,    
      client_name: offer?.client,
      ta_name: offer?.ta,
      title: '['+offer?.qhcompanyjid+'] '+offer?.title,
      SPOC: offer?.spoc,
      assigned_to: offer?.assignedto,
      final_selected: offer?.available_resumes,
      offered: offer?.available_resumes,
      declined: offer?.available_resumes,
      joined: offer?.available_resumes,
      joined_else: offer?.available_resumes,
      offer_hold: offer?.available_resumes,
      documents_ongoing: offer?.available_resumes,
      documents_issue: offer?.available_resumes,
    };
  }); 

  return (
    <div className="teamAssignment-wrapper">
      {jdData?.length > 0 ? (
        <>
           <div className="teamAssignmentTitle">Resumes Sharing</div>
           <TableDashBoard
            isClickable={true}
            isJDPage={true}
            titleList={resumeSharingList}
            data={resumeDetailData}
          /> 
          <div className="teamAssignmentTitle">Screen/Interview Selections</div>
           <TableDashBoard
            isClickable={true}
            isJDPage={true}
            titleList={resumeSelectionList}
            data={resumeSelectionData}
          />               
          <div className="teamAssignmentTitle">Rejections/No Shows</div>
           <TableDashBoard
            isClickable={true}
            isJDPage={true}
            titleList={resumeRejectionList}
            data={resumeRejectionData}
          />  
          <div className="teamAssignmentTitle">Final Selections/Offers</div>
           <TableDashBoard
            isClickable={true}
            isJDPage={true}
            titleList={finalSelectionList}
            data={finalSelectionData}
          />                                    
        </>
        
      ) : (
        <Empty style={{ margin: "60px" }} />
      )}
    </div>
  );
}
