import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Select, message } from "antd";
import useMembersStore from "../../store/members.store";
import useJDStore from "../../store/jd.store";

import "./jdAssignmentForm.scss";

export default function JDAssignmentForm({ setCurrentView }) {
  const params = useParams();
  const { getVendors, vendors, getTeamMembers, teamMembers, assignedTeamMembers, getAssignedMembers } = useMembersStore();
  const { postAssignment, selectedJD } = useJDStore();
  const [formKey, setFormKey] = useState(1);
  const [form] = Form.useForm();

  var AssignmentValues = {
    spoc_TA: assignedTeamMembers?.Spoc,
    team_members:assignedTeamMembers?.Assignments?.map((assign,idx)=>{return assign.AssignedToUid + '+'+ assign.AssignedToName}),
  };

  const [ curentAssignment, setAssignments ] = useState(AssignmentValues);

  useEffect(() => {
    console.log("called  New assignments",AssignmentValues.spoc_TA);
    getVendors();
    getTeamMembers();
    getAssignedMembers(params.value);
    console.log("Done  New assignments",AssignmentValues.spoc_TA);
  }, []);

  useEffect(() => {
    AssignmentValues = {
      spoc_TA: assignedTeamMembers?.Spoc,
      team_members:assignedTeamMembers?.Assignments?.map((assign,idx)=>{return assign.AssignedToUid + '+'+ assign.AssignedToName}),
    };
    setAssignments(AssignmentValues)
  }, [assignedTeamMembers]);

  useEffect(() => {
    setFormKey((prevKey) => prevKey + 1);
  }, [selectedJD]);

  function getTimeNow(){
    const today = new Date();
    const month = today.getMonth()+1;
    const year = today.getFullYear();
    const date = today. getDate();
    const currentDate = date + "-" + month + "-" + year;
    return currentDate
}

  const encodeValues = (values) =>{
    var encodedJsonValues={}  
    console.log("Assignment Data to encode", values)
    const userData = JSON.parse(localStorage.getItem("userDetails"));

    encodedJsonValues.spoc= values.spoc_TA;
    //get the current date
    let newDate = getTimeNow()
    encodedJsonValues.assignedon= newDate;
    encodedJsonValues.assignedby= userData.username;
    encodedJsonValues.assignments ={}
    encodedJsonValues.assignments = values.team_members?.map((team, idx) => {
    var sp = team.split('+')
    console.log("Encoded Data ", encodedJsonValues)
    return {
        assignedtoname:sp[1],
        assignedtouid:sp[0],
      };
  });
    return encodedJsonValues
  }

  const handleSubmit = (values) => {
    var eValues = encodeValues(values)
    console.log("Called Handle Submit Values", values)
    console.log("Called Handle Submit Params", params.value)    
    const res = postAssignment(params.value, eValues);
    if (res) {
      form.resetFields();  
      setCurrentView("summary");
    } else {
      message.error("JD Assignment Error.");
    }
  };

  return (
    <div className="jd-assignment-form-wrapper">
      <h1 className="jd-assignment-form-title">JD Team Assignment  </h1>
      <Form
        form={form}
        key={formKey}
        className="jd-assignment-form"
        name="jd-assignment-form"
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        initialValues={curentAssignment}
      >
        <div className="double-input-wrapper">
          <Form.Item
            className="jd-assignment-field-item"
            label="Current Assignment"
            name="team_members"
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              className="jd-assignment-select"
            >
              {teamMembers && teamMembers.map((member, idx) => {
                return (
                  <Select.Option key={idx} value={member.qhuid +"+"+ member.username}>
                    {member.username}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            className="jd-assignment-field-item"
            label="Current SPoC"
            name="spoc_TA"
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="Select SPoC"
              className="jd-assignment-select"
            >
              {teamMembers && teamMembers.map((member, idx) => {
                return (
                  <Select.Option key={member.qhuid} value={member.username}>
                    {member.username}
                  </Select.Option>
                );
              })}
            </Select>

          </Form.Item>
        </div>

        <h1 className="jd-assignment-form-title">JD Vendor Assignment</h1>
        <Form.Item
          className="jd-assignment-field-item"
          label="Assign to Vendors"
          name="vendors"
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Select Vendors"
            className="jd-assignment-select"
          >
            {vendors &&
              vendors.map((vendor, idx) => (
                <Select.Option key={idx} value={vendor.ID}>
                  {vendor.email}
                </Select.Option>
              ))}
          </Select>

        </Form.Item>

        <Button
          style={{ width: "100%", height: "40px", fontWeight: "600" }}
          type="primary"
          htmlType="submit"
        >
          Update JD Assignment
        </Button>
      </Form>
    </div>
  );
}
