import { create } from "zustand";
import axios from "../helper/axios";

import useMembersStore from "./members.store";

const useClientStore = create((set, get) => ({
    isModalOpen: false,
    currentClientName: null,
    currentClientTAName:null,
    setCurrentClientName: (value) => {
        set({ currentClientName: value });
    },
    setCurrentClientTAName: (value) => {
        set({ currentClientTAName: value });
    },    
    setModalOpen: () => {
        set({ isModalOpen: true });
    },
    handleModalOk: () => {
        set({ isModalOpen: false });
    },
    handleModalCancel: () => {
        set({ isModalOpen: false });
    },
    handleClientAdd: async (value) => {
        console.log(value);
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));

            await axios.post(`/addclients/${userData.ID}`, {
                    ...value,
            });
            useMembersStore.getState().getClients();
        } catch (err) {
            console.log(err);
        }
    },
    handleClientTAAdd: async (value) => {
        console.log(value);
        var t = get().currentClientName.clientName;
        console.log(t);
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            await axios.post(`/addtas/${userData.ID}/${t}`, {
                    ...value,
            });
            useMembersStore.getState().getClients();
        } catch (err) {
            console.log(err);
        }
    },    
    removeClient: async (clientName) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            await axios.delete(`/deleteclients/${userData.ID}/${clientName}`);

            useMembersStore.getState().getClients();
        } catch (err) {
            console.error(err);
        }
    },
    removeClientTA: async (clientName,taName) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            await axios.delete(`/deletetas/${userData.ID}/${clientName}/${taName}`);

            useMembersStore.getState().getClients();
        } catch (err) {
            console.error(err);
        }
    },    
}));

export default useClientStore;
