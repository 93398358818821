import { create } from "zustand";
import axios from "../helper/axios";

import useMembersStore from "./members.store";

const useSubUserStore = create((set, get) => ({
    isModalOpen: false,
    currentMemberType: null,
    setCurrentMemberType: (value) => {
        set({ currentMemberType: value });
    },
    setModalOpen: () => {
        set({ isModalOpen: true });
    },
    handleModalOk: () => {
        set({ isModalOpen: false });
    },
    handleModalCancel: () => {
        set({ isModalOpen: false });
    },
    handleUserAdd: async (value) => {
        console.log(value);
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));

            if (get().currentMemberType === "team") {
                await axios.post(`/users/${userData.ID}/team_member`, {
                    ...value,
                    account_type: userData.account_type,
                });
                useMembersStore.getState().getTeamMembers();
            } else {
                await axios.post(`/users/${userData.ID}/vendor_connect`, {
                    ...value,
                    account_type: "vendor",
                });
                useMembersStore.getState().getVendors();
            }
        } catch (err) {
            console.log(err);
        }
    },
    removeMember: async (userId) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            await axios.delete(`/users/${userData.ID}/${userId}`);

            useMembersStore.getState().getVendors();
            useMembersStore.getState().getTeamMembers();
        } catch (err) {
            console.error(err);
        }
    },
}));

export default useSubUserStore;
