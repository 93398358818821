import React, { useEffect } from "react";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";

import useJDStore from "../../store/jd.store";
import { useLocation } from "react-router-dom";

import Table from "../Table";

import "./assignedJds.scss";

export default function AssignedJds(props) {
  console.log(props);

  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const { getAllJds, jdData } = useJDStore();

  useEffect(() => {
    getAllJds();
  }, [status]);


  const titleList = [
    "Sr. No.",
    "Assigned On",
    "Priority",    
    "Job Title",
    "Client",
    "TA",
    "SPOC",
    "Assigned To",
    "Hiring Manager",
  ];

  const GetTeamAssignment =(jds)=>{
    var assignedTeam=''
    jds?.JDAssignment?.Assignments?.map((assign,idx)=>{
      if (assignedTeam === ''){
        assignedTeam = assign.AssignedToName;
      }else{
        assignedTeam = assignedTeam +','+assign.AssignedToName;
      }
      
     return })

     return assignedTeam
  }

  const jdsDetailData = jdData?.map((jds, idx) => {
    return {
      id: idx + 1,
      date_added: jds?.JDOther.PostingDate,  
      priority: jds?.JDOther.Priority,
      title: '['+jds?.QhCompanyJID+'] '+jds?.JDDetails.Title,
      client_name: jds?.JDClient.ClientName,
      ta_name: jds?.JDClient.TaName,
      SPOC: jds?.JDAssignment?.Spoc,
      assignedTo: GetTeamAssignment(jds),
      hiring_project_team_name: jds?.hiring_project_team_name,
    };
  });

  return (
    <div className="jds-wrapper">
      {jdData?.length > 0 ? (
        <Table
          isClickable={true}
          isJDPage={true}
          titleList={titleList}
          data={jdsDetailData}
        />
      ) : (
        <Empty style={{ margin: "60px" }} />
      )}
    </div>
  );
}
