import React, { useEffect } from "react";
import { Empty } from "antd";
import STable from "../STable";

import useResumeStore from "../../store/resume.store";
import "./resumeSource.scss";

function ResumeSource() {
    const { resumeStats } = useResumeStore();

    useEffect(() => {
    }, []);

    const tableHeaders = [
        "ID",
        "JD ID",
        "JD Title",
        "Vendor Submissions",
        "Team Submissions",
        "From  Jobboards",
        "From Referral",
        "From Linkedin",
        "From Naukri",
        "From Monster",
    ];
    const tablekeys = resumeStats?.map((stats, idx) => ({
        id: idx + 1,
        jdId: stats?.jd_id,
        jdTitle: stats?.title,
        fromVendor: stats?.from_vendor_connects,
        fromInternal: stats?.from_internal,
        fromJobboards: stats?.from_jobboards,
        fromReferral: stats?.fromReferral,
        fromLinkedin: stats?.from_Linkedin,
        fromNaukri: stats?.from_naukri,
        fromMonster: stats?.from_monster,
    }));

    return (
        <div className="resume-source-wrapper">
           

            <div classname="resume-source-table-wrapper">
                {resumeStats?.length > 0 ? (
                    <STable titleList={tableHeaders} data={tablekeys} />
                ) : (
                    <Empty style={{ margin: "60px" }} />
                )}
            </div>
        </div>
    );
}

export default ResumeSource;
