import React, { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Button, Empty } from "antd";

import RTable from "../RTable";
import RTableSelectable from "../RTableSelectable";
import InterviewTableSelectable from "../InterviewTableSelectable";
import CandidateAddModal from "../CandidateAddModal";

import useCandidateStore from "../../store/candidateModal.store";

import ResumeAnalysisNew from "../resumeAnalysisNew";
import "./jdSummary.scss";
import cloudIcon from "../../assets/upload.png";
import downloadIcon from "../../assets/download.png";
import CandidateProfile from "../candidateProfile";
import PieChart from "../PieChart";
import StackChart from "../StackChart";
import useMembersStore from "../../store/members.store";
import useJDStore from "../../store/jd.store";
import useResumeStore from "../../store/resume.store";

const summaryListTable = [
    "Select",
    "Sr. No.",
    "Resume ID",    
    "Name",
    "Candidate Status",
    "Resume Suitability",        
    "Exp",
    "ECTC",
    "Notice Period",   
    "Resume Status",
    "Updated By",
    "Updated On",
    "Assessment Status"
];

const personalListTable = [
    "Sr. No.",
    "Resume ID",
    "Name",
    "Email Id",
    "Mobile",
    "Sex M/F",
    "Age",
    "Location",
    "Designation",
    "Company",
    "Max Degree",
    "Remarks"
];

const ctcListTable = [
    "Sr. No.",
    "Resume ID",
    "Current CTC",
    "ECTC",
    "CTC Remarks",
    "Official NP",
    "Negotiable NP",
    "LWD",
    "Reason for Leaving",
    "Other Offer Y/N",
    "Other Joining Date",
    "Offer CTC",
    "Offer Company",
    "Multiple Offer Y/N",
    "Remarks"
];

const suitablityListTable = [
    "Select",
    "Sr. No.",
    "Resume ID",  
    "Name",
    "Mobile",
    "Total Exp",
    "Relevant Exp",       
    "Resume Suitability",
    "Candidate Status",
    "Resume Status",
    "Skill Tags",
    "Skill Matrix Suitability",
    "Assessment Suitablity",
];

const sourcingListTable = [
    "Sr. No.",
    "Resume ID",
    "Name", 
    "Email Id",
    "Mobile",
    "Uploaded By",
    "Name",
    "User ID",
    "Uploading Date",    
    "Source",
    "Ownership"
];

const interviewListTable = [
    "Select",
    "Sr. No.",
    "Resume ID",  
    "Name",
    "Mobile",       
    "Interview Type",
    "Interview Action",
    "Interview Date",
    "Interview Time",
    "Interview Status",
    "No of Reschedules",
    "Interview History",
];


export default function JDSummary() {
    const [currentView, setCurrentView] = useState("summary");
    const [selectedResumeID, setResumeID] = useState("");
    const { value } = useParams();
    const { getVendors, vendors, getTeamMembers, teamMembers, assignedTeamMembers, getAssignedMembers } = useMembersStore();
    const { setCandidateModalOpen } = useCandidateStore();
    const { resumes, getResumesByJD, updateResume, downloadResumeByID,allApplications,getAllApplicationsByJD,getProfileResumeDetails,
            resetSelectedProfileResume,resetSelectedApplication,getSpecificApplicationByJD} =
        useResumeStore();

    const {selectedJD} =useJDStore();    

    useEffect(() => {
        getVendors();
        getTeamMembers();
        getAssignedMembers(value);
        getResumesByJD(value);
        getAllApplicationsByJD(value);
    }, []);

    const handleDownloadResume = (resumeID) => {
        downloadResumeByID(value, resumeID);
    };

    const GetUplodedByDetails = (resumeDetail)=>{
        if(resumeDetail?.firstuploadedby.uploadedby === "team"){
            return {
                updatedOn: resumeDetail?.firstuploadedby.teamdetails.updatedon,
                updatedByName:resumeDetail?.firstuploadedby.teamdetails.name,
                source:resumeDetail?.firstuploadedby.teamdetails.source,
                ownership:resumeDetail?.firstuploadedby.resumeownership
            } 
        }else if(resumeDetail?.firstuploadedby.uploadedby === "vendor"){
            return {
                updatedOn: resumeDetail?.firstuploadedby.vendordetails.updatedon,
                updatedByName:resumeDetail?.firstuploadedby.vendordetails.name,
                source:"Vendor",
                ownership:resumeDetail?.firstuploadedby.resumeownership
            }             
        }else if(resumeDetail?.firstuploadedby.uploadedby === "Portal"){
            return {
                updatedOn: resumeDetail?.firstuploadedby.portaldetails.updatedon,
                updatedByName:resumeDetail?.firstuploadedby.portaldetails.name,
                source:"Candidate",
                ownership:resumeDetail?.firstuploadedby.resumeownership
            } 
        }
   }

   const GetUpdatedByDetails = (qhrid)=>{
    for(let idx = 0; idx < allApplications?.length; idx++){  
        if (allApplications[idx]?.qhrid === qhrid) {
            console.log("Application Details", idx,allApplications[idx])    
            return {
                updatedByName:allApplications[idx]?.candidatestatus?.updatedby.name,
                updatedOn:allApplications[idx]?.candidatestatus?.updatedby.updatedon,
                status:allApplications[idx]?.candidatestatus?.status
        } 
       }
     }
   }
   const GetResumeScore = (qhrid)=>{
    for(let idx = 0; idx < allApplications?.length; idx++){  
        if (allApplications[idx]?.qhrid === qhrid) {
            console.log("Application Details", idx,allApplications[idx])    
            return ({
                finalScore: allApplications[idx]?.resumescore.score
        }) 
       }
     }
   }
   
   const GetNoticePeriod = (qhrid)=>{
    for(let idx = 0; idx < resumes?.length; idx++){  
        if (resumes[idx]?.qhrid === qhrid) {
            console.log("Resume Details", idx,resumes[idx])    
            return ({
                np: resumes[idx]?.noticeperiod
        }) 
       }
     }
   }
   const GetLastUpdated = (appDetail)=>{
        if (appDetail.candidatestatus.updatedby.timestamp > appDetail.resumestatus.updatedby.timestamp) {  
            return ({
                updatedBy: appDetail.candidatestatus.updatedby.name,
                updatedOn: appDetail.candidatestatus.updatedby.updatedon
        }) 
       }else{
        return ({
            updatedBy: appDetail.resumestatus.updatedby.name,
            updatedOn: appDetail.resumestatus.updatedby.updatedon
        })
       }
   }
    const summaryData = resumes?.map((resumeDetail, idx) => {

        let candidateSt = GetUpdatedByDetails(resumeDetail?.qhrid)
        let score = GetResumeScore(resumeDetail?.qhrid)

        console.log("Upload Details",score)   
        return {
            select:"",
            id: idx+1,
            qhid:resumeDetail?.qhrid,
            name: resumeDetail?.personaldetail.name,
            exp: resumeDetail?.personaldetail.mobile,
            ECTC: resumeDetail?.personaldetail.mobile,
            noticePeriod: resumeDetail?.personaldetail.mobile,            
            resumeSuitability: score?.finalScore <50 ?"Not Suitable":score?.finalScore + " %",
            candidateStatus: candidateSt?.status,
            resumeStatus: candidateSt?.status,
            uploadedBy: candidateSt?.updatedByName,
            dateAdded: candidateSt?.updatedOn,
            assesmentStatus:"To Do",
        };
    });

    const summaryDataNew = allApplications?.map((appDetail, idx) => {

        let nPeriod = GetNoticePeriod(appDetail?.qhrid) 
        let lUpdate = GetLastUpdated(appDetail)   
        return {
            select:"",
            id: idx+1,
            qhid:  appDetail?.qhrid,
            name: appDetail?.applicationid.name,
            candidateStatus: appDetail?.candidatestatus?.status,
            resumeSuitability: appDetail?.resumescore?.score <50 ?"Not Suitable":appDetail?.resumescore?.score + " %",            
            exp: appDetail?.newemploymentdetails.relevantexp == ""?"-":appDetail?.newemploymentdetails.relevantexp+ "/"+ appDetail?.newemploymentdetails.totalexp,
            ECTC: appDetail?.newemploymentdetails.ectc.totalctc,
            noticePeriod: nPeriod?.officialnp,            
            resumeStatus: appDetail?.resumestatus?.status,
            uploadedBy: lUpdate?.updatedBy,
            dateAdded: lUpdate?.updatedOn,
            assesmentStatus:"To Do",
        };
    });    
    const personalDetailsData = resumes?.map((resumeDetail, idx) => {
        return {
            id: idx+1,
            qhid:resumeDetail?.qhrid,
            name: resumeDetail?.personaldetail.name,
            email: resumeDetail?.personaldetail.email,
            phone: resumeDetail?.personaldetail.mobile,
            preferredLocation: resumeDetail?.personaldetail.preferredLocation,
            currentDesignation: resumeDetail?.currentDesignation,
            noticePeriodOfficial: resumeDetail?.noticePeriodOfficial,
            noticePeriodNegotiable: resumeDetail?.noticePeriodNegotiable,
            maxDegree: resumeDetail?.maxDegree,
            currentCompany: resumeDetail?.currentCompany,
            currentLocation: resumeDetail?.currentLocation,
        };
    });    

    const ctcDetailsData = resumes?.map((resumeDetail, idx) => {
        return {
            id:idx+1,
            qhid:resumeDetail?.qhrid,
            cCTC: resumeDetail?.employmentdetails.currentctc.totalctc,
            eCTC:  resumeDetail?.employmentdetails.currentctc.totalctc,
            remarksCTC: resumeDetail?.employmentdetails.currentctc.remarks,
            noticePeriodOfficial: resumeDetail?.noticePeriodOfficial,
            noticePeriodNegotiable: resumeDetail?.noticePeriodNegotiable,            
            LWD: resumeDetail?.preferredLocation,
            reasonLeaving: resumeDetail?.currentDesignation,
            oOffer: resumeDetail?.maxDegree,
            oDOJ: resumeDetail?.currentCompany,
            oCTC: resumeDetail?.currentLocation,
            oCompany: resumeDetail?.maxDegree,
            mOffer: resumeDetail?.currentCompany,
            remarks: resumeDetail?.currentLocation,
        };
    });   

    const suitabilityData = allApplications?.map((appDetail, idx) => {
        return {
            select:"",
            id: idx+1,
            qhid:  appDetail?.qhrid,
            name: appDetail?.applicationid.name,
            phone: appDetail?.applicationid.mobile,
            totalExp:"",
            relExp:"",
            resumeSuitability: appDetail?.resumescore?.score <50 ?"Not Suitable":appDetail?.resumescore?.score + " %",
            candidateStatus: appDetail?.candidatestatus?.status,
            resumeStatus: appDetail?.resumestatus?.status,
            skills: appDetail?.resumestatus?.tags,
            skillMatix: appDetail?.skillmatix,
            assesment: appDetail?.assesment,
        };
    });

    const interviewData = allApplications?.map((appDetail, idx) => {
        return {
            select:"",
            id: idx+1,
            qhid:  appDetail?.qhrid,
            name: appDetail?.applicationid.name,
            phone: appDetail?.applicationid.mobile,
            iType: appDetail?.interviewstatus?.type?appDetail?.interviewstatus?.type:"--",
            iAction: appDetail?.interviewstatus?.action?appDetail?.interviewstatus?.action:"--",
            iDate: appDetail?.interviewstatus?.interviewdate?appDetail?.interviewstatus?.interviewdate:"--",
            iTime: appDetail?.interviewstatus?.interviewtime?appDetail?.interviewstatus?.interviewtime:"--",
            iStatus: appDetail?.interviewstatus?.status?appDetail?.interviewstatus?.status:"--",
            numResch: appDetail?.interviewstatus?.numrescheduled?appDetail?.interviewstatus?.numrescheduled:"--",
            history:"Click Here"
        };
    });    

    const GetSourceOwner = (appDetail)=>{
            if (appDetail?.sourcingdetails.uploadedby === "team") {
                return ({
                    sourceName: appDetail?.sourcingdetails.teamdetails.name,
                    sourceID:appDetail?.sourcingdetails.teamdetails.qhuid,
                    sourceDate:appDetail?.sourcingdetails.teamdetails.updatedon
            }) 
           }else if (appDetail?.sourcingdetails.uploadedby === "vendor"){
            return ({
                sourceName: appDetail?.sourcingdetails.vendordetails.name,
                sourceID:appDetail?.sourcingdetails.vendordetails.qhvid,
                sourceDate:appDetail?.sourcingdetails.vendordetails.updatedon
           }) 
           }else{
            return ({
                sourceName: appDetail?.sourcingdetails.portalapplication.name,
                sourceID:"--",
                sourceDate:appDetail?.sourcingdetails.portalapplication.updatedon
           }) 
           }
       }

    const sourcingData = allApplications?.map((appDetail, idx) => {
        var sOwner = GetSourceOwner(appDetail)
        console.log("Upload Details", sOwner)
        return {
            id: idx+1,
            qhid:  appDetail?.qhrid,
            name: appDetail?.applicationid.name,
            email: appDetail?.applicationid.email, 
            phone: appDetail?.applicationid.mobile,            
            uploadedBy: appDetail?.sourcingdetails?.uploadedby,
            sName:sOwner.sourceName,
            sID:sOwner.sourceID,
            dateAdded: sOwner.sourceDate,            
            sourceMedium: "Naukri",
            ownership: "To Do",
        };
    });

    const handleEditField = (bodyKey, bodyValue, id) => {
        const filteredResume = resumes.filter((res) => res.ID === id);
        updateResume(value, id, { ...filteredResume[0], [bodyKey]: bodyValue });
    };
    const handleResumeProfile= (jdId,id,rid) => {
        resetSelectedProfileResume (value,rid)
        resetSelectedApplication (value,rid)
        getSpecificApplicationByJD(value,rid)
        getProfileResumeDetails(value,rid)
        setResumeID(id)
    };

    return (

            <div className="jd-summary-wrapper">
            <div className="section-header">
                <h3 className="title">Resumes</h3>
                <Button
                    onClick={setCandidateModalOpen}
                    className="btn"
                    type="primary"
                >
                    <img
                        src={cloudIcon}
                        alt="Upload"
                        width="30" height="30"
                        className="image"
                    />
                    Upload Resume
                </Button>

            </div>
            <div className="jd-summary-buttons-wrapper">
                <Button
                    className={`jd-summary-btn ${
                        currentView === "summary" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("summary")}
                >
                    Summary
                </Button>
                <Button
                    className={`jd-summary-btn ${
                        currentView === "personal" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("personal")}
                >
                    Personal Details
                </Button>
                <Button
                    className={`jd-summary-btn ${
                        currentView === "ctc" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("ctc")}
                >
                    CTC and NP
                </Button>
                <Button
                    className={`jd-summary-btn ${
                        currentView === "skills" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("skills")}
                >
                    Skills and Resume Status
                </Button> 
                <Button
                    className={`jd-summary-btn ${
                        currentView === "sourcing" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("sourcing")}
                >
                    Sourcing
                </Button>                                                                
                <Button
                    className={`jd-summary-btn ${
                        currentView === "candidate" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("interview")}
                >
                    Interviews
                </Button>
                <Button className="jd-summary-btn"
                onClick={() => setCurrentView("resume_analysis")}>
                    Resume Analysis
                    </Button>
            </div>
            {resumes ? (
                <div className="jd-summary-wrapper">
                    {
                    currentView === "personal" ?(
                        <RTable
                        isClickable
                        titleList={personalListTable}
                        data={personalDetailsData}
                        isResumePage
                        isEditField = {false}
                        editFieldHandler={handleEditField}
                        setCurrentView={setCurrentView}
                        resumeProfileHandler = {handleResumeProfile}
                        jdID={value}
                    />
                    )
                    :currentView === "ctc" ? (
                        <RTable
                            isClickable
                            titleList={ctcListTable}
                            data={ctcDetailsData}
                            isResumePage
                            isEditField = {false}
                            editFieldHandler={handleEditField}
                            setCurrentView={setCurrentView}
                            resumeProfileHandler = {handleResumeProfile}
                        />
                    ):currentView === "skills" ? (
                        <>
                        <div className="chart-summary">
                            <PieChart stackData={null}/>
                            <PieChart stackData={null}/>
                            <PieChart stackData={null}/>
                        </div>
                          <RTableSelectable
                                isClickable
                                titleList={suitablityListTable}
                                data={suitabilityData}
                                isResumePage
                                isEditField={true}
                                editFieldHandler={handleEditField}
                                setCurrentView={setCurrentView}
                                resumeProfileHandler = {handleResumeProfile} 
                                qhjid={value}
                          />
                        </> 
                    ):currentView === "interview" ? (
                        <>
                          <InterviewTableSelectable
                                isClickable
                                titleList={interviewListTable}
                                data={interviewData}
                                isResumePage
                                isEditField={true}
                                editFieldHandler={handleEditField}
                                setCurrentView={setCurrentView}
                                resumeProfileHandler = {handleResumeProfile} 
                                qhjid={value}
                          />
                        </> 
                    ):currentView === "sourcing" ? (
                        <><PieChart stackData={null} className="chart-summary" /><RTable
                                            isClickable
                                            titleList={sourcingListTable}
                                            data={sourcingData}
                                            isResumePage
                                            isEditField={false}
                                            editFieldHandler={handleEditField}
                                            setCurrentView={setCurrentView}
                                            resumeProfileHandler = {handleResumeProfile}
                                             /></>                                                
                    ): currentView === "profile" ? (
                        <Outlet />
                    ) : currentView === "resume_analysis" ? (
                        <ResumeAnalysisNew  jdID ={value}/>                   
                    ) : (
                        <RTableSelectable
                            titleList={summaryListTable}
                            data={summaryDataNew}
                            isEditField = {true}
                            editFieldHandler={handleEditField}
                            setCurrentView={setCurrentView}
                            resumeProfileHandler = {handleResumeProfile}
                            qhjid={value}
                        />
                    )}
                    
                </div>
            ) : (
                <Empty style={{ margin: "60px" }} >
                    <h4>Upload Resumes Now</h4>
                </Empty>
            )}

            <CandidateAddModal />
         
        </div>
    );
}
