import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useJDStore from "../../../store/jd.store";
import { useLocation } from "react-router-dom";

import Table from "../../../components/Table";
import logo from "../../../assets/QuallyHireGreen.png";
import { Radio, Divider, Form, Input, message, Button, TimePicker, DatePicker,Card,Select } from "antd";
import Footer from "../../footer";

import "./jobListing.scss";

export default function JobListings(props) {
  console.log(props);

  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const { getAllJds, jdData } = useJDStore();

  const handleSignin = () => {
    navigate('/login')
  };

  const handleSignup = () => {
    navigate('/signup')
  };

  const handleJobSeekers = () => {
    navigate('/jobSeekers')
  };

  const handleCareerListing = () => {
    navigate('/jobSeekers')
  };

  const handleUpdateFormSubmit = () => {
    navigate('/jobListingSeachResults')
  };

  const handleChange =()=>{

  }

  useEffect(() => {
    getAllJds();
  }, [status]);

  const jobCagegory = [
    { value: 'category', label: 'category' },
    { value: 'it', label: 'IT and Software' },
    { value: 'vlsi', label: 'Hardware and VLSI' },
    { value: 'wireless', label: 'Wireless' },
    { value: 'embedded', label: 'Embedded'},
    { value: 'teaching', label: 'Teaching' },
    { value: 'BFSI', label: 'BFSI' },
    { value: 'mechanical', label: 'Mechanical' },
    { value: 'research', label: 'Research'}, 
    { value: 'purchasing', label: 'Purchasing ' },
    { value: 'marketing', label: 'Sales and Marketing' },
    { value: 'production', label: 'Production' },
    { value: 'medical', label: 'Medical '},           
  ];

  const titleList = [
    "Sr. No.",
    "Date Added",    
    "Client",
    "Job ID",
    "Job Title",
    "Available Resumes",
    "Screen Rejected",
    "Interviews",
    "Final Select",
    "Priority",
    "Job Status",
    "Matching Resume",
    "Assigned To",
    "SPOC",
    "Hiring Manager",
    "Date Modified",
  ];

  const jdsDetailData = jdData?.map((jds, idx) => {
    return {
      id: idx + 1,
      date_added: jds?.date_added,      
      client_name: jds?.client_name,
      jobId: jds?.ID,
      title: jds?.title,
      available_resumes: jds?.available_resumes,
      screen_rejected: jds?.screen_rejected,
      interviews: jds?.interviews,
      final_select: jds?.final_select,
      priority: jds?.priority,
      status: jds?.status,
      matching_resume: jds?.matching_resume,
      assigned_to: jds?.assigned_to,
      SPOC: jds?.SPOC,
      hiring_project_team_name: jds?.hiring_project_team_name,
      date_modified: jds?.date_modified,
    };
  });

  return (
    <div className="jobListing-wrapper">
       <div className="jobListing-header"> 
          <div className="header-logo">
              <img className="header-logo-img"src={logo} />
              <div className="header-logo-name">
                  Quallyhire AI
              </div>
          </div>
          <div className="header-menu">
            <ul className="header-menu">
              <li >
                <a href="/">Home</a>
              </li>
              <li>
                JobSeekers
              </li>
              <li>
                Support 
              </li>                                          
            </ul>
          </div>
          <div className="header-sign">
            <Button className="sign-button" onClick={() => handleSignin()}>
              Post 5 Free Jobs
            </Button>
            <Button className="job-button" onClick={() => handleJobSeekers()}>
              Employers
            </Button>                                     
          </div>          
       </div>

       <div className="jobListing-title">
         <div className="jobListing-punchLine">
            Get Your Dream Job from Quallyhire AI
         </div>
         <div className="jobListing-puncLineSmall">
            1,80,570 jobs listed here! Your dream job is waiting.
         </div>
         <div className="jobListing-search-wrapper">
            <Form
                className="jobListing-search-wrapper"
                name="jobListing-search"
                autoComplete="off"
                onFinish={handleUpdateFormSubmit}
            >
                <div className="jobListing-search">
                     <Form.Item
                        name="priority"
                    >
                        <Input
                            className="jobListing-search-item"
                            type="text"
                            required= {true}
                            defaultValue={"Keywords"}
                        />
                    </Form.Item>
                    <Form.Item
                        name="number_of_positions"
                    >
                        <Input
                            className="jobListing-search-item"
                            type="text"
                            required={ true}
                            defaultValue={"location"}
                        />
                    </Form.Item>
                    <Form.Item
                        name="category"
                    >
                        <Select
                          className="jobListing-search-item"
                          defaultValue="category"
                          onChange={handleChange}
                          options={jobCagegory}
                        />                        
                    </Form.Item>                    
                    <Form.Item
                        name="number_of_positions"
                    >
                      <Button
                        className="jobListing-search-button"
                        type="primary"
                        htmlType="submit"
                      >
                          Search Jobs
                      </Button>   
                    </Form.Item>   
                </div>                 
            </Form>
         </div>  

         <div className="features-wrapper">
            <div className="feature-heading">
              Jobs Category in Our Platform
            </div>
            <div className="feature-subheading">
              Main Categories
            </div>
            <div className="feature-card-wrapper">
        <div className="feature-card">
            <Card title="IT and Software jobs" bordered={false} extra={<a href="#">60 Jobs</a>}>
                Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div>
        <div className="feature-card">
            <Card title="Hardware and VLSI Jobs" bordered={false} extra={<a href="#">40 Jobs</a>}>
               Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div>
        <div className="feature-card">
            <Card title="Wireless Jobs" bordered={false} extra={<a href="#">20 Jobs</a>}>
               Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div>   
        <div className="feature-card">
            <Card title="Embedded Jobs" bordered={false} extra={<a href="#"> 300 Jobs</a>}>
               Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div>
        <div className="feature-card">
            <Card title="Teaching Jobs" bordered={false} extra={<a href="#"> 60 Jobs</a>}>
               Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div>
        <div className="feature-card">
            <Card title="BFSI Jobs" bordered={false} extra={<a href="#">500 Jobs</a>}>
               Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div>
        <div className="feature-card">
            <Card title="Mechanical Jobs" bordered={false} extra={<a href="#">700 Jobs</a>}>
               Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div>
        <div className="feature-card">
            <Card title="Research Jobs" bordered={false}extra={<a href="#">50 Jobs</a>}>
               Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div>
        <div className="feature-card">
            <Card title="Purchasing Jobs" bordered={false} extra={<a href="#">1 Job</a>}>
               Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div> 
        <div className="feature-card">
            <Card title="Sales and Marketing Jobs" bordered={false} extra={<a href="#">20 Jobs</a>}>
               Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div>   
        <div className="feature-card">
            <Card title="Production Jobs" bordered={false} extra={<a href="#">100 Jobs</a>}>
               Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div>   
        <div className="feature-card">
            <Card title="Medical Jobs" bordered={false} extra={<a href="#"> 0 Jobs</a>}>
               Get your info tests delivered at home collect a sample from the your pogress actual tests.
            </Card>
        </div>                                                  
        </div>                                                                       
       </div>

       </div>

       <div className="landing-footer">
          <Footer/>
       </div>         
    </div>
  );
}
