import React, { useEffect,useState } from "react";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";

import useJDStore from "../../store/jd.store";
import useStatsStore from "../../store/stats.store";
import RTable from "../RTable";
import InterviewDayScheduleCard from "../interviewDayScheduleCard";

import "./interviewDaySchedule.scss";

export default function InterviewDaySchedule({jd,sDate}) {

  const status = localStorage.getItem("status");

  const { getAllJds ,jdData} = useJDStore();
  const { jdsConsolidatedStats } = useStatsStore();

  useEffect(() => {
    getAllJds();
  }, [status]);

  const tabList = [
    {
      key: 'tab1',
      tab: 'Candidates',
    },
    {
      key: 'tab2',
      tab: 'Panels',
    },
    {
      key: 'tab3',
      tab: 'Reminders',
    },
    {
      key: 'tab4',
      tab: 'HR Contacts',
    },
  ];

  const contentList = {
    tab1: <InterviewDayScheduleCard  
             role={'candidates'}
             jd={jd}
             sDate = {sDate}
          /> ,
    tab2: <InterviewDayScheduleCard  
             role={"panel"}
             jd={jd}  
             sDate = {sDate}           
           />,
    tab3: <InterviewDayScheduleCard  
           role={"reminders"}
           jd={jd}
           sDate = {sDate}
         />,
    tab4: <InterviewDayScheduleCard  
         role={"contact"}
         jd={jd}
         sDate = {sDate}
       />                     
  };

  const [activeTabKey1, setActiveTabKey1] = useState('tab1');
  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };

  const getTitle = () => {
    //console.log("why not coming",jd);
    return  jd.client+ "- ["+ jd.qhcompanyjid+ "]   "+jd.title 
  };
  
  return (
    <div className="interviewDaySchedule-wrapper">
      <Card
        title= {getTitle()}
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={onTab1Change}
      >
        {contentList[activeTabKey1]}
      </Card>
    </div>
  );
}
