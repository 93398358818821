import React, { useEffect } from "react";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";

import useJDStore from "../../store/jd.store";
import useClientStore from "../../store/members.store";
import { useLocation } from "react-router-dom";

import Table from "../Table";

import "./jdsPriority.scss";
import TeamAssignmentCard from "../TeamAssignmentCard";

export default function JdsPriority(props) {
  console.log(props);

  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const { getAllJds, jdData } = useJDStore();
  const { clients,getClients } = useClientStore();

  useEffect(() => {
    getAllJds();
    getClients();
  }, );

  const customerList = [
    "Sr. No.",    
    "Client",
    "TA",
    "JD Todays",
    "JD Urgent",
    "JD High Priority",
    "JD Normal Priority",
    "JD Low Priority",
    "JD Open",
    "JD Hold",
    "JD Closed",
  ];
 
  const customerUIData = [];

  function  copyData () {
    var idx=0;
    for (const client of clients) {
        if(client?.ClientTAList != null){
          for(const ta of client?.ClientTAList){
            if(ta?.TotalJDsOpen >0 || ta?.TotalJDsClosed>0 || ta?.TotalJDsHold>0){
              customerUIData.push({
                id: idx+1,
                clientName: client?.ClientName,
                taName: ta?.TAName,
                tpr: ta?.TotalJDsT,              
                upr: ta?.TotalJDsU,              
                hpr: ta?.TotalJDsH,
                npr: ta?.TotalJDsN,              
                lpr: ta?.TotalJDsL,
                jdsopen: ta?.TotalJDsOpen,
                jdsclosed: ta?.TotalJDsClosed,
                jdshold: ta?.TotalJDsHold
              });
            }
      }
    }
  }
}

  return (
    <div className="jds-wrapper">
      {copyData()}
      {jdData?.length > 0 ? (
           <Table
            isClickable={false}
            isJDPage={false}
            titleList={customerList}
            data={customerUIData}
          />             
      ) : (
        <Empty style={{ margin: "60px" }} />
      )}
    </div>
  );
}
