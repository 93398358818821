import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useJDStore from "../../../store/jd.store";
import { useLocation } from "react-router-dom";

import Table from "../../../components/Table";
import logo from "../../../assets/QuallyHireGreen.png";
import { Radio, Divider, Form, Input, message, Button, TimePicker, DatePicker,Card } from "antd";
import Footer from "../../footer";

import "./jobListingSearchResultFilter.scss";
import { Dropdown } from "react-bootstrap";


export default function JobListingSearchResultFilter(props) {
  console.log(props);

  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const { getAllJds, jdData } = useJDStore();

  const handleSignin = () => {
    navigate('/login')
  };

  const handleSignup = () => {
    navigate('/signup')
  };

  const handleJobSeekers = () => {
    navigate('/jobSeekers')
  };

  const handleCareerListing = () => {
    navigate('/jobSeekers')
  };

  const handleUpdateFormSubmit = () => {
    navigate('/jobSeekers')
  };

  useEffect(() => {
    getAllJds();
  }, [status]);


  return (
    <div className="searchResultsFilter-wrapper">
        Filters
        <Form
                className="searchResultsFilter-wrapper"
                name="jobListing-search"
                autoComplete="off"
                onFinish={handleUpdateFormSubmit}
                layout="vertical"
            >
                    <Form.Item
                        className="searchResultsFilter-field-item"
                        label="Experience "
                        name="experience"
                    >
                        <Input
                            type="text"
                            className="searchResultsFilter-input"
                        />
                        to
                        <Input
                            type="text"
                            className="searchResultsFilter-input"
                        />
                        Yrs
                    </Form.Item>                    
                    <Form.Item
                        name="offered_CTC"
                        className="searchResultsFilter-field-item"
                        label="Salary Range"
                    >
                        <Input
                            type="text"
                            className="searchResultsFilter-input"
                        />
                        to
                        <Input
                            type="text"
                            className="searchResultsFilter-input"
                        /> 
                        LPA                       
                    </Form.Item>
                    <Form.Item

                    className="searchResultsFilter-field-item"
                    name="hiring_for"
                    label="Posted In Last"
                >
                    <Radio.Group
                        className="searchResultsFilter-radio-wrapper"
                        onClick
                    >
                        <Radio
                            className="radio-option"
                            value="internal-project"
                        >
                            1 day
                        </Radio>
                        <Radio
                            className="radio-option"
                            value="customer-project"
                        >
                            7 days
                        </Radio>
                        <Radio
                            className="radio-option"
                            value="customer-project"
                        >
                            15 days
                        </Radio>
                        <Radio
                            className="radio-option"
                            value="customer-project"
                        >
                            30 days
                        </Radio>
                        <Radio
                            className="radio-option"
                            value="customer-project"
                        >
                            Older
                        </Radio>
                    </Radio.Group>
                    </Form.Item>                     
                    <Form.Item
                    
                    className="searchResultsFilter-field-item"
                    name="hiring_for"
                    label="Notice Period"
                >
                    <Radio.Group
                        className="searchResultsFilter-radio-wrapper"
                        onClick
                    >
                        <Radio
                            className="radio-option"
                            value="internal-project"
                        >
                            Immediate
                        </Radio>
                        <Radio
                            className="radio-option"
                            value="customer-project"
                        >
                            15 days
                        </Radio>
                        <Radio
                            className="radio-option"
                            value="customer-project"
                        >
                            30 days
                        </Radio>
                        <Radio
                            className="radio-option"
                            value="customer-project"
                        >
                            60 days
                        </Radio>
                        <Radio
                            className="radio-option"
                            value="customer-project"
                        >
                            90 days
                        </Radio>
                    </Radio.Group>
                    </Form.Item>                    
                    <Form.Item
                        name="number_of_positions"
                    >
                      <Button
                        className="jobListing-search-button"
                        type="primary"
                        htmlType="submit"
                      >
                          Apply Filter
                      </Button>   
                    </Form.Item>             
        </Form>
    </div>     
  );
}
