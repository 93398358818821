import React, { useState, useEffect } from "react";
import { Button, Empty } from "antd";

import TableSelectable from "../TableSelectable";
import Table from "../Table";
import SubUserAddModal from "../SubUserAddModal";

import useMembersStore from "../../store/members.store";
import useSubUserStore from "../../store/subUserModal.store";

import "./settings.scss";

const currentTeamTitles = [
  "Select",
  "S. No.",
  "Name",
  "Mobile No.",
  "Email ID",
  "Role",
  "Active Since",
  "Permission",
];

const vendorTitles = [
    "S. No.",
    "Name",
    "Mobile No.",
    "Email ID",
    "Address",
    "City",
    "Country",
    "Vendor Alias",
    "Vendor Rate",
    "Referred By",
    "Vendor Status",
    "Onboarding Date",
    "Credentials",
    "Action",
];

function Settings() {
    const [currentView, setCurrentView] = useState("team");

    const { getTeamMembers, getVendors, vendors, teamMembers } =
        useMembersStore();

    const { setModalOpen, setCurrentMemberType, removeMember } =
        useSubUserStore();

    useEffect(() => {
        getTeamMembers();
        getVendors();
    }, []);

    const teamMembersList =
        teamMembers && teamMembers.length > 0
            ? teamMembers.map((member, idx) => ({
	          select: "",
                  id: idx + 1,
                  username: member?.username,
                  contact: member?.contact,
                  email: member?.email,
                  designation: member?.designation,
                  onboardingDate: "To Do",
                  permission: member?.permission,
                  memberID: member?.ID,
        }))
      : [];

    const vendorsList =
        vendors && vendors.length > 0
            ? vendors.map((member, idx) => ({
                  id: idx + 1,
                  username: member?.username,
                  contact: member?.contact,
                  email: member?.email,
                  address: member?.address,
                  vendor_alias: member?.vendor_alias,
                  vendor_rate: member?.vendor_rate,
                  referred_by: member?.referred_by,
                  vendor_status: member?.vendor_status,
                  date_added: member?.date_added,
              }))
            : [];

    const handleAddTeamClick = () => {
        setModalOpen();
        setCurrentMemberType("team");
    };
    const handleAddVendorClick = () => {
        setModalOpen();
        setCurrentMemberType("vendor");
    };

    return (
        <div className="settings-container">
            <div className="settings-btns-wrapper">
                <Button
                    onClick={() => setCurrentView("team")}
                    className={`setting-btn ${
                        currentView === "team" && "setting-btn-active"
                    }`}
                >
          Team
                </Button>
                <Button
                    onClick={() => setCurrentView("vendor")}
                    className={`setting-btn ${
                        currentView === "vendor" && "setting-btn-active"
                    }`}
                >
          Vendors
                </Button>
            </div>
            {currentView === "team" ? (
                <div className="members-wrapper">
                    <div style={{display:"flex"}}>
                    <h2 className="members-list-title">Current Users</h2>
                    <div className="members-add-wrapper">
                        <Button
                            onClick={handleAddTeamClick}
                            type="primary"
                            className="members-add-btn"
                        >
                            + Add User
                        </Button>
                    </div>
                    </div>
                    {teamMembers?.length > 0 ? (
            <TableSelectable
                            titleList={currentTeamTitles}
                            data={teamMembersList}
                        />
                    ) : (
                        <Empty style={{ margin: "60px" }} />
                    )}

                  
                </div>
            ) : (
                <div className="members-wrapper">
                    <div style={{display:"flex"}}>
                    <h2 className="members-list-title">Current Vendors</h2>
                    <div className="members-add-wrapper">
                        <Button
                            onClick={handleAddVendorClick}
                            type="primary"
                            className="members-add-btn"
                        >
                            + Add Vendor
                        </Button>
                    </div>
                    </div>
                    {vendors?.length > 0 ? (
                        <Table titleList={vendorTitles} data={vendorsList} />
                    ) : (
                        <Empty style={{ margin: "60px" }} />
                    )}

                  
                </div>
            )}
            <SubUserAddModal />
        </div>
    );
}

export default Settings;
