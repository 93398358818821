import { Doughnut, Pie , Bar} from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend ,
  CategoryScale,
  LinearScale,
  BarElement,
  Title} from 'chart.js';
import "./stackBarChart.scss";


ChartJS.register(  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,ArcElement);

 /* 
const labels = ['Geetha', 'Shivani', 'Anusha', 'Amruta', 'Aniket', 'Arpitha', 'Vinay'];
export const stackBarChartResumes = {
  labels,
  datasets: [
    {
      label: 'Total Uploaded',
      data: labels.map(() => 20),
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: 'Available',
      data: labels.map(() => 30),
      backgroundColor: 'rgb(75, 192, 192)',
    },
    {
      label: 'Shared',
      data: labels.map(() => 40),
      backgroundColor: 'rgb(53, 162, 235)',
    },
    {
      label: 'NP/B/JD/CB',
      data: labels.map(() => 40),
      backgroundColor: 'rgb(53, 162, 235)',
    },
  ],
};    
*/
 
function StackBarChart( {stackBarData, stack, showTitle} ) {
  return (
    <div >
        <Bar
          data={stackBarData}
          options={{
            plugins: {
              title: {
                display: true,
                text: showTitle
              },
            },
            responsive: true,
            scales:{
              x: {
                stacked: stack,                            
              },
              y: {
                stacked: stack,           
              }
            }
          }}
        />
      </div>
  );
}
export default StackBarChart;