import React from "react";
import { Modal, Form, Input, Button, Radio, Dropdown ,Space, Select} from "antd";

import useSubUserStore from "../../store/subUserModal.store";

import "./subUserAddModal.scss";

export default function SubUserAddModal() {
    const { isModalOpen, handleModalCancel, handleUserAdd, currentMemberType } = useSubUserStore();
    const [form] = Form.useForm();

    console.log("currentMemberType", currentMemberType)

    const handleFormSubmit = (value) => {
        handleUserAdd(value);
        handleModalCancel();
        form.resetFields();
    };

    return (
        <Modal
            title= {currentMemberType == "team" ? "Provide New User Details": "Provide New Vendor Details"}
            visible={isModalOpen}
            footer={null}
            onCancel={handleModalCancel}
            className="subUserAddModel-title"
        >
            {currentMemberType === "team" ? (
                <Form
                    labelCol={{ flex: "150px" }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                    onFinish={handleFormSubmit}
                    form={form}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add User's Name",
                            },
                        ]}
                        label="Name"
                        name="username"
                    >
                        <Input   className="subUserAddModel-input"/>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                maxLength: 10,
                                message: "Please Add Contact Details",
                            },
                        ]}
                        label="Mobile No."
                        name="contact"
                    >
                        <Input   className="subUserAddModel-input"/>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Email",
                            },
                        ]}
                        label="Email ID"
                        name="email"
                    >
                    <Input className="subUserAddModel-input" type="email" />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Role",
                            },
                        ]}
                        label="Role"
                        name="designation"
                    >
                    <Select                          
                            defaultValue="Select Role"
                            className="subUserAddModel-input"
                            options={[
                                { value: 'Select Role', label: 'Please select Role' },
                                { value: 'Trainee Recruiter', label: 'Trainee Recruiter' },
                                { value: 'Recruiter', label: 'Recruiter' },
                                { value: 'Lead Recruiter', label: 'Lead Recruiter' },
                                { value: 'Manager', label: 'Manager'},
                                { value: 'Group Manager', label: 'Group Manager' },
                                { value: 'COO', label: 'COO' },
                                { value: 'CHRO', label: 'CHRO' },
                                { value: 'CEO', label: 'CEO'}, 
                                { value: 'OTHERS', label: 'Others' },
                            ]}
                    />                    
                    </Form.Item>                    
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Select Admin Rights",
                            },
                        ]}
                        label="Permission"
                        name="permission"
                    >
                        <Radio.Group className="subUserAddModel-input">
                            <Radio value={'super_admin'}>Super Admin</Radio>
                            <Radio value={'admin'}>Admin</Radio>
                            <Radio value={'user'}>User</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Button htmlType="submit" type="primary">
                        Submit
                    </Button>
                </Form>
            ) : (
                <Form
                    labelCol={{ flex: "150px" }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                    onFinish={handleFormSubmit}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Name",
                            },
                        ]}
                        label="Name"
                        name="name"
                    >
                        <Input type="text" className="subUserAddModel-input" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Email",
                            },
                        ]}
                        label="Email ID"
                        name="email"
                    >
                        <Input type="email" className="subUserAddModel-input" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Number",
                            },
                        ]}
                        label="Phone Number"
                        name="number"
                    >
                        <Input type="text" className="subUserAddModel-input" />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Address",
                            },
                        ]}
                        label="Address"
                        name="address"
                    >
                        
                        <Input type= "text" className="subUserAddModel-input" />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add City",
                            },
                        ]}
                        label="City"
                        name="city"
                    >
                        
                        <Input type= "text" className="subUserAddModel-input" />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Country",
                            },
                        ]}
                        label="Country"
                        name="country"
                    >
                        <Input type= "text" className="subUserAddModel-input" />
                    </Form.Item>
 
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Vendor Alias",
                            },
                        ]}
                        label="Vendor alias"
                        name="alias"
                    >
                        <Input type="text" className="subUserAddModel-input" />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Vendor Rate",
                            },
                        ]}
                        label="Vendor Rate"
                        name="vendorRate"
                    >
                        <Input type="text" className="subUserAddModel-input" />
                    </Form.Item>                    
 
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Vendor ReferredBy Whom",
                            },
                        ]}
                        label="Vendor Referred By"
                        name="vendorReferredBy"
                    >
                        <Input type="text" className="subUserAddModel-input" />
                    </Form.Item>   
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Password",
                            },
                        ]}
                        label="Password"
                        name="password"
                    >
                        <Input type="password" className="subUserAddModel-input" />
                    </Form.Item>

                    <Button htmlType="submit" type="primary">
                        Submit
                    </Button>
                </Form>
            )}
        </Modal>
    );
}
