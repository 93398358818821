import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { Radio, Divider, Form, Input, message, Button, TimePicker, DatePicker,Select,Space } from "antd";
import moment from 'moment'
import TextArea from "antd/es/input/TextArea";
import RTable from "../RTable";
import CandidateAddModal from "../CandidateAddModal";

import useCandidateStore from "../../store/candidateModal.store";
import useResumeStore from "../../store/resume.store";
import useJDStore from "../../store/jd.store";

import "./candidateProfile.scss";
import cloudIcon from "../../assets/upload.png";
import downloadIcon from "../../assets/download.png";
import profileIcon from "../../assets/profile.png";

import editIcon from "../../assets/pencil.png";
import StackChart from "../StackChart";

export default function CandidateProfile() {

   
    const [currentView, setCurrentView] = useState("profile");
    const [isEditMode, setIsEditMode] = useState(false);
    const { jdID,id, resumeID } = useParams();

    const [formKey, setFormKey] = useState(1);
    const [isRequired, setIsRequired] = useState(true);

    const { setCandidateModalOpen } = useCandidateStore();
    
    const { createJd, updateJd, selectedJD, clearJD } = useJDStore();
    const { resumes, getResumesByJD, selectedProfileResume, downloadResumeByID, getSpecificApplicationByJD,
            updateProfileInApplication,updateProfileResumeDetails,getProfileResumeDetails,selectedApplication} =
        useResumeStore();

    var value = jdID;
    const [currentApplication, setCurrentApplication] = useState(selectedApplication);

    useEffect(() => {
        console.log("Selected Profile 4444", selectedProfileResume)
        setCurrentApplication(selectedApplication)
    }, [selectedApplication]);

    const handleDownloadResume = (resumeID) => {
        downloadResumeByID(jdID, resumeID);
    };

    function getTimeNow(){
        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today. getDate();
        const currentDate = date + "-" + month + "-" + year;
        return currentDate
    }

    const encodeApplicationJson = (values) =>{

        let encodedJsonValues ={};

        console.log("Application Data to encode", values)
        //FSM to maintain the JD state
        encodedJsonValues.applicationstatus = values.applicationstatus

        //get company details
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        console.log("User data", userData)
        encodedJsonValues.qhoid= userData.qhoid;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhrid= resumeID;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhcompanyjid= jdID
        encodedJsonValues.version= "1.0"
        encodedJsonValues._id ="Not Needed Only Dummy"

        //now fill the parameters
        encodedJsonValues.applicationid ={};
        encodedJsonValues.applicationid.name=values.name;
        encodedJsonValues.applicationid.email=values.email;
        encodedJsonValues.applicationid.mobile=values.mobile;
        encodedJsonValues.applicationid.aadharid=values.aadharid;


        encodedJsonValues.newemploymentdetails ={};
        encodedJsonValues.newemploymentdetails.preferredlocation=values.preferredlocation;
        encodedJsonValues.newemploymentdetails.locationchangereason=values.locationchangereason;
        encodedJsonValues.newemploymentdetails.ectc={}
        encodedJsonValues.newemploymentdetails.ectc.totalctc= values.ectc;    
        encodedJsonValues.newemploymentdetails.payroll = values.payroll  ;  

        let newDate = getTimeNow()

        encodedJsonValues.sourcingdetails ={};
        encodedJsonValues.sourcingdetails.uploadedby= "TEAM"; //TEAM,VENDOR<PORTAL
        encodedJsonValues.sourcingdetails.teamdetails={}
        encodedJsonValues.sourcingdetails.teamdetails.qhuid= userData.qhuid;
        encodedJsonValues.sourcingdetails.teamdetails.name=userData.username; 
        encodedJsonValues.sourcingdetails.teamdetails.updatedon= newDate; 
        encodedJsonValues.sourcingdetails.resumeownership=userData.organization; 

        //encodedJsonValues.resumestatus ={};
        //encodedJsonValues.resumestatus.resumestatus= values.skills;
        //encodedJsonValues.resumestatus.resumesuitability= values.roles_and_responsibility;
        //encodedJsonValues.resumestatus.tags= values.skills;
        //encodedJsonValues.resumestatus.updatedby={}
        //encodedJsonValues.resumestatus.updatedby.updatedon= newDate;
        //encodedJsonValues.resumestatus.updatedby.qhuid= values.education;

        encodedJsonValues.candidatestatus ={};
        encodedJsonValues.candidatestatus.candidatestatus= values.candidatestatus;
        encodedJsonValues.candidatestatus.updatedby={}
        encodedJsonValues.candidatestatus.updatedby.updatedon= newDate;
        encodedJsonValues.candidatestatus.updatedby.qhuid= userData.qhuid;

        encodedJsonValues.interviewstatus =null;
        encodedJsonValues.selectionstatus =null;        
        encodedJsonValues.skillsevalsatus =null;
        encodedJsonValues.testevalstatus =null;
        
        
        return encodedJsonValues
    }

    function getTimeNow(){
        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today. getDate();
        const currentDate = date + "-" + month + "-" + year;
        return currentDate
    }

    const encodeResumeDetailsJson = (values) =>{

        let encodedJsonValues ={};

        console.log("Resune Data to encode", values)

        //get company details
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        encodedJsonValues.qhoid= userData.qhoid;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhrid= resumeID;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhcompanyjid= jdID
        encodedJsonValues._id ="Not Needed Only Dummy"

        //now fill the parameters
        encodedJsonValues.employmentdetails ={};        
        encodedJsonValues.employmentdetails.currentcompany = values.currentcompany;
        encodedJsonValues.employmentdetails.designation = values.designation;
        encodedJsonValues.employmentdetails.currentlocation = values.currentlocaton;
        encodedJsonValues.employmentdetails.currentctc = {};
        encodedJsonValues.employmentdetails.currentctc.totalctc = values.currentctc;
        encodedJsonValues.employmentdetails.noticeperiod = {};
        encodedJsonValues.employmentdetails.noticeperiod.officialnp = values.officialnp; 
        encodedJsonValues.employmentdetails.noticeperiod.isnegotiable = values.isnegotiable;  
        encodedJsonValues.employmentdetails.noticeperiod.negotiableto = values.negotiableto;  
        encodedJsonValues.employmentdetails.noticeperiod.lwd = values.lwd;  
        encodedJsonValues.employmentdetails.noticeperiod.reason = values.reason;  

        encodedJsonValues.educationaldetail ={}; 
        encodedJsonValues.educationaldetail.maxdegree=values.maxdegree;
        encodedJsonValues.educationaldetail.college=values.college;
        encodedJsonValues.educationaldetail.stream=values.stream;;
        encodedJsonValues.educationaldetail.percentage="To DO";
        encodedJsonValues.educationaldetail.passoutyear= "To DO";
        encodedJsonValues.educationaldetail.eductionremarks= "To DO"            

        let newDate = getTimeNow()

        encodedJsonValues.personaldetail ={};
        encodedJsonValues.personaldetail.name= values.name;
        encodedJsonValues.personaldetail.email= values.email;
        encodedJsonValues.personaldetail.mobile= values.mobile;
        encodedJsonValues.personaldetail.aadharid= values.aadharid;
        encodedJsonValues.personaldetail.dob= values.dob;
        encodedJsonValues.personaldetail.gender= values.gender;  
        encodedJsonValues.personaldetail.age= values.age;      
        encodedJsonValues.personaldetail.martialstatus= values.martialstatus;    

        encodedJsonValues.offerdetails={}
        encodedJsonValues.offerdetails.otheroffer = values.otheroffer;
        encodedJsonValues.offerdetails.offerctc = values.ctc;
        encodedJsonValues.offerdetails.offerdoj = values.doj;
        encodedJsonValues.offerdetails.companytype = values.offercompanytype;
        encodedJsonValues.offerdetails.companyname = "ToDO";
       
        return encodedJsonValues
    }


    const handleFormSubmit = (values) => {
        const jasonValues = encodeApplicationJson(values)
        const resumeJasonValues = encodeResumeDetailsJson(values)
        console.log("handle jdid id", jdID, resumeID)
        updateProfileInApplication({
            jasonValues,jdID,resumeID
        });
        updateProfileResumeDetails({
            resumeJasonValues,jdID,resumeID
        });
        message.success("Updated Profile Detials");
    };    

  const handleUpdateFormSubmit = (values) => {
      console.log("called")
      message.success("Job description updated successfully");
      setCurrentView("assignment");
      setIsEditMode(false);
  };

  const appvalues ={
    name: selectedProfileResume?.personaldetail.name,
    email: selectedProfileResume?.personaldetail.email,
    mobile: selectedProfileResume?.personaldetail.mobile,
    score: selectedApplication?.resumescore.score,
    skills: selectedApplication?.resumestatus.tags,
    payroll: selectedApplication?.newemploymentdetails.payroll,
    candidatestatus: selectedApplication?.candidatestatus.status,
    appliedbefore: selectedApplication?.candidatestatus.status,
    interviewbefore: selectedApplication?.candidatestatus.status,
    currentlocation: selectedProfileResume?.employmentdetails.currentlocation,
    preferredlocation: selectedProfileResume?.personaldetail.name,
    locationchangereason: selectedProfileResume?.personaldetail.email,
    maxdegree: selectedProfileResume?.personaldetail.mobile,
    stream: selectedProfileResume?.personaldetail.email,
    college: selectedProfileResume?.personaldetail.mobile,
    designation: selectedProfileResume?.employmentdetails.currentcompany,
    experience: selectedProfileResume?.personaldetail.email,
    relevantexperience: selectedApplication?.candidatestatus.status,
    currentcompany: selectedProfileResume?.employmentdetails.currentcompany,
    noticeperiod: selectedProfileResume?.employmentdetails.noticeperiod,
    negotiablenoticeperiod: selectedProfileResume?.personaldetail.mobile,
    relevantexperience: selectedProfileResume?.personaldetail.mobile,
    lwd: selectedProfileResume?.personaldetail.mobile,
    reason: selectedProfileResume?.personaldetail.mobile,
    currentctc: selectedProfileResume?.employmentdetails.currentctc,
    ectc: selectedProfileResume?.personaldetail.mobile,
    ectcoptions: selectedProfileResume?.personaldetail.mobile,
    ctcremarks: selectedProfileResume?.personaldetail.mobile,
    otheroffer: selectedProfileResume?.personaldetail.mobile,
    offercompanytype: selectedProfileResume?.personaldetail.mobile,   
    offerctc: selectedProfileResume?.personaldetail.mobile,
    aadharnumber: selectedProfileResume?.personaldetail.mobile,
    offerremarks: selectedProfileResume?.personaldetail.mobile 
  }

    return (
            <div className="profile-summary-wrapper">
            <div className="profile-stackChart-wrapper">
                   Candidate Profile - {resumeID}
                   <br></br>
                <StackChart 
                    stackData={null} 
                    stack = {false} 
                    showLegend
                />
            </div>
            <div className="profile-creation-form-wrapper">
            <div className="header">
                {isEditMode ?<h3 className="jd-creation-form-suitability"> Resume Suitablity : {appvalues.score > 50?appvalues.score + "%":"Not Suitable"} </h3>  :
                              <h3 className="jd-creation-form-suitability"> Resume Suitablity : {appvalues.score > 50?appvalues.score +"%" :"Not Suitable"}  </h3>  }  
                <h5 className="jd-creation-form-title"> {appvalues.name} </h5>                                                                      
                {!isEditMode && (
                    <Button className="profile-creation-edit-button" onClick={() => setIsEditMode(true)} type="primary">
                        <img
                        src={editIcon}
                        alt="Edit"
                        width="14" height="14" 
                        />
                    </Button>
                )}
            </div>
            {currentApplication == null? <></>:
                <Form
                key={formKey}
                className="profile-creation-form"
                name="profile-creation-form"
                justify="start"
                layout="vertical"
                autoComplete="off"
                onFinish={value ? handleUpdateFormSubmit : handleFormSubmit}
                initialValues={appvalues}
            >
                <div className="triple-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Candidate Name"
                        name="name"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>

                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Email ID"
                        name="email"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                            required={value ? false : true}
                            name="mobile"
                            className="jd-creation-field-item"
                            label="Mobile Number"
                        >
                            <Input
                                disabled={!isEditMode}
                                required={value ? false : true}
                                className="jd-creation-input"
                            />
                    </Form.Item>
                </div>

                <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Tagged Skills"
                        name="skills"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                </Form.Item>

                <Form.Item
                        required={isRequired}
                        name="payroll"
                        className="jd-creation-field-item"
                        label="Payroll interested in"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper-3"
                        >
                            <Radio className="radio-option" value={"Our"}>
                                Our
                            </Radio>                           
                            <Radio className="radio-option" value={"Client"}>
                                Client
                            </Radio>
                            <Radio className="radio-option" value={"End Client"}>
                                End Client
                            </Radio>                           
                        </Radio.Group>
                </Form.Item>

                <div className="triple-input-wrapper">

                    <Form.Item
                        required={value ? false : true}
                        name="candidatestatus"
                        className="jd-creation-field-item"
                        label="Candidate Status"
                    >
                            <Select
                                disabled={!isEditMode}
                                placeholder="To Be Called"                                
                                required={value ? false : true}
                                className="jd-creation-input"                            
                                options={[
                                    { value: 'Available', label: 'Available' },
                                    { value: 'Not Picking', label: 'Not Picking' },
                                    { value: 'Busy', label: 'Busy' },
                                    { value: 'Call Later', label: 'Call Later' },
                                    { value: 'Not Interested', label: 'Not Interested'},
                                    { value: 'Location Issue', label: 'Location Issue' },
                                    { value: 'JD Needed', label: 'JD Needed' },
                                    { value: 'Disconnected', label: 'Disconnected' },
                                    { value: 'OOS', label: 'OOS' },
                                    { value: 'Already Got Call', label: 'Already Got Call'}, 
                                    { value: 'Already Given Interview in last 6 Months', label: 'Already Given Interview in last 6 Months ' },
                                    { value: 'Already Given Interview before 6 months', label: 'Already Given Interview before 6 months' },
                                    { value: 'To Be Called', label: 'To Be Called' }, 
                                ]}
                            />                         
                    </Form.Item>
                    <Form.Item
                        required={isRequired}
                        name="appliedbefore"
                        className="jd-creation-field-item"
                        label="Have you applied before for this role"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper"
                        >
                            <Radio className="radio-option" value={true}>
                                Yes
                            </Radio>
                            <Radio className="radio-option" value={false}>
                                No
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        required={isRequired}
                        name="interviewbefore"
                        className="jd-creation-field-item"
                        label="Have you given interview before"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper"
                        >
                            <Radio className="radio-option" value={true}>
                                Yes
                            </Radio>
                            <Radio className="radio-option" value={false}>
                                No
                            </Radio>
                        </Radio.Group>
                    </Form.Item>                                                        
                </div>

                <div className="triple-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        name="currentlocation"
                        className="jd-creation-field-item"
                        label="Current Location"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item> 

                    <Form.Item
                        required={value ? false : true}
                        name="preferredlocation"
                        className="jd-creation-field-item"
                        label="Preferred Locations"
                    >
                            <Select
                                disabled={!isEditMode}
                                mode="multiple"
                                allowClear
                                placeholder="Please select"                                
                                required={value ? false : true}
                                className="jd-creation-input"                            
                                options={[
                                    { value: 'Bengaluru', label: 'Bengaluru' },
                                    { value: 'Chennai', label: 'Chennai' },
                                    { value: 'Hyderabad', label: 'Hyderabad' },
                                    { value: 'Gurgaon', label: 'Gurgaon' },
                                    { value: 'Noida', label: 'Noida'},
                                    { value: 'Delhi', label: 'Delhi' },
                                    { value: 'Mumbai', label: 'Mumbai' },
                                    { value: 'Pune', label: 'Pune' },
                                    { value: 'Kolkata', label: 'Kolkata' },
                                    { value: 'Nagpur', label: 'Nagpur'}, 
                                    { value: 'Remote', label: 'Remote ' },
                                    { value: 'Any', label: 'Any' },
                                    { value: 'Abroad', label: 'Abroad' }, 
                                ]}
                            />                         
                    </Form.Item>

                    <Form.Item
                        required={value ? false : true}
                        name="locationchangereason"
                        className="jd-creation-field-item"
                        label="Relocation Reason"
                    >
                            <Select
                                disabled={!isEditMode}
                                placeholder="Please Select"                                
                                required={value ? false : true}
                                className="jd-creation-input"                            
                                options={[
                                    { value: 'NA', label: 'NA' },
                                    { value: 'Partner Job', label: 'Partner Job' },
                                    { value: 'Family Member Lives there', label: 'Family Member Lives there' },
                                    { value: 'Carrer Growth', label: 'Carrer Growth' },
                                    { value: 'Other Reasons', label: 'Other Reasons'},
                                ]}
                            />                         
                    </Form.Item>
                                     
                </div>
                <div className="triple-input-wrapper">
                    <Form.Item
                            required={value ? false : true}
                            name="maxdegree"
                            className="jd-creation-field-item"
                            label="Max Degree"
                        >
                            <Select
                                disabled={!isEditMode}
                                placeholder="Please select"                                
                                required={value ? false : true}
                                className="jd-creation-input"                            
                                options={[
                                    { value: 'BE', label: 'BE' },
                                    { value: 'BTech', label: 'BTech' },
                                    { value: 'ME', label: 'ME' },
                                    { value: 'MTech', label: 'MTech' },
                                    { value: 'PhD', label: 'PhD'},
                                    { value: 'BCA', label: 'BCA'},
                                    { value: 'MCA', label: 'MCA'},
                                    { value: 'Diploma', label: 'Diploma'},
                                    { value: 'BCom', label: 'BCom'},
                                    { value: 'MCom', label: 'MCom'},
                                    { value: 'BEd', label: 'BEd'},
                                    { value: 'MEd', label: 'MEd'},
                                    { value: 'BSc', label: 'BSc'},
                                    { value: 'MSc', label: 'MSc'},
                                    { value: 'MBBS', label: 'MBBS'},
                                    { value: 'MD/MS', label: 'MD/MS'},
                                    { value: 'MS (Abroad)', label: 'MS (Abroad)'},
                                    { value: 'BS (Abroad)', label: 'BS (Abroad)'},
                                    { value: 'PhD (Abroad)', label: 'PhD (Abroad)'},                                                                        
                                ]}
                            /> 
                    </Form.Item> 
                    <Form.Item
                            required={value ? false : true}
                            name="stream"
                            className="jd-creation-field-item"
                            label="Stream"
                        >
                            <Input
                                disabled={!isEditMode}
                                required={value ? false : true}
                                className="jd-creation-input"
                            />
                    </Form.Item>                     

                    <Form.Item
                            required={value ? false : true}
                            name="college"
                            className="jd-creation-field-item"
                            label="Uni/College"
                        >
                            <Select
                                disabled={!isEditMode}
                                placeholder="Please select"                                
                                required={value ? false : true}
                                className="jd-creation-input"                            
                                options={[
                                    { value: 'IITs', label: 'IITs' },
                                    { value: 'IIMs', label: 'IIMs' },
                                    { value: 'RECs', label: 'RECs' },
                                    { value: 'AIIMS', label: 'AIIMS' },
                                    { value: 'Others', label: 'Others'},
                                    { value: 'Abroad', label: 'Abroad'},
                                ]}
                            />  
                    </Form.Item>                                       
                </div>

                <div className="triple-input-wrapper">

                    <Form.Item
                        required={value ? false : true}
                        name="designation"
                        className="jd-creation-field-item"
                        label="Current Designation"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>

                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Total Exp "
                        name="experience"
                    >
                    <Space.Compact>
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            defaultValue={"Yrs"}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            defaultValue={"Months"}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                     </Space.Compact>  
                    </Form.Item>  

                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Relevant Exp "
                        name="relevantexperience"
                    >
                        <Space.Compact>
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            defaultValue={"Yrs"}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            defaultValue={"Months"}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                     </Space.Compact>  
                    </Form.Item>                     
                </div>
                           
                <div className="triple-input-wrapper">                  

                   <Form.Item
                        required={value ? false : true}
                        name="currentcompany"
                        className="jd-creation-field-item"
                        label="Current Company"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Official Notice Period (Days)"
                        name="noticeperiod"
                    >
                    <Select
                            disabled={!isEditMode}
                            placeholder="Please select"                                
                            required={value ? false : true}
                            className="jd-creation-input"                            
                            options={[
                                { value: 0, label: 0 },                                
                                { value: 15, label: 15 },
                                { value: 30, label: 30 },
                                { value: 45, label: 45 },
                                { value: 60, label: 60 },
                                { value: 90, label: 90},
                                { value: 120, label: 120 },
                                { value: 180, label: 180 },
                            ]}
                    />                          
                    </Form.Item>
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Negotiable Notice Period (Days)"
                        name="negotiablenoticeperiod"
                    >
                    <Select
                            disabled={!isEditMode}
                            placeholder="Please select"                                
                            required={value ? false : true}
                            className="jd-creation-input"                            
                            options={[
                                { value: 0, label: 0 },
                                { value: 5, label: 5 },
                                { value: 10, label: 10 },
                                { value: 15, label: 15 },
                                { value: 30, label: 30 },
                                { value: 45, label: 45 },
                                { value: 60, label: 60 },
                                { value: 90, label: 90},
                                { value: 120, label: 120 },
                                { value: 180, label: 180 },
                            ]}
                    /> 
                    </Form.Item>                    
                </div>

                <div className="triple-input-wrapper">
                    <Form.Item
                        className="jd-creation-field-item"
                        label="LWD"
                        name="lwd"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            defaultValue={"dd/mm/yyyy"}
                            className="jd-creation-input"
                        />

                    </Form.Item>                    
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Reason for Leaving "
                        name="reason"
                    >
                    <Select
                            disabled={!isEditMode}
                            placeholder="Please select"                                
                            required={value ? false : true}
                            className="jd-creation-input"                            
                            options={[
                                { value: "No Project,On Bench", label: "No Project,On Bench"},                                
                                { value: "LayOff", label: "LayOff" },
                                { value: "Financial Growth", label: "Financial Growth" },
                                { value: "No JobSatisfaction", label: "No JobSatisfaction" },
                                { value: "Poor Work Environment", label: "Poor Work Environment" },
                                { value: "Persoanl Reason", label: "Persoanl Reason"},
                                { value: "To Take care of family", label: "To Take care of family" },
                                { value: "Othe reasons" ,label: "Othe reasons" },
                            ]}
                    />                          
                    </Form.Item> 
                    <Form.Item
                        required={value ? false : true}
                        name="currentctc"
                        className="jd-creation-field-item"
                        label="Current CTC (LPA)"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />                       
                    </Form.Item>                                                  
                </div>                
                <div className="triple-input-wrapper">
                   <Form.Item
                        required={value ? false : true}
                        name="ectc"
                        className="jd-creation-field-item"
                        label="ECTC (LPA)"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />                       
                    </Form.Item>                                                        
                    <Form.Item
                        required={isRequired}
                        name="ectcoptions"
                        className="jd-creation-field-item"
                        label="ECTC Options"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper-3"
                        >
                            <Radio className="radio-option" value={"Negotiable"}>
                                Negotiable
                            </Radio>
                            <Radio className="radio-option" value={"Fixed"}>
                                Fixed
                            </Radio>                            
                            <Radio className="radio-option" value={"Will Discuss with HR"}>
                                Will discuss with HR
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="ctcremarks"
                        className="jd-creation-field-item"
                        label="CTC Remarks"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            className="jd-creation-input"
                        />                       
                    </Form.Item>  
                </div>
                <div className="triple-input-wrapper">
                    <Form.Item
                        required={isRequired}
                        name="otheroffer"
                        className="jd-creation-field-item"
                        label="Other offers"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper-4"
                        >
                            <Radio className="radio-option" value={"No Offer"}>
                                No Offer
                            </Radio>                           
                            <Radio className="radio-option" value={"One Offer"}>
                                One Offer
                            </Radio>
                            <Radio className="radio-option" value={"Multiple Offer"}>
                                Multiple offer
                            </Radio>
                            <Radio className="radio-option" value={"In Pipeline"}>
                                In Pipeline
                            </Radio>                            
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="offercompanytype"
                        className="jd-creation-field-item"
                        label="Offered Company type"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper"
                        >
                            <Radio className="radio-option" value={"Product"}>
                                Product
                            </Radio>
                            <Radio className="radio-option" value={"Service"}>
                                Service
                            </Radio>
                        </Radio.Group>
                    </Form.Item>                      
                    <Form.Item
                        name="offerctc"
                        className="jd-creation-field-item"
                        label="Offered CTC (LPA)"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            className="jd-creation-input"
                        />                       
                    </Form.Item> 
                </div>                

                                  
                <div className="triple-input-wrapper">
                    <Form.Item
                        name="aadharnumber"
                        className="jd-creation-field-item"
                        label="Aadhar Number"
                    >
                        <Input
                            disabled={!isEditMode}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        name="hiring_project_team_email_id"
                        className="jd-creation-field-item"
                        label="DOB"
                    >
                        <Input
                            disabled={!isEditMode}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        name="hiring_project_group_contact_number"
                        className="jd-creation-field-item"
                        label=" Gender"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper"
                        >
                            <Radio className="radio-option" value={"Male"}>
                                Male
                            </Radio>
                            <Radio className="radio-option" value={"Female"}>
                                Female
                            </Radio>
                        </Radio.Group>                        
                    </Form.Item>
                </div>

                <Form.Item
                    name="offerremarks"
                    className="jd-creation-field-item"
                    label="Other Remarks"
                >
                    <Input
                        disabled={!isEditMode}
                        type="text"
                        className="jd-creation-input"
                    />                       
                </Form.Item>     

                <Button
                    style={{ width: "100%", height: "40px", fontWeight: "600" }}
                    type="primary"
                    disabled={!isEditMode}
                    htmlType="submit"
                >
                    Save Candidate Details
                </Button>
            </Form>         
            }

            </div>

            <CandidateAddModal />
         
        </div>
    );
}
