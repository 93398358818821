import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";

import companyLogo from "../../assets/HMWLogo.png"
import logo from "../../assets/QuallyHireGreen.png";
import { Radio, Divider, Form, Input, message, Button, TimePicker, DatePicker,Card } from "antd";
import Footer from "../footer";

import "./careerWebsites.scss";

export default function CareerWebsites(props) {
  console.log(props);

  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const handleSignin = () => {
    navigate('/login')
  };

  const handleSignup = () => {
    navigate('/signup')
  };

  const handleJobSeekers = () => {
    navigate('/jobSeekers')
  };

  const handleCareerListing = () => {
    navigate('/jobSeekers')
  };

  const handleUpdateFormSubmit = () => {
    navigate('/jobSeekers')
  };

  useEffect(() => {

  }, [status]);



  return (
    <div className="careerListing-wrapper">
       <div className="careerListing-header"> 
          <div className="header-logo">
              <img className="header-logo-img"src={logo} />
              <div className="header-logo-name">
                  Quallyhire AI
              </div>
          </div>
          <div className="header-menu">
            <ul className="header-menu">
              <li >
                <a href="/">Home</a>
              </li>
              <li>
                Career Websites Portfolio
              </li>
              <li>
                Support 
              </li>                                          
            </ul>
          </div>
          <div className="header-sign">
            <Button className="sign-button" onClick={() => handleSignin()}>
              Add Your Career Website Page
            </Button>                                   
          </div>          
       </div>

       <div className="careerListing-title">
         <div className="careerListing-punchLine">
            Integrate Career Links to Your Website
         </div>
         <div className="jobListing-puncLineSmall">
            Hastle free Job Listing with ATS
         </div>

         <div className="careerListingCompanyList-wrapper">
            <div className="feature-heading">
              Below 50+ Companies and startups are using it.
            </div>
            <div className="feature-card-wrapper">
              <div className="company-card">
                  <Card title="HMW Communications" bordered={false} extra={<a href="#">60 Jobs</a>}>
                      <div className="company.website">
                          <a>www.hmwcomm.com/careers</a>
                      </div>
                      <div className="company.logo">
                          <img className="company-main-image" src={companyLogo}/>
                      </div>
                  </Card>
              </div>   
              <div className="company-card">
                  <Card title="HMW Communications" bordered={false} extra={<a href="#">60 Jobs</a>}>
                      <div className="company.website">
                          <a>www.hmwcomm.com/careers</a>
                      </div>
                      <div className="company.logo">
                          <img className="company-main-image" src={companyLogo}/>
                      </div>
                  </Card>
              </div>               
              <div className="company-card">
                  <Card title="HMW Communications" bordered={false} extra={<a href="#">60 Jobs</a>}>
                      <div className="company.website">
                          <a>www.hmwcomm.com/careers</a>
                      </div>
                      <div className="company.logo">
                          <img className="company-main-image" src={companyLogo}/>
                      </div>
                  </Card>
              </div> 
              <div className="company-card">
                  <Card title="HMW Communications" bordered={false} extra={<a href="#">60 Jobs</a>}>
                      <div className="company.website">
                          <a>www.hmwcomm.com/careers</a>
                      </div>
                      <div className="company.logo">
                          <img className="company-main-image" src={companyLogo}/>
                      </div>
                  </Card>
              </div>   
              <div className="company-card">
                  <Card title="HMW Communications" bordered={false} extra={<a href="#">60 Jobs</a>}>
                      <div className="company.website">
                          <a>www.hmwcomm.com/careers</a>
                      </div>
                      <div className="company.logo">
                          <img className="company-main-image" src={companyLogo}/>
                      </div>
                  </Card>
              </div>               
              <div className="company-card">
                  <Card title="HMW Communications" bordered={false} extra={<a href="#">60 Jobs</a>}>
                      <div className="company.website">
                          <a>www.hmwcomm.com/careers</a>
                      </div>
                      <div className="company.logo">
                          <img className="company-main-image" src={companyLogo}/>
                      </div>
                  </Card>
              </div>               
            </div>                                                                       
       </div>

       </div>

       <div className="landing-footer">
          <Footer/>
       </div>         
    </div>
  );
}
