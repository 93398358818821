import React, { useState, useRef, useEffect } from "react";
import { Empty, Button, Checkbox, Select, Input } from "antd";
import { useNavigate } from "react-router-dom";

import "./NewRTableSelectable.scss";
import useResumeStore from "../../store/resume.store";
import useClientStore from "../../store/clientModal.store";

export default function NewRTableSelectable({
  isClickable = false,
  titleList,
  data,
  isResumePage = false,
  isEditField = true,
  editFieldHandler,
  setCurrentView,
  resumeProfileHandler,
  qhjid,
}) {
  const { removeClient } = useClientStore();
  const navigate = useNavigate();

  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [checkBoxData, setCheckBoxData] = useState([]);
  const [clicked, setClicked] = useState(false);
  const ResetCheckBox = () => {
    var checkBoxResetArray = data?.map((idx) => {
      return {
        CustomerName: idx.name,
        CompanyType: idx.type,
        HQLocation: idx.location,
        Description: idx.description,
        Status: idx.state,
        isSelected: false,
      };
    });
    setCheckBoxData(() => [...checkBoxResetArray]);
  };
  useEffect(() => {
    console.log("Selected JD and Data", qhjid, data);
    const checkBoxArray = data?.map((idx) => {
      return {
        CustomerName: idx.name,
        CompanyType: idx.type,
        HQLocation: idx.location,
        Description: idx.description,
        Status: idx.state,
        isSelected: false,
      };
    });
    setCheckBoxData(checkBoxArray);
  }, [data]);

  if (!data) {
    return <Empty style={{ margin: "60px" }} />;
  }

  function enableDbUpdate() {
    for (let idx = 0; idx < checkBoxData.length; idx++) {
      if (checkBoxData[idx].isSelected) {
        return true;
      }
    }
    return false;
  }
  const onCheckBoxChange = (e) => {
    console.log(e.target);
    if (e.target.checked) {
      checkBoxData[e.target.value].isSelected = true;
      console.log("Setting checkBoxData", e.target.value, true, checkBoxData);
    } else {
      checkBoxData[e.target.value].isSelected = false;
      console.log("Setting checkBoxData", e.target.value, false);
    }
    if (enableDbUpdate() == true) {
      setUpdateAvailable(true);
    } else {
      setUpdateAvailable(false);
    }
    setCheckBoxData(() => [...checkBoxData]);
  };

  const OnSelectChange = (value, field) => {
    //console.log(`selected ${value}`);
    const answer_array = value.split("#");
    var status = answer_array[1];
    var idx = answer_array[0];
    var idxInt = parseInt(idx);
    setCheckBoxData(() => {
      const newArray = [...checkBoxData];
      if (field === "type") {
        newArray[idxInt].CompanyType = status;
      } else if (field === "state") {
        newArray[idxInt].Status = status;
      }
      return newArray;
    });
  };

  function getTimeNow() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const currentDate = date + "-" + month + "-" + year;
    return currentDate;
  }

  const getUpdated = (rowIndex) => {
    return checkBoxData[rowIndex]?.isSelected;
  };

  const handleRemove = (value) => {
    const removedCustomers = checkBoxData.filter((item) => item.isSelected);
    removedCustomers.forEach((element) => {
      removeClient(element.CustomerName);
    });
    setUpdateAvailable(false);
    ResetCheckBox();
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const updatedValues = [...checkBoxData];
    updatedValues[index] = { ...updatedValues[index], [name]: value };
    setCheckBoxData(updatedValues);
  };

  return (
    <div className="rtable-selectable-wrapper">
      <div className="rtable-selectable-btn-wrapper">
        <Button
          className="rtable-selectable-btn"
          disabled={!updateAvailable}
          // onClick={}
        >
          Update
        </Button>
        <Button
          className="rtable-selectable-btn"
          disabled={!updateAvailable}
          onClick={handleRemove}
        >
          Remove
        </Button>
      </div>
      <div
        className="rtable-title-wrapper"
        style={{
          gridTemplateColumns: `50px 50px 2fr 2fr 2fr repeat(${
            titleList.length - 5
          }, 2fr)`,
        }}
      >
        {titleList.map((title) =>
          title === "Select" ? (
            <div
              className="rtable-title"
              onClick={() => {
                if (!clicked) {
                  const checkAllBox = checkBoxData.map((item) => ({
                    ...item,
                    isSelected: true,
                  }));
                  setCheckBoxData(checkAllBox);
                  setClicked(true);
                  setUpdateAvailable(true);
                } else {
                  const checkAllBox = checkBoxData.map((item) => ({
                    ...item,
                    isSelected: false,
                  }));
                  setCheckBoxData(checkAllBox);
                  setClicked(false);
                  setUpdateAvailable(false);
                }
              }}
            >
              {title}
            </div>
          ) : (
            <div className="rtable-title">{title}</div>
          )
        )}
      </div>
      {data?.map((table, rowIndex) => (
        <div
          key={table.id}
          className="rtable-info-wrapper"
          style={{
            gridTemplateColumns: `50px 50px 2fr 2fr 2fr repeat(${
              titleList.length - 5
            }, 2fr)`,
            cursor: isClickable && "pointer",
          }}
        >
          {Object.keys(table)?.map((tableInfo, idx) => (
            <div className="rtable-info">
              {isEditField ? (
                <div className="edit-field-wrapper">
                  <>
                    {tableInfo === "id" ? rowIndex + 1 : <></>}

                    {tableInfo === "select" ? (
                      <Checkbox
                        value={rowIndex}
                        onChange={onCheckBoxChange}
                        checked={getUpdated(rowIndex)}
                      ></Checkbox>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "name" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="CustomerName"
                            value={checkBoxData[rowIndex].CustomerName}
                            placeholder={checkBoxData[rowIndex].CustomerName}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "type" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Select
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            onChange={(value) =>
                              OnSelectChange(value, tableInfo)
                            }
                            placeholder={checkBoxData[rowIndex].CompanyType}
                            options={[
                              {
                                value: rowIndex + "#" + "Product",
                                label: "Product",
                              },
                              {
                                value: rowIndex + "#" + "Service",
                                label: "Service",
                              },
                              {
                                value: rowIndex + "#" + "Both",
                                label: "Both Product and Service",
                              },
                            ]}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "location" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="HQLocation"
                            value={checkBoxData[rowIndex].HQLocation}
                            placeholder={checkBoxData[rowIndex].HQLocation}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "description" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="Description"
                            value={checkBoxData[rowIndex].Description}
                            placeholder={checkBoxData[rowIndex].Description}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "talist" ? table[tableInfo] : <></>}
                    {tableInfo === "state" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Select
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            onChange={(value) =>
                              OnSelectChange(value, tableInfo)
                            }
                            placeholder={checkBoxData[rowIndex].Status}
                            options={[
                              {
                                value: rowIndex + "#" + "Active",
                                label: "Active",
                              },
                              {
                                value: rowIndex + "#" + "Hold",
                                label: "Hold",
                              },
                              {
                                value: rowIndex + "#" + "Close",
                                label: "Close",
                              },
                            ]}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "date" ? table[tableInfo] : <></>}
                  </>
                </div>
              ) : (
                table[tableInfo]
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
