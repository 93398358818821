import React, { useState, useRef, useEffect } from "react";
import { Empty, Button, Checkbox, Select, Input } from "antd";
import { useNavigate } from "react-router-dom";

import "./TableSelectable.scss";
import useSubUserStore from "../../store/subUserModal.store";

export default function NewRTableSelectable({
  isClickable = false,
  titleList,
  data,
  isResumePage = false,
  isEditField = true,
  editFieldHandler,
  setCurrentView,
  resumeProfileHandler,
  qhjid,
}) {
  const { removeMember } = useSubUserStore();
  const navigate = useNavigate();

  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [checkBoxData, setCheckBoxData] = useState([]);
  const [clicked, setClicked] = useState(false);
  const ResetCheckBox = () => {
    var checkBoxResetArray = data?.map((idx) => {
      return {
        Name: idx.username,
        MobileNo: idx.contact,
        EmailID: idx.email,
        Role: idx.designation,
        Permission: idx.permission,
        MemberID: idx.id,
        isSelected: false,
      };
    });
    setCheckBoxData(() => [...checkBoxResetArray]);
  };
  useEffect(() => {
    console.log("Selected JD and Data", qhjid, data);
    const checkBoxArray = data?.map((idx) => {
      return {
        Name: idx.username,
        MobileNo: idx.contact,
        EmailID: idx.email,
        Role: idx.designation,
        Permission: idx.permission,
        MemberID: idx.id,
        isSelected: false,
      };
    });
    setCheckBoxData(checkBoxArray);
  }, [data]);

  if (!data) {
    return <Empty style={{ margin: "60px" }} />;
  }

  function enableDbUpdate() {
    for (let idx = 0; idx < checkBoxData.length; idx++) {
      console.log(
        "enableDbUpdate checkBoxArray",
        idx,
        checkBoxData[idx].selected
      );
      if (checkBoxData[idx].isSelected) {
        return true;
      }
    }
    return false;
  }
  const onCheckBoxChange = (e) => {
    console.log(e.target);
    if (e.target.checked) {
      checkBoxData[e.target.value].isSelected = true;
      console.log("Setting checkBoxData", e.target.value, true, checkBoxData);
    } else {
      checkBoxData[e.target.value].isSelected = false;
      console.log("Setting checkBoxData", e.target.value, false);
    }
    if (enableDbUpdate() == true) {
      setUpdateAvailable(true);
    } else {
      setUpdateAvailable(false);
    }
    setCheckBoxData(() => [...checkBoxData]);
  };

  const OnSelectChange = (value, field) => {
    //console.log(`selected ${value}`);
    const answer_array = value.split("#");
    var designation = answer_array[1];
    var idx = answer_array[0];
    var idxInt = parseInt(idx);
    // checkBoxData[idxInt].Role = designation;
    setCheckBoxData(() => {
      const newArray = [...checkBoxData];
      if (field === "designation") {
        newArray[idxInt].Role = designation;
      } else if (field === "permission") {
        newArray[idxInt].Permission = designation;
      }
      return newArray;
    });
  };

  function getTimeNow() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const currentDate = date + "-" + month + "-" + year;
    return currentDate;
  }

  const getUpdated = (rowIndex) => {
    return checkBoxData[rowIndex]?.isSelected;
  };

  const handleRemove = () => {
    const removedUsers = checkBoxData.filter((item) => item.isSelected);
    removedUsers.forEach((element) => {
      removeMember(element.MemberID);
    });
    setUpdateAvailable(false);
    ResetCheckBox();
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const updatedValues = [...checkBoxData];
    updatedValues[index] = { ...updatedValues[index], [name]: value };
    setCheckBoxData(updatedValues);
  };

  return (
    <div className="rtable-selectable-wrapper">
      <div className="rtable-selectable-btn-wrapper">
        <Button
          className="rtable-selectable-btn"
          disabled={!updateAvailable}
          // onClick={}
        >
          Update
        </Button>
        <Button
          className="rtable-selectable-btn"
          disabled={!updateAvailable}
          onClick={handleRemove}
        >
          Remove
        </Button>
      </div>
      <div
        className="rtable-title-wrapper"
        style={{
          gridTemplateColumns: `50px 50px 1fr 1fr repeat(${
            titleList.length - 4
          }, 2fr)`,
        }}
      >
        {titleList.map((title) =>
          title === "Select" ? (
            <div
              className="rtable-title"
              onClick={() => {
                if (!clicked) {
                  const checkAllBox = checkBoxData.map((item) => ({
                    ...item,
                    isSelected: true,
                  }));
                  setCheckBoxData(checkAllBox);
                  setClicked(true);
                  setUpdateAvailable(true);
                } else {
                  const checkAllBox = checkBoxData.map((item) => ({
                    ...item,
                    isSelected: false,
                  }));
                  setCheckBoxData(checkAllBox);
                  setClicked(false);
                  setUpdateAvailable(false);
                }
              }}
            >
              {title}
            </div>
          ) : (
            <div className="rtable-title">{title}</div>
          )
        )}
      </div>
      {data?.map((table, rowIndex) => (
        <div
          key={table.id}
          className="rtable-info-wrapper"
          style={{
            gridTemplateColumns: `50px 50px 1fr 1fr repeat(${
              titleList.length - 4
            }, 2fr)`,
            cursor: isClickable && "pointer",
          }}
        >
          {Object.keys(table)?.map((tableInfo, idx) => (
            <div className="rtable-info">
              {isEditField ? (
                <div className="edit-field-wrapper">
                  <>
                    {tableInfo === "id" ? rowIndex + 1 : <></>}
                    {/* {tableInfo != "id" &&
                    tableInfo != "select" &&
                    tableInfo != "candidateStatus" &&
                    tableInfo != "resumeStatus" &&
                    tableInfo != "interviewAction" &&
                    tableInfo != "interviewType" &&
                    tableInfo != "interviewStatus" ? (
                      table[tableInfo]
                    ) : (
                      <></>
                    )} */}

                    {tableInfo === "select" ? (
                      <Checkbox
                        value={rowIndex}
                        onChange={onCheckBoxChange}
                        checked={getUpdated(rowIndex)}
                      ></Checkbox>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "username" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: "auto",
                              height: 24,
                              textAlign: "center",
                            }}
                            name="Name"
                            value={checkBoxData[rowIndex]?.Name}
                            placeholder={checkBoxData[rowIndex]?.Name}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "contact" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="MobileNo"
                            value={checkBoxData[rowIndex].MobileNo}
                            placeholder={checkBoxData[rowIndex].MobileNo}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "email" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="EmailID"
                            value={checkBoxData[rowIndex].EmailID}
                            placeholder={checkBoxData[rowIndex].EmailID}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "designation" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Select
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="Role"
                            onChange={(value) => {
                              OnSelectChange(value, tableInfo);
                            }}
                            placeholder={checkBoxData[rowIndex].Role}
                            options={[
                              {
                                value: rowIndex + "#" + "Trainee Recruiter",
                                label: "Trainee Recruiter",
                              },
                              {
                                value: rowIndex + "#" + "Recruiter",
                                label: "Recruiter",
                              },
                              {
                                value: rowIndex + "#" + "Lead Recruiter",
                                label: "Lead Recruiter",
                              },
                              {
                                value: rowIndex + "#" + "Manager",
                                label: "Manager",
                              },
                              {
                                value: rowIndex + "#" + "Group Manager",
                                label: "Group Manager",
                              },
                              {
                                value: rowIndex + "#" + "COO",
                                label: "COO",
                              },
                              {
                                value: rowIndex + "#" + "CHRO",
                                label: "CHRO",
                              },
                            ]}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "onboardingDate" ? table[tableInfo] : <></>}
                    {tableInfo === "permission" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Select
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            onChange={(value) => {
                              OnSelectChange(value, tableInfo);
                            }}
                            placeholder={checkBoxData[rowIndex].Permission}
                            options={[
                              {
                                value: rowIndex + "#" + "Super Admin",
                                label: "Super Admin",
                              },
                              {
                                value: rowIndex + "#" + "Admin",
                                label: "Admin",
                              },
                              {
                                value: rowIndex + "#" + "User",
                                label: "User",
                              },
                            ]}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
              ) : (
                table[tableInfo]
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
