import React, { useState } from "react";

import { actions } from "../actions";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../assets/QuallyHireGreen.png";
import mainSlider from "../assets/mainSlider.jpg";
import resume from "../assets/resume.png";
import { Container } from "react-bootstrap";
import { Button, Empty } from "antd";
import Footer from "../landing/footer";
import Features from "../landing/features";
import ForWho from "../landing/forWho";
import JobListings from "../landing/jobSeekers/jobListing";

import "./landing.css";


function Landing (props)  {
  const [loader, SetLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [error,setError] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const onSubmit = () => {
    console.log("on submit called");
    let dataToSend = { email: email };
    let inputData = { email: email };
    console.log("email", email);
    SetLoader(true);

    dispatch(actions.SignIn(dataToSend, (error, status) => {
      if (!error) {
        navigate("/otp", { state: { inputData, type: "login" } });
        SetLoader(false);
      } else {
        // Handle the error case
        SetLoader(false);
        console.error("API Error:", error);
        let e = error.split("or")
        setError(e[0] + "signup first")
       // setErrorMessage(error); // Set the error message in state
      }
    }));
  };

  const handleSignin = () => {
    navigate('/login')
  };

  const handleSignup = () => {
    navigate('/signup')
  };

  const handleJobSeekers = () => {
    navigate('/jobSeekers')
  };

  const handleResumeAnalysisFree = () => {
    navigate('/resumeAnalysisFree')
  };

  const handleCareerListing = () => {
    navigate('/careerWebsites')
  };
  return (
     <div className="landing-wrapper">
       <div className="landing-header">
          <div className="header-logo">
              <img className="header-logo-img"src={logo} />
              <div className="header-logo-name">
                  Quallyhire AI
              </div>
          </div>
          <div className="header-menu">
            <ul className="header-menu">
              <li>
                Home
              </li>
              <li>
                Products
              </li>
              <li>
                Pricing
              </li>
              <li>
                Support
              </li>                                          
            </ul>
          </div>
          <div className="header-sign">
            <Button className="sign-button" onClick={() => handleResumeAnalysisFree()}>
              Resume Analysis
            </Button>
            <Button className="sign-button" onClick={() => handleSignup()}>
              Sign Up
            </Button>
            <Button className="sign-button" onClick={() => handleSignin()}>
              Sign In
            </Button>
            <Button className="job-button" onClick={() => handleJobSeekers()}>
              Jobseekers
            </Button>
            <Button className="job-button" onClick={() => handleCareerListing()}>
              Career Websites Portfolio
            </Button>               
            <Button className="job-button">
              Vendor Connect
            </Button>             
            <Button className="job-button">
              News
            </Button> 
            <Button className="job-button">
              BHP
            </Button>                                      
          </div>          
       </div>
       <div className="landing-main-slider">
          <div className="punch-heading-wrapper">
                <div className="punch-heading">
                   Quality Hiring and Workflow Tool 
                </div>
                <div className="punch-sub-heading">
                   Cost effective and efficient AI based tools for End to End HR and recruitment functions.
                </div>
                <Button className="request-button">
                   Request Demo
                </Button>

                <div className="stats">
                  <div className="stats-data">
                        20
                    </div>
                    <div className="stats-data">
                        80
                    </div>   
                    <div className="stats-data">
                        100
                    </div>  
                    <div className="stats-data">
                        210
                    </div> 
                    <div className="stats-data">
                        650
                    </div> 

                    <div className="stats-data">
                        companies
                    </div>
                    <div className="stats-data">
                        jobs
                    </div>
                    <div className="stats-data">
                        resumes
                    </div> 
                    <div className="stats-data">
                        vendors
                    </div>
                    <div className="stats-data">
                        users
                    </div>  
                                                                                      
                </div>
          </div>
          <div className="main-image-wrapper">
              <img className="main-image"src={mainSlider}/>
          </div>
       </div>
       <div className="landing-forWho">
          <ForWho/>
       </div>       
       <div className="landing-features">
          <Features/>
       </div>        
       <div className="landing-footer">
          <Footer/>
       </div>       
     </div>
  );
};

export default Landing;
